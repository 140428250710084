import React from 'react';

import './Button.css';

export enum ButtonType {
  PRIMARY,
  DEFAULT,
}
interface PropTypes {
  title: string;
  type?: ButtonType;
  onClick?: () => void;
  className?: string;
  isLink?: boolean;
  href?: string;
}

export const Button: React.FC<PropTypes> = ({
  title,
  type,
  onClick,
  className,
  isLink,
  href,
}) => {
  const classNames = `button 
        ${
          type === ButtonType.PRIMARY ? 'button-primary' : 'button-default'
        } ${className}
      `;

  return (
    <>
      {isLink ? (
        <a href={href} className={classNames} target="_blank" rel="noreferrer">
          {title}
        </a>
      ) : (
        <button className={classNames} onClick={onClick}>
          {title}
        </button>
      )}
    </>
  );
};
