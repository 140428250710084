import { getLocaleData } from 'helpers/getLocaleData';
import { az, en, ru } from './lang';

export const getData = (lang: string) => {
  const baseLang = getLocaleData(lang, az, en, ru);
  return {
    title: baseLang.title,
    description: baseLang.description,
    buttonTitle: baseLang.buttonTitle,
    modalTitle: baseLang.modalTitle,
    modalDescription: baseLang.modalDescription,
    rewardMoney: baseLang.rewardMoney,
    goToTest: baseLang.goToTest,
    birthday: baseLang.birthday,
    email: baseLang.email,
    female: baseLang.female,
    male: baseLang.male,
    next: baseLang.next,
    unknown: baseLang.unknown,
    quizTitle: baseLang.quizTitle,
    thankYou: baseLang.thankYou,
    submissionSent: baseLang.submissionSent,
  };
};
