import React from 'react';

import './Card.css';

export interface CardInfo {
  title: string;
  info: string;
  price: string;
  isActive: boolean;
  items: string[];
}
export const Card: React.FC<CardInfo> = ({
  title,
  info,
  price,
  isActive,
  items,
}) => (
  <>
    <div className={`card ${isActive ? 'card-active' : ''}`}>
      <div className="price">
        <div className="price-background">
          <div className="price-content">{price}</div>
        </div>
      </div>
      <div className="text-center">
        <h5 className="title-primary">{title}</h5>
        <p className="text-secondary">{info}</p>
      </div>
      <ul>
        {items.map((item, index) => (
          <li key={index} className={item ? 'hasData' : ''}>
            {item}
          </li>
        ))}
      </ul>
    </div>
  </>
);
