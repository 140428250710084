import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
} from '@fortawesome/free-brands-svg-icons';
import './Footer.css';
import { getLocaleData } from 'helpers/getLocaleData';
import { az, en, ru } from './lang';

interface IProps {
  lang: string;
}

export const Footer: React.FC<IProps> = ({ lang }) => {
  const baseLang = getLocaleData(lang, az, en, ru);
  return (
    <>
      <div className="footer">
        <div className="container">
          <div className="footer-primary">
            <div className="links-file">
              <a href="terms" rel="noreferrer">
                {baseLang.termsOfUse}
              </a>{' '}
              |{' '}
              <a href="privacy" rel="noreferrer">
                {baseLang.privacyPolicy}
              </a>
            </div>
            <p className="copyright">
              {baseLang.copyright.split('%')[0]}
              <strong>{baseLang.copyright.split('%')[1]}</strong>
              {baseLang.copyright.split('%')[2]}
            </p>
            <div className="d-flex align-items-center">
              <a
                href="https://www.linkedin.com/company/kidfi-inc"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faLinkedinIn} />
              </a>
              <a
                href="https://facebook.com/kidfi.co/?tsid=0.9414930236059078&source=result"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faFacebookF} />
              </a>
              <a
                href="https://www.instagram.com/kidfi.co/?igshid=YmMyMTA2M2Y="
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faInstagram} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
