import React, { ReactNode } from 'react';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import './Modal.css';

interface IModalProps {
  isOpen: boolean;
  closeModal: () => void;
  children: ReactNode;
  borderRadius?: string;
}

export const ModalPanel: React.FC<IModalProps> = ({
  isOpen,
  closeModal,
  children,
  borderRadius,
}) => {
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '20px',
      transform: 'translate(-50%, -50%)',
      zIndex: '1000',
      padding: 0,
      borderRadius: borderRadius,
      border: 'unset',
      boxShadow: '0px 28px 35px rgba(0, 0, 0, 0.26)',
      maxWidth: '90%',
      maxHeight: '80vh',
    },
  };
  return (
    <div>
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Test yourself modal"
      >
        <div className="modal-close" onClick={closeModal}>
          <FontAwesomeIcon icon={faClose} color="#333333" fontSize={12} />
        </div>
        {children}
      </Modal>
    </div>
  );
};
