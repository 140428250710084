export const az = {
  title: 'Qiymət',
  cardInfo: [
    {
      title: 'KidFi Max',
      price: '2.99 ₼',
      items: [
        '500-ə qədər istifadəçi',
        'Özəl məktəblər',
        'Banklar üçün ƏDX xidməti',
      ],
    },
    {
      title: 'KidFi Plus',
      price: '3.99 ₼',
      items: [
        '300-ə qədər istifadəçi',
        'Kiçik və Orta şirkətlər',
        'İşçilərin bağlılığı',
      ],
    },
    {
      title: 'KidFi Standart',
      price: '4.99 ₼',
      items: ['', 'Ailə paketi', ''],
    },
  ],
};
export const en = {
  title: 'Pricing',
  cardInfo: [
    {
      title: 'KidFi Max',
      price: '3.99$',
      items: ['Up to 500 users', 'Private schools', 'VAS service for banks'],
    },
    {
      title: 'KidFi Plus',
      price: '4.99$',
      items: [
        'Up to 300 users',
        'Small & Medium companies',
        'Employee engagement',
      ],
    },
    {
      title: 'KidFi Standart',
      price: '6.99$',
      items: ['', 'Family package', ''],
    },
  ],
};
export const ru = {
  title: 'Цена',
  cardInfo: [
    {
      title: 'KidFi Max',
      price: '3.99$',
      items: ['До 500 пользователей', 'Частные школы', 'VAS-сервис для банков'],
    },
    {
      title: 'KidFi Plus',
      price: '4.99$',
      items: [
        'До 300 пользователей',
        'Малые и средние компании',
        'Вовлечения сотрудников',
      ],
    },
    {
      title: 'KidFi Standart',
      price: '6.99$',
      items: ['', 'Семейный план', ''],
    },
  ],
};
