import { faCalendarDays } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './Datepicker.css';

const CustomInput = React.forwardRef((props: any, ref) => {
  return (
    <div className="custom-input-picker">
      <input {...props} ref={ref} />
      <FontAwesomeIcon icon={faCalendarDays} color="#B122EA" />
    </div>
  );
});
CustomInput.displayName = 'Search';

interface IProps {
  placeholder: string;
  name: string;
  startDate: Date | null;
  setStartDate: (date: Date) => void;
}
export const ReactPicker: FC<IProps> = ({
  placeholder,
  name,
  startDate,
  setStartDate,
}) => {
  return (
    <DatePicker
      selected={startDate}
      onChange={(date) => date && setStartDate(date)}
      placeholderText={placeholder}
      customInput={<CustomInput />}
      name={name}
      // showMonthDropdown
      // showYearDropdown
    />
  );
};
