import { getLocaleData } from 'helpers/getLocaleData';
import { az, en, ru } from './lang';

export const getData = (lang: string) => {
  const baseLang = getLocaleData(lang, az, en, ru);
  return {
    title: baseLang.title,
    fullName: baseLang.fullName,
    email: baseLang.email,
    phoneNumber: baseLang.phoneNumber,
    company: baseLang.company,
    message: baseLang.message,
    buttonTitle: baseLang.buttonTitle,
  };
};
