export const az = {
  title: 'Gələcək nəslə dəstək olun!',
  description:
    'Oyunlaşdırılmış mobil proqram öyrənmə prosesinə daha çox əyləncə qatır. Uşaqlar kiçik ölçülü interaktiv dərslər, real mükafatlar və rəngarəng interfeys ilə unikal təcrübə qazanacaqlar.',
  details: [
    {
      title: 'Cəld Öyrən',
      description:
        'KidFi tətbiqi ilə kiçik ölçülü dərslərdən həzz al və maliyyə mövzularında daha savadlı ol.',
    },
    {
      title: 'Xallar qazan',
      description:
        'Səviyyələri tamamla, mükafatlar qazan və pulu idarə etməyə başla. Xəyallarınız üçün yığım etməyi öyrən.',
    },
    {
      title: 'Əməliyyatları izləyin!',
      description: 'Pul göndərin və əməliyyatlar haqqında məlumat alın.',
    },
  ],
  clickInfo: 'Yükləmək üçün kliklə',
};
export const en = {
  title: 'Empower the next generation!',
  description:
    'Gamified mobile app adds more fun to the learning process. Kids will gain unique educational experience with bite-size interactive lessons, real rewards and gamified design.',
  details: [
    {
      title: 'Learn fast',
      description:
        'Enjoy bite-size lessons with app and become smart about finances.',
    },
    {
      title: 'Gain rewards',
      description:
        'Complete levels, gain reward and develop money management skills. Start saving for your dreams.',
    },
    {
      title: 'Track the transactions',
      description: 'Send money and get informed about transactions history.',
    },
  ],
  clickInfo: 'Click to download',
};
export const ru = {
  title: 'Расширь границы обучения',
  description:
    'Игровое мобильное приложение делает процесс обучения более увлекательным. Дети получат уникальный опыт вовремя   интерактивных занятий,  узнают как зарабатывать и тратить деньги с умом.',
  details: [
    {
      title: 'Учись быстро!',
      description:
        'Наслаждайся небольшими уроками с KidFi и узнай больше о финансах.',
    },
    {
      title: 'Получай награды',
      description:
        'Проходи уровни, получай награды и начинай управлять деньгами. Учись копить на свои мечты.',
    },
    {
      title: 'Отслеживайте транзакции!',
      description:
        'Отправляйте деньги и получайте информацию об истории транзакций.',
    },
  ],
  clickInfo: 'Нажмите, чтобы скачать',
};
