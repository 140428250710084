export const az = {
  firstTitle: 'Öyrən, əylən və qazan!',
  firstDescription:
    'KidFi ilə interaktiv şəkildə maliyyə, gəlir, xərc, yığım və yatırım haqqında öyrən. Əyləncəli şəkildə maliyyənin idarə edilməsi, ağıllı xərcləmə və qənaət vərdişləri qazan. Bizimlə bərabər həftəlik uğurunu izlə və maliyyə bacarıqlarını inkişaf etdir!',
  secondTitle: 'Rəqəmsal cüzdan ilə maliyyə bacarıqlarını artır!',
  secondDescription:
    'Övladlarınızın pul bacarıqlarını artırmaq üçün onlara rəqəmsal cüzdan yaradın. Tətbiq vasitəsilə uşaqlara tapşırıqlar verin və nailiyyətlərini qeyd edin. KidFi ilə  öyrənərək onlar mükafatlar qazanır və qazandıqları bonusları Marketplace-də endirimlər, hədiyyələrə və sairə ilə dəyişə bilərlər.',
};
export const en = {
  firstTitle: 'Learn, enjoy and gain rewards',
  firstDescription:
    'Learn with KidFi about finances, earning, spending, saving and investing in interactive way. Have fun and gain smart spending and saving habits. Track your weekly progress and improve money skills with us.',
  secondTitle: 'Practice money skills with digital wallet',
  secondDescription:
    'Create digital wallet for your kids to boost their money skills. Assign chores and mark their accomplishments through the app. Kids earn rewards while the learning and can exchange them in the Marketplace for discounts, bonuses and more.',
};
export const ru = {
  firstTitle: 'Учись, веселись и получай награды!',
  firstDescription:
    'Узнай вместе с KidFi о финансах, тратах, сбережениях и инвестициях в интерактивной форме. Учись и приобретай навыки по управлению личными финансами. Следи за своим еженедельными успехом  вместе с нами и развивай свои финансовые навыки!',
  secondTitle: 'Практикуйте денежные навыки с цифровым кошельком',
  secondDescription:
    'Создайте цифровой кошелек для своих детей, чтобы повысить их навыки обращения с деньгами. Назначайте задачи и отмечайте их достижения через приложение. Дети получают награды во время обучения и могут обменивать их на торговой площадке на скидки, бонусы и многое другое.',
};
