import React from 'react';
import { ButtonType, Button } from 'components/button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import TestYourselfImage from 'assets/images/testYourself.png';
import './TestYourself.css';
import { ModalPanel } from 'components/modal/Modal';
import { TestForm } from './TestForm';
import { Video } from './Video';
import { Quiz } from './Quiz';

import { getData } from './data';

interface IProps {
  id: string;
  lang: string;
}

export const TestYourself: React.FC<IProps> = ({ id, lang }) => {
  const scrollToElement = (element: any) => {
    element.scrollIntoView({
      behavior: 'smooth',
    });
  };
  const [modalIsOpen, setModalOpen] = React.useState(false);
  const [isFormVisible, setFormVisible] = React.useState(true);
  const [isQuizVisible, setQuizVisible] = React.useState(false);

  const onVideoEnd = () => {
    setQuizVisible(true);
  };

  function openModal() {
    setModalOpen(true);
  }

  function closeModal() {
    setModalOpen(false);
  }
  const data = getData(lang);
  return (
    <>
      <div className="testYourself" id={id}>
        <div className="container">
          <div className="d-flex justify-content-between">
            <div className="testYourself-left">
              <h1 className="title-primary">
                {data.title.split('%')[0]}
                <strong>{data.title.split('%')[1]}</strong>
                {data.title.split('%')[2]}
              </h1>
              <p className="text-primary">{data.description}</p>
              <div className="d-flex">
                <Button
                  title={data.buttonTitle}
                  type={ButtonType.PRIMARY}
                  onClick={openModal}
                />
                <div
                  className="scrollToBottom"
                  onClick={() =>
                    scrollToElement(document.getElementById('about'))
                  }
                >
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    color="#B122EA"
                    fontSize={24}
                  />
                </div>
              </div>
            </div>
            <div className="testYourself-right">
              <img src={TestYourselfImage} alt="" />
            </div>
          </div>
        </div>
      </div>
      <ModalPanel
        isOpen={modalIsOpen}
        closeModal={closeModal}
        borderRadius={isQuizVisible ? '3px 3px 24px 24px' : '24px'}
      >
        {isFormVisible && (
          <TestForm
            title={data.modalTitle}
            info={data.modalDescription}
            buttonTitle={data.goToTest}
            emailPlaceholder={data.email}
            pickerPlaceholder={data.birthday}
            maleText={data.male}
            femaleText={data.female}
            setFormVisible={setFormVisible}
          />
        )}
        {!isFormVisible && !isQuizVisible && <Video onEnd={onVideoEnd} />}
        {isQuizVisible && <Quiz />}
      </ModalPanel>
    </>
  );
};
