import React from 'react';
import { Link } from 'react-router-dom';
import AppInfoImage from 'assets/images/appInfo.png';
import PlayStore from 'assets/images/playStore.svg';
import AppleStore from 'assets/images/appleStore.svg';
import QuizzesImage from 'assets/images/quizzes.svg';
import CreditCardImage from 'assets/images/credit_card.svg';
import CalculatorImage from 'assets/images/calculator.svg';
import './AppInfo.css';
import { getData } from './data';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faApple,
  faAppStore,
  faGooglePlay,
} from '@fortawesome/free-brands-svg-icons';

interface IProps {
  id: string;
  lang: string;
}
export const AppInfo: React.FC<IProps> = ({ id, lang }) => {
  const data = getData(lang);
  const images = [CalculatorImage, QuizzesImage, CreditCardImage];
  return (
    <>
      <div className="appInfo" id={id}>
        <div className="container">
          <div className="d-flex justify-content-between">
            <div className="appInfo-left">
              <img src={AppInfoImage} alt="" />
            </div>
            <div className="appInfo-right">
              <h4 className="title-primary">{data.title}</h4>
              <p className="text-primary">{data.description}</p>
              <div className="d-flex appInfo-links">
                <a
                  href="https://apps.apple.com/az/app/kidfi-parent/id1629253749"
                  className="store-link mr-5"
                >
                  <FontAwesomeIcon icon={faApple} fontSize={32} />
                  <div className="store-link__info">
                    <h6>App Store</h6>
                    <p>{data.clickInfo}</p>
                  </div>
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.ea.kidfi.parent"
                  className="store-link"
                >
                  <FontAwesomeIcon icon={faGooglePlay} fontSize={32} />
                  <div className="store-link__info">
                    <h6>Play Store</h6>
                    <p>{data.clickInfo}</p>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="appInfo-details">
          <div className="container">
            <div className="d-flex justify-content-between text-center">
              {data.details.map((item: any, index: number) => {
                return (
                  <div className="appInfo-details__item" key={index}>
                    <img
                      src={images[index]}
                      className="appInfo-details__icon"
                    />
                    <p className="appInfo-details__title">{item.title}</p>
                    <p className="appInfo-details__info">{item.description}</p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
