import React, { useState, useEffect } from 'react';
import { Header } from 'components/header/Header';
import { HomeView } from './views/home/HomeView';
import { Privacy } from './views/privacy/Privacy';
import { Terms } from './views/terms/Terms';
import { Footer } from 'components/footer/Footer';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import { getCurrentLang } from 'helpers/getCurrentLang';
import { API } from 'views/api/API';

function App() {
  const [language, setLanguage] = useState(getCurrentLang());

  const [defaultValue, setDefaultValue] = useState({
    value: '',
    label: '',
  });

  const langOptions = [
    { value: 'az', label: 'Az' },
    { value: 'en', label: 'En' },
    { value: 'ru', label: 'Ru' },
  ];

  useEffect(() => {
    if (
      !(
        getCurrentLang() === 'az' ||
        getCurrentLang() === 'en' ||
        getCurrentLang() === 'ru'
      )
    ) {
      window.location.href.includes('.az')
        ? localStorage.setItem('lang', 'az')
        : localStorage.setItem('lang', 'en');
    }
    setLanguage(getCurrentLang());
    setDefaultValue(
      langOptions.find((item) => item.value === getCurrentLang()) ?? {
        value: '',
        label: '',
      }
    );
  }, []);

  const handleChange = (value: any) => {
    localStorage.setItem('lang', value.value);
    setLanguage(value.value);
  };

  return (
    <div className="App">
      <BrowserRouter>
        <Header
          handleChange={(value: any) => handleChange(value)}
          lang={language}
          options={langOptions}
          defaultValue={defaultValue}
        />
        <Routes>
          <Route path="/" element={<HomeView lang={language} />} />
          <Route path="/privacy" element={<Privacy lang={language} />} />
          <Route path="/terms" element={<Terms lang={language} />} />
          <Route path="/api" element={<API lang={language} />} />
        </Routes>
        <Footer lang={language} />
      </BrowserRouter>
    </div>
  );
}

export default App;
