import React, { FC, useState, useEffect, useRef, ReactNode } from 'react';
import ModalImage from 'assets/images/quiz.png';
import ProgressBar from '@ramonak/react-progress-bar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faCheckCircle,
  faCircleCheck,
} from '@fortawesome/free-solid-svg-icons';
import { Button, ButtonType } from 'components/button/Button';
import { quizData, getData } from './data';
import { getCurrentLang } from 'helpers/getCurrentLang';
import { v4 as uuid } from 'uuid';

export const Quiz: FC = () => {
  const lang = getCurrentLang();
  const [counter, setCounter] = useState(1);
  const [headerHeight, setHeaderHeight] = useState(0);
  const ref = React.createRef<HTMLDivElement>();

  useEffect(() => {
    setHeaderHeight(ref.current ? ref.current.clientHeight : 0);
  });
  const data =
    lang === 'az' ? quizData.az : lang === 'ru' ? quizData.ru : quizData.en;
  return (
    <>
      <div className="modal-quiz">
        <ProgressBar
          completed={(counter * 100) / data.length}
          className="wrapper"
        />
        {counter <= data.length ? (
          <>
            <div className="modal--static" ref={ref}>
              <div className="modal-header d-flex justify-content-between">
                <div>
                  <h6>{data[counter - 1].question}</h6>
                  <p>{getData(lang).quizTitle}</p>
                </div>
                <div>
                  <span>
                    <strong>{counter}</strong>/{data.length}
                  </span>
                </div>
              </div>
            </div>
            <div
              className="modal-content d-flex justify-content-between"
              style={{ marginTop: headerHeight }}
            >
              <div className="modal-content--left mr-5">
                {data[counter - 1].answers.map((answer, index) => {
                  return (
                    <div className="radio-group" key={uuid()}>
                      <input
                        type={
                          data[counter - 1].type === 'single'
                            ? 'radio'
                            : 'checkbox'
                        }
                        name={`answers${counter}`}
                        id={`answer${counter}${index}`}
                      />
                      <label htmlFor={`answer${counter}${index}`}>
                        <div className="circle">
                          <FontAwesomeIcon icon={faCheck} color="#CCCACA" />
                        </div>
                        {answer.title}
                      </label>
                    </div>
                  );
                })}
              </div>
              {/* <img src={ModalImage} alt="" /> */}
            </div>
            <div className="modal-footer">
              <Button
                title={getData(lang).next}
                type={ButtonType.PRIMARY}
                onClick={() => {
                  setCounter(counter + 1);
                }}
              />
              <Button
                title={`${getData(lang).unknown} :(`}
                onClick={() => {
                  setCounter(counter + 1);
                }}
              />
            </div>
          </>
        ) : (
          <div className="modal-message">
            <FontAwesomeIcon
              icon={faCheckCircle}
              color="#87D185"
              fontSize="40"
            />
            <h1 className="title-primary">
              {getData(getCurrentLang()).thankYou}
            </h1>
            <p className="text-primary">
              {getData(getCurrentLang()).submissionSent}
            </p>
          </div>
        )}
      </div>
    </>
  );
};
