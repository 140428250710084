import React, { FC, useState } from 'react';
import axios from 'axios';
import ModalImage from 'assets/images/modal.png';
import { ReactPicker } from 'components/datepicker/Datepicker';
import { Button, ButtonType } from 'components/button/Button';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faMars, faVenus } from '@fortawesome/free-solid-svg-icons';

interface ITestFormProps {
  title: string;
  info: string;
  buttonTitle: string;
  emailPlaceholder: string;
  pickerPlaceholder: string;
  maleText: string;
  femaleText: string;
  setFormVisible: any;
}
interface Values {
  email: string;
  date: string;
  gender: string;
}
export const TestForm: FC<ITestFormProps> = ({
  title,
  info,
  buttonTitle,
  emailPlaceholder,
  pickerPlaceholder,
  maleText,
  femaleText,
  setFormVisible,
}) => {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const onPostContactForm = (values: Values, resetForm: () => void) => {
    axios
      .post('https://shark-app-4wif8.ondigitalocean.app/kid-fi/guest/', {
        email: values.email,
        birthday: startDate,
        gender: values.gender,
      })
      .then(() => {
        resetForm();
        setFormVisible(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <Formik
      initialValues={{
        email: '',
        date: '',
        gender: 'female',
      }}
      onSubmit={(values: Values, { resetForm }: FormikHelpers<Values>) => {
        onPostContactForm(values, resetForm);
      }}
    >
      {({ setFieldValue }) => (
        <Form>
          <div className="modal-content modal-form d-flex">
            <div className="modal-content--left">
              <img src={ModalImage} alt="" />
            </div>
            <div className="modal-content--right">
              <h6 className="title-primary">
                {title.split('%')[0]}
                <strong>{title.split('%')[1]}</strong>
                {title.split('%')[2]}
              </h6>
              <p className="text-primary">{info}</p>
              <div className="d-flex justify-content-between flex-wrap">
                <ReactPicker
                  placeholder={pickerPlaceholder}
                  name="date"
                  startDate={startDate}
                  setStartDate={setStartDate}
                />
                <div className="input-group">
                  <Field placeholder={emailPlaceholder} name="email" />
                  <FontAwesomeIcon icon={faEnvelope} color="#B122EA" />
                </div>
                <div className="radio-group">
                  <input
                    type="radio"
                    name="gender"
                    id="female"
                    value="female"
                    onChange={() => setFieldValue('gender', 'female')}
                    checked
                  />
                  <label htmlFor="female">
                    {femaleText}
                    <FontAwesomeIcon
                      icon={faVenus}
                      color="#B122EA"
                      fontSize={17}
                    />
                  </label>
                </div>
                <div className="radio-group">
                  <input
                    type="radio"
                    name="gender"
                    id="male"
                    value="male"
                    onChange={() => setFieldValue('gender', 'male')}
                  />
                  <label htmlFor="male">
                    {maleText}
                    <FontAwesomeIcon
                      icon={faMars}
                      color="#B122EA"
                      fontSize={17}
                    />
                  </label>
                </div>
              </div>
              <Button title={buttonTitle} type={ButtonType.PRIMARY} />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
