import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getLocaleData } from 'helpers/getLocaleData';
import { ReactSelect } from 'components/select/Select';
import { MenuLink } from 'components/link/MenuLink';
import HeaderLogo from 'assets/images/header_logo.svg';
import { az, en, ru } from './lang';
import './Header.css';

interface IProps {
  handleChange: (value: any) => void;
  lang: string;
  options: {
    value: string;
    label: string;
  }[];
  defaultValue: {
    value: string;
    label: string;
  };
}

interface IMenuLinkActive {
  testYourself: boolean | null;
  about: boolean | null;
  contact: boolean | null;
  pricing: boolean | null;
  partners: boolean | null;
  api: boolean | null;
}

export const Header: React.FC<IProps> = ({
  handleChange,
  lang,
  options,
  defaultValue,
}) => {
  const [isSticky, setSticky] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState(false);

  const [isActive, setActive] = useState<IMenuLinkActive>({
    testYourself: null,
    about: null,
    contact: null,
    pricing: null,
    partners: null,
    api: null,
  });

  const setDefault = () => {
    setActive({
      testYourself: false,
      about: false,
      contact: false,
      pricing: false,
      partners: false,
      api: false,
    });
  };

  document.addEventListener('scroll', function (e) {
    if (window.pageYOffset > 0) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  });

  const baseLang = getLocaleData(lang, az, en, ru);
  const { pathname, state } = useLocation();
  const { targetId } = (state || {}) as { targetId: string };

  useEffect(() => {
    setActive({
      ...isActive,
      [targetId]: true,
    });
  }, [targetId]);

  useEffect(() => {
    if (pathname == '/api')
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [pathname]);

  return (
    <div
      className={`header ${isSticky ? 'sticky' : ''} ${
        pathname == '/api' ? 'colored' : ''
      }`}
      id="header"
    >
      <div className="container">
        <a
          onClick={() => window.location.reload()}
          style={{ cursor: 'pointer' }}
          href="/"
        >
          <img src={HeaderLogo} alt="" />
        </a>
        {pathname === '/' || pathname === '/api' ? (
          <div className="header-menu d-flex mx-auto">
            <MenuLink
              setDefault={setDefault}
              title={baseLang.testYourself}
              active={isActive}
              id="testYourself"
            />
            <MenuLink
              setDefault={setDefault}
              title={baseLang.about}
              active={isActive}
              id="about"
            />
            <MenuLink
              setDefault={setDefault}
              title={baseLang.pricing}
              active={isActive}
              id="pricing"
            />
            {/* <MenuLink
              setDefault={setDefault}
              title={baseLang.contact}
              active={isActive}
              id="contact"
            /> */}
            <MenuLink
              setDefault={setDefault}
              title={baseLang.partners}
              active={isActive}
              id="partners"
            />
            <MenuLink
              title={baseLang.api}
              active={isActive}
              id="api"
              setDefault={setDefault}
              isIndividual
            />
          </div>
        ) : (
          <></>
        )}
        <ReactSelect
          options={options}
          defaultValue={defaultValue}
          key={defaultValue.value}
          primaryColor="#4A155F"
          secondaryColor="rgba(74, 21, 95, .5)"
          className="select-lang"
          handleChange={handleChange}
        />
        {pathname === '/' || pathname === '/api' ? (
          <div
            className={`menu-icon ${isMenuOpen ? 'menu-icon-close' : ''}`}
            onClick={() => setMenuOpen(!isMenuOpen)}
          >
            <div className="bar"></div>
            <div className="bar"></div>
            <div className="bar"></div>
          </div>
        ) : (
          <></>
        )}
      </div>
      {pathname === '/' || pathname === '/api' ? (
        <div className={`header-menu__mobile ${isMenuOpen ? 'active' : ''}`}>
          <MenuLink
            setDefault={setDefault}
            title={baseLang.testYourself}
            active={isActive}
            id="testYourself"
            setMenuOpen={setMenuOpen}
          />
          <MenuLink
            setDefault={setDefault}
            title={baseLang.about}
            active={isActive}
            id="about"
            setMenuOpen={setMenuOpen}
          />
          <MenuLink
            setDefault={setDefault}
            title={baseLang.pricing}
            active={isActive}
            id="pricing"
            setMenuOpen={setMenuOpen}
          />
          {/* <MenuLink
            setDefault={setDefault}
            title={baseLang.contact}
            active={isActive}
            id="contact"
            setMenuOpen={setMenuOpen}
          /> */}
          <MenuLink
            setDefault={setDefault}
            title={baseLang.partners}
            active={isActive}
            id="partners"
            setMenuOpen={setMenuOpen}
          />
          <MenuLink
            setDefault={setDefault}
            title={baseLang.api}
            active={isActive}
            id="api"
            setMenuOpen={setMenuOpen}
            isIndividual
          />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
