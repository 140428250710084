import React, { FC } from 'react';
import YouTube, { YouTubeProps } from 'react-youtube';
import { getCurrentLang } from 'helpers/getCurrentLang';

interface IVideoProps {
  onEnd: () => void;
}

export const Video: FC<IVideoProps> = ({ onEnd }) => {
  const onPlayerReady: YouTubeProps['onReady'] = (event) => {
    event.target.playVideo();
  };

  const opts: YouTubeProps['opts'] = {
    height: '390',
    width: '690',
    playerVars: {
      autoplay: 1,
    },
  };
  const lang = getCurrentLang();
  return (
    <>
      <YouTube
        videoId={
          lang === 'az'
            ? 'GkTZae4ZWkk'
            : lang === 'ru'
            ? 'yDKYrdRD08g'
            : '86Lub9ZXONw'
        }
        opts={opts}
        onReady={onPlayerReady}
        onEnd={onEnd}
      />
      {/* <video controls onEnded={onEnd} autoPlay>
        <source
          src={lang === 'az' ? '/videos/money_az.mp4' : lang === 'ru' ? '/videos/money_ru.mp4' : '/videos/money_en.mp4'}
          type="video/mp4"
        ></source>
      </video> */}
    </>
  );
};
