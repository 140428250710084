import React from 'react';
import './Privacy.css';

interface IProps {
  lang: string;
}

export const Privacy: React.FC<IProps> = ({ lang }) => {
  return (
    <div className="privacy">
      <div className="container">
        {lang === 'az' ? (
          <>
            <p style={{ textAlign: 'center', lineHeight: '114%' }}>
              <strong>
                <span
                  style={{
                    lineHeight: '114%',
                    fontSize: '16px',
                  }}
                >
                  MƏXFİLİK SİYASƏTİ
                </span>
              </strong>
            </p>
            <p style={{ textAlign: 'center', lineHeight: '114%' }}>
              <strong>
                <span
                  style={{
                    lineHeight: '114%',
                    fontSize: '16px',
                  }}
                >
                  &nbsp;
                </span>
              </strong>
            </p>
            <p style={{ textAlign: 'justify', lineHeight: '114%' }}>
              <strong>
                <span
                  style={{
                    lineHeight: '114%',
                    color: 'rgb(0,0,0)',
                    fontSize: '16px',
                  }}
                >
                  &nbsp;“KIDFI” MMC tərəfindən
                </span>
              </strong>
            </p>
            <p
              style={{
                marginTop: '5.0000pt',
                marginBottom: '5.0000pt',
                textIndent: '18.0000pt',
                textAlign: 'justify',
                lineHeight: '114%',
              }}
            >
              <span
                style={{
                  lineHeight: '114%',
                  color: 'rgb(0,0,0)',
                  fontSize: '16px',
                }}
              >
                Bu Məxfilik Siyasəti Azərbaycan Respublikasının
                qanunvericiliyinə uyğun olaraq təsis edilmiş hüquqi şəxs
                olan&nbsp;
              </span>
              <strong>
                <span
                  style={{
                    lineHeight: '114%',
                    color: 'rgb(0,0,0)',
                    fontSize: '16px',
                  }}
                >
                  “KIDFI” MMC-
                </span>
              </strong>
              <span
                style={{
                  lineHeight: '114%',
                  color: 'rgb(0,0,0)',
                  fontSize: '16px',
                }}
              >
                nin (bundan sonra “biz”) (
              </span>
              <span
                style={{
                  lineHeight: '114%',
                  color: 'rgb(0,0,0)',
                  fontSize: '16px',
                }}
              >
                1405934651, Bakı şəhəri, Nəsimi rayonu, Ə.Hüseynov küçəsi,
              </span>
              <span
                style={{
                  lineHeight: '114%',
                  color: 'rgb(0,0,0)',
                  fontSize: '16px',
                }}
              >
                &nbsp;5/7, 454
              </span>
              <span
                style={{
                  lineHeight: '114%',
                  color: 'rgb(0,0,0)',
                  fontSize: '16px',
                }}
              >
                ) və partynorlarının
              </span>
              <strong>
                <span
                  style={{
                    lineHeight: '114%',
                    color: 'rgb(0,0,0)',
                    fontSize: '16px',
                  }}
                >
                  &nbsp;
                </span>
              </strong>
              <span
                style={{
                  lineHeight: '114%',
                  color: 'rgb(0,0,0)',
                  fontSize: '16px',
                }}
              >
                “KidFi” adlı mobil tətbiqdən (bundan sonra “xidmətlər”) istifadə
                etdiyiniz zaman istifadəçi məlumatının (bundan sonra “siz”) necə
                toplanması, istifadəsi və açıqlanması haqqında həyata keçirdiyi
                prosedur və siyasətlərini izah edir. &nbsp;
              </span>
            </p>
            <p
              style={{
                marginTop: '5.0000pt',
                marginRight: '1.4500pt',
                marginBottom: '5.0000pt',
                textIndent: '18.0000pt',
                textAlign: 'justify',
              }}
            >
              <span
                style={{
                  color: 'rgb(0,0,0)',
                  fontSize: '16px',
                }}
              >
                Qeydiyyat prosesi zamanı müvafiq yeri işarələməklə siz bu
                Məxfilik Siyasətinə razılıq vermiş olursunuz.
              </span>
            </p>
            <p
              style={{
                marginTop: '5.0000pt',
                marginBottom: '5.0000pt',
                textIndent: '18.0000pt',
                textAlign: 'justify',
                lineHeight: '114%',
              }}
            >
              <span
                style={{
                  lineHeight: '114%',
                  color: 'rgb(0,0,0)',
                  fontSize: '16px',
                }}
              >
                Sizin təhlükəsizliyiniz və məxfiliyiniz bizim üçün son dərəcə
                vacib olduğundan, biz məlumatların məxfiliyinin qorunması ilə
                bağlı Azərbaycan Respublikasında qüvvədə olan müvafiq
                qanunvericiliyə və Azərbaycan Respublikasının tərəfdar çıxdığı
                beynəlxalq müqavilələrə, o cümlədən “Fərdi Məlumatlar haqqında”
                Azərbaycan Respublikasının 11 may 2010-cu il tarixli, № 998-IIIQ
                Qanununa uyğun olaraq fəaliyyət göstəririk.
              </span>
            </p>
            <p
              style={{
                marginTop: '5.0000pt',
                marginBottom: '5.0000pt',
                textIndent: '18.0000pt',
                textAlign: 'justify',
                lineHeight: '114%',
              }}
            >
              <span
                style={{
                  lineHeight: '114%',
                  color: 'rgb(0,0,0)',
                  fontSize: '16px',
                }}
              >
                Nəzərə alın ki, İstifadəçi Qaydaları bu Məxfilik Siyasətinin
                ayrılmaz hissəsi hesab olunur və bu sənədlər birlikdə sizinlə
                bizim aramızda olan münasibətləri tənzimləyir.
              </span>
            </p>
            <h1 style={{ marginTop: '8.0000pt', marginBottom: '8.0000pt' }}>
              <strong>
                <span
                  style={{
                    color: 'rgb(0,0,0)',
                    fontSize: '16px',
                  }}
                >
                  ƏSAS ANLAYIŞLAR
                </span>
              </strong>
              <span
                style={{
                  color: 'rgb(46,117,181)',
                  fontSize: '16px',
                }}
              >
                :
              </span>
            </h1>
            <ul style={{ listStyleType: 'undefined', marginLeft: '3.5px' }}>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  -&nbsp;
                </span>
                <em>
                  <span
                    style={{
                      fontSize: '12.0000pt',
                    }}
                  >
                    Şəxsi məlumat&nbsp;
                  </span>
                </em>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  –&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  sizi birbaşa və ya dolayısı ilə müəyyən etməyə imkan verən hər
                  hansı məlumat;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  -&nbsp;
                </span>
                <em>
                  <span
                    style={{
                      fontSize: '12.0000pt',
                    }}
                  >
                    Şəxsi məlumatın subyekti&nbsp;
                  </span>
                </em>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  –&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  haqqında şəxsi məlumatların toplandığı, işləndiyi və
                  açıqlandığı istifadəçi;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  -&nbsp;
                </span>
                <em>
                  <span
                    style={{
                      fontSize: '12.0000pt',
                    }}
                  >
                    Uşaq/övlad&nbsp;
                  </span>
                </em>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  – 18 yaşdan kiçik hər bir şəxs;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  -&nbsp;
                </span>
                <em>
                  <span
                    style={{
                      fontSize: '12.0000pt',
                    }}
                  >
                    Fərdi İdentifikasiya Nömrəsi (FİN)&nbsp;
                  </span>
                </em>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  –&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  şəxs barəsində müvafiq məlumatları müəyyən etməyə imkan verən
                  vahid təkrarolunmaz kod;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  -&nbsp;
                </span>
                <em>
                  <span
                    style={{
                      fontSize: '12.0000pt',
                    }}
                  >
                    Şəxsi məlumatın toplanması&nbsp;
                  </span>
                </em>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  –&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  qanunvericiliyə uyğun olaraq subyekt haqqında fərdi
                  məlumatların əldə edilməsi;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  -&nbsp;
                </span>
                <em>
                  <span
                    style={{
                      fontSize: '12.0000pt',
                    }}
                  >
                    Şəxsi məlumatın emalı&nbsp;
                  </span>
                </em>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  –&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  şəxsi məlumatlar üzərində aparılan yazılma, sistemləşdirilmə,
                  yenilənmə, dəyişdirilmə, çıxarılma, anonimləşdirilmə,
                  saxlanma, ötürülmə, məhv edilmə kimi əməliyyatlar;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  -&nbsp;
                </span>
                <em>
                  <span
                    style={{
                      fontSize: '12.0000pt',
                    }}
                  >
                    Şəxsi məlumatın açıqlanması&nbsp;
                  </span>
                </em>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  –&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  məlumatların qanunvericiliyə uyğun olaraq xidmət
                  təminatçılarına və ya tərəfdaşlarımıza – üçüncü şəxslərə
                  təqdim edilməsi.
                </span>
              </li>
            </ul>
            <h1>
              <strong>
                <span
                  style={{
                    color: 'rgb(0,0,0)',
                    fontSize: '16px',
                  }}
                >
                  BİZ KİMİK?
                </span>
              </strong>
            </h1>
            <p
              style={{
                marginTop: '5.0000pt',
                marginBottom: '5.0000pt',
                textIndent: '18.0000pt',
                textAlign: 'justify',
                lineHeight: '114%',
              }}
            >
              <span
                style={{
                  lineHeight: '114%',
                  color: 'rgb(0,0,0)',
                  fontSize: '16px',
                }}
              >
                Bu Məxfilik Siyasətinə uyğun olaraq məlumatlarınız üçün
                məsuliyyət daşıyan şəxs (məlumat nəzarətçisi)&nbsp;
              </span>
              <span
                style={{
                  lineHeight: '114%',
                  color: 'rgb(0,0,0)',
                  fontSize: '16px',
                }}
              >
                Azərbaycan Respublikasının qanunvericiliyinə uyğun olaraq təsis
                edilmiş hüquqi şəxs olan&nbsp;
              </span>
              <strong>
                <span
                  style={{
                    lineHeight: '114%',
                    color: 'rgb(0,0,0)',
                    fontSize: '16px',
                  }}
                >
                  “KIDFI” MMC
                </span>
              </strong>
              <span
                style={{
                  lineHeight: '114%',
                  color: 'rgb(0,0,0)',
                  fontSize: '16px',
                }}
              >
                -dir
              </span>
              <strong>
                <span
                  style={{
                    lineHeight: '114%',
                    color: 'rgb(0,0,0)',
                    fontSize: '16px',
                  }}
                >
                  &nbsp;(
                </span>
              </strong>
              <span
                style={{
                  lineHeight: '114%',
                  color: 'rgb(0,0,0)',
                  fontSize: '16px',
                }}
              >
                1405934651, Bakı şəhəri, Nəsimi rayonu, Ə.Hüseynov küçəsi,
              </span>
              <span
                style={{
                  lineHeight: '114%',
                  color: 'rgb(0,0,0)',
                  fontSize: '16px',
                }}
              >
                &nbsp;5/7, 454
              </span>
              <span
                style={{
                  lineHeight: '114%',
                  color: 'rgb(0,0,0)',
                  fontSize: '16px',
                }}
              >
                ).
              </span>
            </p>
            <p
              style={{
                marginTop: '5.0000pt',
                marginBottom: '5.0000pt',
                textIndent: '18.0000pt',
                textAlign: 'justify',
                lineHeight: '114%',
              }}
            >
              <span
                style={{
                  lineHeight: '114%',
                  color: 'rgb(0,0,0)',
                  fontSize: '16px',
                }}
              >
                Biz məlumatlarınızı qeyri-qanuni girişdən, təsadüfən
                itirilməkdən, icazəsiz dəyişdirilməkdən və üçüncü şəxslərin
                digər qanunsuz hərəkətlərindən qorumaq üçün bütün zəruri
                təşkilati və texniki tədbirləri görəcəyik. Bu Məxfilik
                Siyasətində qeyd olunan hallarda, bizdən başqa bəzi üçüncü
                şəxslər məlumatlarınıza daxil ola bilər.
              </span>
            </p>
            <h1 style={{ marginTop: '8.0000pt', marginBottom: '8.0000pt' }}>
              <strong>
                <span
                  style={{
                    color: 'rgb(0,0,0)',
                    fontSize: '16px',
                  }}
                >
                  TƏRƏFİMİZDƏN TOPLANAN MƏLUMAT
                </span>
              </strong>
            </h1>
            <p
              style={{
                marginTop: '5.0000pt',
                marginBottom: '5.0000pt',
                textIndent: '18.0000pt',
                textAlign: 'justify',
                lineHeight: '114%',
              }}
            >
              <span
                style={{
                  lineHeight: '114%',
                  fontSize: '16px',
                }}
              >
                Qeyd etmək lazımdır ki, xidmətlərimizi həyata keçirmək üçün biz
                siz və övlad (lar) ınız haqqında müəyyən məlumatları toplayırıq.
                Buraya daxildir:
              </span>
            </p>
            <ul style={{ listStyleType: 'undefined', marginLeft: '3.5px' }}>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  -&nbsp;
                </span>
                <em>
                  <span
                    style={{
                      fontSize: '12.0000pt',
                    }}
                  >
                    Sizin tərəfinizdən təqdim edilən məlumatlar&nbsp;
                  </span>
                </em>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  – Qeydiyyatdan keçərkən və hesab yaradarkən siz bizə tam
                  adınız, telefon nömrəniz, doğum tarixiniz, cinsiniz və
                  promokodunuz (əgər varsa) kimi giriş məlumatlarını təqdim
                  edirsiniz. Profil şəkliniz, yerləşdiyiniz yer və elektron
                  ünvan kimi məlumatlarınızı profilinizə əlavə edə bilərsiniz.
                  Maliyyə əməliyyatlarını həyata keçirmək üçün kart
                  məlumatlarınız və rəqəmsal kart yaratmaq üçün, Fərdi
                  İdentifikasiya Nömrəniz (FİN) kimi məlumatları bizə təqdim
                  edirsiniz. Övlad (lar) ınızı əlavə edərkən siz bizimlə) o
                  (lar) nun tam adı, doğum tarixi, cinsi, mükafat məbləği və
                  promokod (əgər varsa), istəyə görə profil şəkli kimi
                  məlumatları paylaşırsınız. Övlad (lar) ınız testlərdən
                  keçərkən o, bizə suallara verdiyi cavabları təqdim edir.
                  Müştəri dəstəyi komandamızla əlaqə saxladığınız halda,
                  qarşılıqlı əlaqə zamanı bizimlə paylaşdığınız məlumatlar
                  tərəfimizdən toplanır;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  -&nbsp;
                </span>
                <em>
                  <span
                    style={{
                      fontSize: '12.0000pt',
                    }}
                  >
                    İstifadə barədə məlumatlar
                  </span>
                </em>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  &nbsp;– Xidmətlərimizdəki fəaliyyətiniz, istifadə etdiyiniz
                  funksiyalar, onlardan necə istifadə etdiyiniz, məsələn, daxil
                  olduğunuz tarix və vaxt, kliklər və sizə göstərilən səhifələr
                  haqqında məlumatlar tərəfimizdən toplanır;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  -&nbsp;
                </span>
                <em>
                  <span
                    style={{
                      fontSize: '12.0000pt',
                    }}
                  >
                    Cihaz məlumatları
                  </span>
                </em>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  &nbsp;– Cihaz ID və növü, IP ünvanı, saat qurşağı, əməliyyat
                  sistemi kimi aparat və proqram təminatı məlumatları və
                  cihaz(lar)ınızı unikal şəkildə müəyyən edə bilən, kukilər və
                  ya digər texnologiyalarla əlaqəli və xidmətlərimizə daxil
                  olmaq üçün istifadə etdiyiniz identifikatorlar da bizim
                  tərəfimizdən toplana bilər;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  -&nbsp;
                </span>
                <em>
                  <span
                    style={{
                      fontSize: '12.0000pt',
                    }}
                  >
                    İcazənizlə əlavə məlumatlar&nbsp;
                  </span>
                </em>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  – İstifadə etdiyiniz xidmətdən və cihazdan asılı olaraq, GPS,
                  Blutuz və ya Wi-Fi bağlantıları vasitəsilə sizin razılığınızla
                  dəqiq geolokasiyanızı əldə edə bilərik.
                </span>
              </li>
            </ul>
            <h1 style={{ marginTop: '8.0000pt', marginBottom: '8.0000pt' }}>
              <strong>
                <span
                  style={{
                    color: 'rgb(0,0,0)',
                    fontSize: '16px',
                  }}
                >
                  KUKİLƏR VƏ DİGƏR OXŞAR TEXNOLOGİYALAR&nbsp;
                </span>
              </strong>
            </h1>
            <p
              style={{
                marginTop: '5.0000pt',
                marginBottom: '5.0000pt',
                textIndent: '18.0000pt',
                textAlign: 'justify',
                lineHeight: '114%',
              }}
            >
              <span
                style={{
                  lineHeight: '114%',
                  fontSize: '16px',
                }}
              >
                &nbsp;
              </span>
              <span
                style={{
                  lineHeight: '114%',
                  color: 'rgb(0,0,0)',
                  fontSize: '16px',
                }}
              >
                Seçimlərinizi saxlamaq, girişinizi daha rahat etmək, istifadəçi
                hesabı məlumatlarını yadda saxlamaq, çoxsaylı giriş ehtiyacını
                azaltmaq, xidmətlərimizi həyata keçirmək və xidmətdən necə və
                kimlər tərəfindən istifadə edildiyini bilmək məqsədilə siz və/və
                ya cihaz(lar)ınız haqqında məlumatların toplanması və istifadəsi
                üçün kukilərdən və oxşar texnologiyalardan istifadə edirik.
                Cihazınızın parametrlərindən kukiləri deaktiv etmək hüququnuz
                var, baxmayaraq ki, bu halda xidmətlərimizin bəzi funksiyaları
                düzgün işləməyə bilər.&nbsp;
              </span>
            </p>
            <h1 style={{ marginTop: '8.0000pt', marginBottom: '8.0000pt' }}>
              <strong>
                <span
                  style={{
                    color: 'rgb(0,0,0)',
                    fontSize: '16px',
                  }}
                >
                  MƏLUMATLARINIZI NƏ ÜÇÜN İSTİFADƏ EDİRİK?&nbsp;
                </span>
              </strong>
            </h1>
            <p
              style={{
                marginTop: '5.0000pt',
                marginBottom: '5.0000pt',
                textIndent: '18.0000pt',
                textAlign: 'justify',
                lineHeight: '114%',
              }}
            >
              <span
                style={{
                  lineHeight: '114%',
                  fontSize: '16px',
                }}
              >
                Məlumatlarınızı toplamaq, istifadə etmək və açıqlamağımızın əsas
                səbəbləri aşağıdakılard
              </span>
              <span
                style={{
                  lineHeight: '114%',
                  fontSize: '16px',
                }}
              >
                ı
              </span>
              <span
                style={{
                  lineHeight: '114%',
                  fontSize: '16px',
                }}
              >
                r:
              </span>
            </p>
            <ul style={{ listStyleType: 'undefined', marginLeft: '17px' }}>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  -&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  Xidmətlərimizi inkişaf etdirmək, təkmilləşdirmək və çatdırmaq
                  və sizə yeni KidFi xidmətləri təqdim etmək;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  -&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  Ö
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  vlad (lar) ınızla əlaqə saxlamağınızı asanlaşdırmaq ü
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  çün
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  &nbsp;sizə&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  h
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  esab yaratmaq və idarə etmək;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  -&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  Əməliyyatlarınızı həyata keçirm
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  ək
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  ;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  -&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  Hans
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  ı&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  funksiyaları&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  t
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  ə
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  kmill
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  əş
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  dirm
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  ə
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  li
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  &nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  oldu
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  ğ
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  umuzu
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  &nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  anlamaq
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  &nbsp;üçü
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  n
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  &nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  t
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  ə
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  tbiqd
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  ə
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  &nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  profilinizi
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  ,&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  f
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  ə
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  aliyy
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  ə
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  tinizi
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  &nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  v
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  ə&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  se
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  ç
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  iml
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  ə
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  rinizi
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  &nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  t
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  ə
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  hlil
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  &nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  etm
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  ə
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  k
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  ;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  -&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  Sizi
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  &nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  m
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  üş
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  t
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  ə
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  ri
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  &nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  d
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  ə
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  st
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  ə
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  yi
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  &nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  xidm
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  ə
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  ti
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  &nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  il
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  ə&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  t
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  ə
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  min
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  &nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  etm
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  ə
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  k
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  &nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  v
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  ə&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  sor
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  ğ
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  ular
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  ı
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  n
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  ı
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  za
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  &nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  cavab
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  &nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  verm
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  ə
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  k
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  ;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  -&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  Sizin
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  &nbsp;üçü
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  n
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  &nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  maraql
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  ı&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  ola
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  &nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  bil
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  ə
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  c
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  ə
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  yini
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  &nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  d
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  üşü
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  nd
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  ü
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  y
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  ü
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  m
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  ü
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  z
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  &nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  m
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  ə
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  hsul
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  &nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  v
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  ə&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  ya
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  &nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  xidm
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  ə
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  tl
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  ə
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  r
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  &nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  haqq
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  ı
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  nda
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  &nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  sizinl
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  ə
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  &nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  ə
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  laq
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  ə&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  saxlamaq
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  ;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  -&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  F
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  ı
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  r
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  ı
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  ldaq
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  çı
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  l
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  ı
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  q
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  &nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  v
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  ə&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  ya
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  &nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  dig
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  ə
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  r
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  &nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  qanunsuz
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  &nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  v
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  ə&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  ya
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  &nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  icaz
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  ə
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  siz
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  &nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  f
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  ə
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  aliyy
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  ə
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  tl
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  ə
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  rin
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  &nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  qar
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  şı
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  s
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  ı
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  n
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  ı&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  almaq
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  ;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  -&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  Qanun
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  i
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  &nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  t
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  ə
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  l
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  ə
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  bl
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  ə
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  r
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  ə ə
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  m
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  ə
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  l
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  &nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  etm
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  ə
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  k
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  &nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  v
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  ə&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  h
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  ü
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  quq
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  -
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  m
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  ü
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  hafiz
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  ə&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  orqanlar
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  ı
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  na
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  &nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  k
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  ö
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  m
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  ə
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  klik
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  &nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  g
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  ö
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  st
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  ə
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  rm
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  ə
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  k
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  .
                </span>
              </li>
            </ul>
            <h1>
              <strong>
                <span
                  style={{
                    color: 'rgb(0,0,0)',
                    fontSize: '16px',
                  }}
                >
                  M
                </span>
              </strong>
              <strong>
                <span
                  style={{
                    color: 'rgb(0,0,0)',
                    fontSize: '16px',
                  }}
                >
                  Ə
                </span>
              </strong>
              <strong>
                <span
                  style={{
                    color: 'rgb(0,0,0)',
                    fontSize: '16px',
                  }}
                >
                  LUMATLARINIZI
                </span>
              </strong>
              <strong>
                <span
                  style={{
                    color: 'rgb(0,0,0)',
                    fontSize: '16px',
                  }}
                >
                  &nbsp;
                </span>
              </strong>
              <strong>
                <span
                  style={{
                    color: 'rgb(0,0,0)',
                    fontSize: '16px',
                  }}
                >
                  NEC
                </span>
              </strong>
              <strong>
                <span
                  style={{
                    color: 'rgb(0,0,0)',
                    fontSize: '16px',
                  }}
                >
                  Ə&nbsp;
                </span>
              </strong>
              <strong>
                <span
                  style={{
                    color: 'rgb(0,0,0)',
                    fontSize: '16px',
                  }}
                >
                  PAYLA
                </span>
              </strong>
              <strong>
                <span
                  style={{
                    color: 'rgb(0,0,0)',
                    fontSize: '16px',
                  }}
                >
                  Ş
                </span>
              </strong>
              <strong>
                <span
                  style={{
                    color: 'rgb(0,0,0)',
                    fontSize: '16px',
                  }}
                >
                  IRIQ
                </span>
              </strong>
              <strong>
                <span
                  style={{
                    color: 'rgb(0,0,0)',
                    fontSize: '16px',
                  }}
                >
                  ?
                </span>
              </strong>
            </h1>
            <p
              style={{
                marginTop: '5.0000pt',
                marginBottom: '5.0000pt',
                textIndent: '18.0000pt',
                textAlign: 'justify',
                lineHeight: '114%',
              }}
            >
              <span
                style={{
                  lineHeight: '114%',
                  color: 'rgb(0,0,0)',
                  fontSize: '16px',
                }}
              >
                Məlumatlarınız üçüncü şəxslərlə yalnız bu Məxfilik Siyasətində
                göstərilən qaydalara uyğun olaraq paylaşılacaq.
              </span>
            </p>
            <p
              style={{
                marginTop: '5.0000pt',
                marginBottom: '5.0000pt',
                textIndent: '18.0000pt',
                textAlign: 'justify',
                lineHeight: '114%',
              }}
            >
              <span
                style={{
                  lineHeight: '114%',
                  color: 'rgb(0,0,0)',
                  fontSize: '16px',
                }}
              >
                Biz xidmətlərimizi idarə etməyə və təkmilləşdirməyə kömək edən
                partnyorlarımız və xidmət təminatçıları ilə bəzi məlumatlarınızı
                paylaşırıq. Belə üçüncü şəxslər bizə müştəri xidməti, analitika,
                ödənişlərin emalı və təhlükəsizlik əməliyyatları da daxil
                olmaqla bir çox formada dəstək göstərir. Bütün partnyorlarımız
                və xidmət təminatçılarımız güclü məxfilik tələblərinə razı
                olmalıdırlar.
              </span>
            </p>
            <p
              style={{
                marginTop: '5.0000pt',
                marginBottom: '5.0000pt',
                textIndent: '18.0000pt',
                textAlign: 'justify',
                lineHeight: '114%',
              }}
            >
              <span
                style={{
                  lineHeight: '114%',
                  fontSize: '16px',
                }}
              >
                Qanuni olaraq zərurət yarandığı halda, müvafiq qanunlara və
                prosedurlara riayət etmək məqsədilə şirkət auditi kimi səbəblərə
                görə məlumatlarınızı digər şirkətlərlə də paylaşa bilərik.
              </span>
            </p>
            <p
              style={{
                marginTop: '5.0000pt',
                marginBottom: '5.0000pt',
                textIndent: '18.0000pt',
                textAlign: 'justify',
                lineHeight: '114%',
              }}
            >
              <span
                style={{
                  lineHeight: '114%',
                  fontSize: '16px',
                }}
              >
                Bu Məxfilik Siyasətinin məqsədləri üçün əsaslı surətdə zəruri
                olarsa, məlumatlarınızı şəxsi heyətimizə, qrupumuzun hər hansı
                üzvünə, təchizatçılara və ya subpodratçılara açıqlaya bilərik,
                bir şərtlə ki, onlar sizin məlumatlarınızdan müstəqil şəkildə
                istifadə etməsinlər və məlumatlarınızı qorumağa razılıq
                versinlər.&nbsp;
              </span>
            </p>
            <p
              style={{
                marginTop: '5.0000pt',
                marginBottom: '5.0000pt',
                textIndent: '18.0000pt',
                textAlign: 'justify',
                lineHeight: '114%',
              }}
            >
              <span
                style={{
                  lineHeight: '114%',
                  fontSize: '16px',
                }}
              >
                Aktivlərimizin hamısının və ya bir hissəsinin bizim tərəfimizdən
                satılması, birləşmə, alınma, bölünmə, ayrılma və ya
                transformasiya hallarında, aktivlərimizi satdığımız hər hansı
                üçüncü şəxs bu Məxfilik Siyasətindəki qaydalara uyğun olaraq
                məlumatlarınızı istifadə etməyə davam etmək hüququna malik
                olacaq.
              </span>
            </p>
            <h1 style={{ marginTop: '8.0000pt', marginBottom: '8.0000pt' }}>
              <strong>
                <span
                  style={{
                    color: 'rgb(0,0,0)',
                    fontSize: '16px',
                  }}
                >
                  HÜQUQLARINIZ
                </span>
              </strong>
            </h1>
            <p
              style={{
                marginTop: '5.0000pt',
                marginBottom: '5.0000pt',
                textIndent: '18.0000pt',
                textAlign: 'justify',
                lineHeight: '114%',
              }}
            >
              <span
                style={{
                  lineHeight: '114%',
                  color: 'rgb(0,0,0)',
                  fontSize: '16px',
                }}
              >
                Məlumatlarınızı necə topladığımız, istifadə etdiyimiz və emal
                etdiyimizlə bağlı sizin aşağıdakı hüquqlarınız var:
              </span>
            </p>
            <ul style={{ listStyleType: 'undefined', marginLeft: '8px' }}>
              <li>
                <span
                  style={{
                    color: 'rgb(0,0,0)',
                    fontSize: '12.0000pt',
                  }}
                >
                  1.&nbsp;
                </span>
                <em>
                  <span
                    style={{
                      fontSize: '16px',
                      color: 'rgb(0,0,0)',
                    }}
                  >
                    Giriş və məlumatlarınızın nüsxəsini əldə etmək&nbsp;
                  </span>
                </em>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  –&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '16px',
                    color: 'rgb(0,0,0)',
                  }}
                >
                  İstənilən vaxt siz və ya övlad (lar) ınız haqqında bizdə olan
                  məlumatların surətini əldə etmək və onun məzmunu ilə tanış
                  olmaq üçün müraciət edə bilərsiniz;
                </span>
              </li>
              <li>
                <span
                  style={{
                    color: 'rgb(0,0,0)',
                    fontSize: '12.0000pt',
                  }}
                >
                  2.&nbsp;
                </span>
                <em>
                  <span
                    style={{
                      fontSize: '16px',
                      color: 'rgb(0,0,0)',
                    }}
                  >
                    Məlumatlarınızı düzəltmək və siz və ya övlad(lar)ınız
                    haqqında məlumatların işlənməsinə etiraz etmək
                  </span>
                </em>
                <span
                  style={{
                    fontSize: '16px',
                    color: 'rgb(0,0,0)',
                  }}
                >
                  &nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  –&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '16px',
                    color: 'rgb(0,0,0)',
                  }}
                >
                  Əgər siz və ya övlad(lar)ınız haqqında saxladığımız
                  məlumatların qeyri-dəqiq və ya natamam olduğuna inanırsınızsa
                  və ya əldə edildiyimiz səbəblərə görə onlardan artıq istifadə
                  etmək hüququmuz yoxdursa və onların düzəldilməsini,
                  silinməsini və ya emal edilməsinə etiraz etmək istəyirsinizsə,
                  bizimlə əlaqə saxlaya bilərsiniz;
                </span>
              </li>
              <li>
                <span
                  style={{
                    color: 'rgb(0,0,0)',
                    fontSize: '12.0000pt',
                  }}
                >
                  3.&nbsp;
                </span>
                <em>
                  <span
                    style={{
                      fontSize: '16px',
                      color: 'rgb(0,0,0)',
                    }}
                  >
                    Məlumatlarınızı və mobil tətbiqi silmək&nbsp;
                  </span>
                </em>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  – Siz istənilən vaxt hesabınızı birbaşa tətbiqdə silə və
                  cihazınız üçün standart silmə prosesindən istifadə edərək
                  tətbiqi silməklə tətbiq tərəfindən bütün məlumatların
                  toplanmasını dayandıra bilərsiniz;
                </span>
              </li>
              <li>
                <span
                  style={{
                    color: 'rgb(0,0,0)',
                    fontSize: '12.0000pt',
                  }}
                >
                  4.&nbsp;
                </span>
                <em>
                  <span
                    style={{
                      fontSize: '16px',
                      color: 'rgb(0,0,0)',
                    }}
                  >
                    Müvafiq orqana şikayət etmək&nbsp;
                  </span>
                </em>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  –&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '16px',
                    color: 'rgb(0,0,0)',
                  }}
                >
                  Məlumatlarınızı toplamağımız və emal etməyimizlə bağlı hər
                  hansı narahatlığınız varsa,&nbsp;
                </span>
                <a href="mailto:info@kidfi.co">
                  <strong>
                    <u>
                      <span
                        style={{
                          fontSize: '12.0000pt',
                          color: 'rgb(5,99,193)',
                          textDecoration: 'underline',
                        }}
                      >
                        info@kidfi.co
                      </span>
                    </u>
                  </strong>
                </a>
                <strong>
                  <span
                    style={{
                      fontSize: '16px',
                      color: 'rgb(0,0,0)',
                    }}
                  >
                    &nbsp;
                  </span>
                </strong>
                <span
                  style={{
                    fontSize: '16px',
                    color: 'rgb(0,0,0)',
                  }}
                >
                  vasitəsilə əlaqə saxlayaraq bizə bildirin. Bizimlə əlaqə
                  saxladıqdan sonra hüquqlarınızın həyata keçirilmədiyini
                  düşünürsünüzsə, müvafiq icra hakimiyyəti orqanına və ya
                  məhkəməyə şikayət edə, sizə dəyən mənəvi və maddi zərərin
                  ödənilməsini tələb edə bilərsiniz.
                </span>
              </li>
            </ul>
            <h1 style={{ marginTop: '8.0000pt', marginBottom: '8.0000pt' }}>
              <strong>
                <span
                  style={{
                    color: 'rgb(0,0,0)',
                    fontSize: '16px',
                  }}
                >
                  MƏLUMATLARINIZI NECƏ QORUYURUQ?
                </span>
              </strong>
            </h1>
            <p
              style={{
                marginTop: '5.0000pt',
                marginBottom: '5.0000pt',
                textIndent: '22.5000pt',
                textAlign: 'justify',
                lineHeight: '114%',
              }}
            >
              <span
                style={{
                  lineHeight: '114%',
                  color: 'rgb(0,0,0)',
                  fontSize: '16px',
                }}
              >
                Biz siz və övlad(lar)ınız haqqında məlumatları icazəsiz
                girişdən, açıqlanmadan, dəyişdirilmədən və ya silinmədən qorumaq
                üçün tədbirlər görürük. Lakin biz onların təhlükəsizliyinə
                həmişə zəmanət verə bilmərik. Fiziki, texniki və təşkilati
                təhlükəsizlik tədbirlərimizi müasir formada saxlamaq üçün
                məlumat toplama, saxlama və emal prosedurlarımızı davamlı olaraq
                yoxlayırıq, eyni zamanda potensial zəifliklər və təhlükələrə
                qarşı sistemlərimizi nəzarət altında saxlayırıq. Hər hansı bir
                təhlükəsizlik pozuntusundan şübhələnsək və ya müəyyən etsək,
                xidmətlərimizin hamısından və ya bir hissəsindən istifadənizi
                dərhal dayandırmaq hüququmuz var.
              </span>
            </p>
            <p
              style={{
                marginTop: '5.0000pt',
                marginBottom: '5.0000pt',
                textIndent: '22.5000pt',
                textAlign: 'justify',
                lineHeight: '114%',
              }}
            >
              <span
                style={{
                  lineHeight: '114%',
                  color: 'rgb(0,0,0)',
                  fontSize: '16px',
                }}
              >
                Hesabınızın və ya məlumatlarınızın təhlükəsizliyinin pozulduğunu
                düşünürsünüzsə,&nbsp;
              </span>
              <a href="mailto:info@kidfi.co">
                <strong>
                  <u>
                    <span
                      style={{
                        lineHeight: '114%',
                        color: 'rgb(5,99,193)',
                        textDecoration: 'underline',
                        fontSize: '16px',
                      }}
                    >
                      info@kidfi.co
                    </span>
                  </u>
                </strong>
              </a>
              <span
                style={{
                  lineHeight: '114%',
                  color: 'rgb(0,0,0)',
                  fontSize: '16px',
                }}
              >
                &nbsp;vasitəsilə bizə bildirin.&nbsp;
              </span>
            </p>
            <h1 style={{ marginTop: '5.0000pt', marginBottom: '5.0000pt' }}>
              <strong>
                <span
                  style={{
                    color: 'rgb(0,0,0)',
                    fontSize: '16px',
                  }}
                >
                  MƏLUMATLARIN SAXLANMASI
                </span>
              </strong>
            </h1>
            <p
              style={{
                marginTop: '5.0000pt',
                marginBottom: '5.0000pt',
                textIndent: '18.0000pt',
                textAlign: 'justify',
                lineHeight: '114%',
              }}
            >
              <span
                style={{
                  lineHeight: '114%',
                  fontSize: '16px',
                }}
              >
                Biz məlumatlarınızı sistemlərimizdə yalnız sizə xidmətlərimizi
                təqdim etmək vəzifəmizi yerinə yetirmək məqsədi ilə və ya bu
                Məxfilik Siyasətində başqa cür müəyyən edildiyi formada saxlayır
                və istifadə edirik. Məlumatlarınızı konkret məqsədlərə nail
                olmaq üçün lazım olandan artıq saxlamırıq. Hesabınızı sildiyiniz
                zaman və ya bir illik fəaliyyətsizlikdən sonra, qanuni tələblərə
                riayət etmək, qanuni tələblərə əməl etdiyimizi göstərmək, zəruri
                iddia və ya mübahisəni həll etmək və fırıldaqçılığın qarşısının
                alınması və istifadəçi təhlükəsizliyinin artırılması kimi qanuni
                biznes məqsədlərimiz üçün saxlamağa ehtiyac olmadığı halda, biz
                məlumatlarınızı silirik.&nbsp;
              </span>
            </p>
            <p
              style={{
                marginTop: '5.0000pt',
                marginBottom: '5.0000pt',
                textIndent: '18.0000pt',
                textAlign: 'justify',
                lineHeight: '114%',
              }}
            >
              <span
                style={{
                  lineHeight: '114%',
                  fontSize: '16px',
                }}
              >
                Unutmayın ki, sistemlərimiz yuxarıda qeyd olunan qaydalara
                əsasən məlumatlarınızı silmək üçün nəzərdə tutulmuş olsa da,
                texniki məhdudiyyətlər səbəbindən bütün məlumatların müəyyən
                vaxt ərzində silinəcəyinə zəmanət verə bilmirik.
              </span>
            </p>
            <h1 style={{ marginTop: '7.0000pt', marginBottom: '7.0000pt' }}>
              <strong>
                <span
                  style={{
                    color: 'rgb(0,0,0)',
                    fontSize: '16px',
                  }}
                >
                  UŞAQLARIN MƏXFİLİYİ&nbsp;
                </span>
              </strong>
            </h1>
            <p
              style={{
                marginTop: '7.0000pt',
                marginBottom: '7.0000pt',
                textIndent: '18.0000pt',
                textAlign: 'justify',
                lineHeight: '114%',
              }}
            >
              <span
                style={{
                  lineHeight: '114%',
                  fontSize: '16px',
                }}
              >
                Valideynlər üçün xidmətlərimiz 18 yaş və ya daha yuxarı olan
                istifadəçilər üçün nəzərdə tutulub. Biz, valideyn(lər)in
                tətbiqimizdə övlad(lar)ı əlavə etmə prosesində təqdim etdiyi
                məlumatlar istisna olmaqla, 18 yaşından kiçik heç kimdən şəxsi
                məlumatları bilərəkdən toplamırıq. Uşaqların giriş məlumatları
                autentifikasiya xidmətlərimizdə saxlanılan ayrıca hesabları var.
              </span>
            </p>
            <h1 style={{ marginTop: '7.0000pt', marginBottom: '7.0000pt' }}>
              <strong>
                <span
                  style={{
                    color: 'rgb(0,0,0)',
                    fontSize: '16px',
                  }}
                >
                  MƏXFİLİK SİYASƏTİNƏ DƏYİŞİKLİKLƏR&nbsp;
                </span>
              </strong>
            </h1>
            <p
              style={{
                marginTop: '7.0000pt',
                marginBottom: '7.0000pt',
                textIndent: '18.0000pt',
                textAlign: 'justify',
                lineHeight: '114%',
              }}
            >
              <span
                style={{
                  lineHeight: '114%',
                  fontSize: '16px',
                }}
              >
                Övlad(lar)ınızı daha effektiv şəkildə öyrətməyinizə köməklik
                etmək üçün zəruri gördüyümüz zaman cari Məxfilik Siyasətinə
                dəyişiklik etmək hüququnu özümüzdə saxlayırıq. Biz sizə hər
                hansı əhəmiyyətli dəyişikliklər barədə əvvəlcədən məlumat
                verəcəyik ki, onları vaxtında nəzərdən keçirə biləsiniz.
              </span>
            </p>
            <h1 style={{ marginTop: '7.0000pt', marginBottom: '7.0000pt' }}>
              <strong>
                <span
                  style={{
                    color: 'rgb(0,0,0)',
                    fontSize: '16px',
                  }}
                >
                  ƏLAQƏ
                </span>
              </strong>
            </h1>
            <p
              style={{
                marginTop: '5.0000pt',
                marginBottom: '5.0000pt',
                textIndent: '18.0000pt',
                textAlign: 'justify',
                lineHeight: '114%',
              }}
            >
              <span
                style={{
                  lineHeight: '114%',
                  fontSize: '16px',
                }}
              >
                Bu Məxfilik Siyasəti və ya digər məxfilik məsələləri ilə bağlı
                hər hansı tövsiyə və ya sualınız olarsa, onları&nbsp;
              </span>
              <a href="mailto:info@kidfi.co">
                <strong>
                  <u>
                    <span
                      style={{
                        lineHeight: '114%',
                        color: 'rgb(5,99,193)',
                        textDecoration: 'underline',
                        fontSize: '16px',
                      }}
                    >
                      info@kidfi.co
                    </span>
                  </u>
                </strong>
              </a>
              <strong>
                <span
                  style={{
                    lineHeight: '114%',
                    color: 'rgb(0,0,0)',
                    fontSize: '16px',
                  }}
                >
                  &nbsp;
                </span>
              </strong>
              <span
                style={{
                  lineHeight: '114%',
                  fontSize: '16px',
                }}
              >
                vasitəsilə bizə yönləndirə bilərsiniz.
              </span>
            </p>
            <br />
            <p
              style={{
                marginTop: '5.0000pt',
                marginBottom: '5.0000pt',
                lineHeight: '114%',
              }}
            >
              <strong>
                <span
                  style={{
                    lineHeight: '114%',
                    color: 'rgb(0,0,0)',
                    fontSize: '16px',
                  }}
                >
                  “KIDFI” MMC
                </span>
              </strong>
            </p>
            <p
              style={{
                marginTop: '5.0000pt',
                marginBottom: '5.0000pt',
                lineHeight: '114%',
              }}
            >
              <strong>
                <span
                  style={{
                    lineHeight: '114%',
                    color: 'rgb(0,0,0)',
                    fontSize: '16px',
                  }}
                >
                  1405934651, Bakı şəhəri, Nəsimi rayonu, Ə.Hüseynov küçəsi,
                </span>
              </strong>
              <strong>
                <span
                  style={{
                    lineHeight: '114%',
                    color: 'rgb(0,0,0)',
                    fontWeight: 'bold',
                    fontSize: '16px',
                  }}
                >
                  &nbsp;5/7, 454
                </span>
              </strong>
            </p>
            <p
              style={{
                marginTop: '5.0000pt',
                marginBottom: '5.0000pt',
                textAlign: 'justify',
                lineHeight: '114%',
              }}
            >
              <strong>
                <span
                  style={{
                    lineHeight: '114%',
                    color: 'rgb(0,0,0)',
                    fontSize: '16px',
                  }}
                >
                  Son yenilənmə: Avqust 2022
                </span>
              </strong>
            </p>
          </>
        ) : lang === 'en' ? (
          <>
            <p style={{ textAlign: 'center', lineHeight: '114%' }}>
              <strong>
                <span
                  style={{
                    lineHeight: '114%',
                    fontSize: '16px',
                  }}
                >
                  PRIVACY POLICY
                </span>
              </strong>
            </p>
            <p style={{ textAlign: 'center', lineHeight: '114%' }}>
              <strong>
                <span
                  style={{
                    lineHeight: '114%',
                    fontSize: '16px',
                  }}
                >
                  &nbsp;
                </span>
              </strong>
            </p>
            <p style={{ textAlign: 'justify', lineHeight: '114%' }}>
              <strong>
                <span
                  style={{
                    lineHeight: '114%',
                    color: 'rgb(0,0,0)',
                    fontSize: '16px',
                  }}
                >
                  ISSUED BY “KIDFI” LLC
                </span>
              </strong>
            </p>
            <p
              style={{
                marginTop: '5.0000pt',
                marginBottom: '5.0000pt',
                textIndent: '18.0000pt',
                textAlign: 'justify',
                lineHeight: '114%',
              }}
            >
              <span
                style={{
                  lineHeight: '114%',
                  color: 'rgb(0,0,0)',
                  fontSize: '16px',
                }}
              >
                This Privacy Policy explains the procedures and policies
                of&nbsp;
              </span>
              <strong>
                <span
                  style={{
                    lineHeight: '114%',
                    color: 'rgb(0,0,0)',
                    fontSize: '16px',
                  }}
                >
                  “KIDFI” LLC
                </span>
              </strong>
              <span
                style={{
                  lineHeight: '114%',
                  color: 'rgb(0,0,0)',
                  fontSize: '16px',
                }}
              >
                &nbsp;(
              </span>
              <span
                style={{
                  lineHeight: '114%',
                  color: 'rgb(0,0,0)',
                  fontSize: '16px',
                }}
              >
                hereinafter referred to as&nbsp;
              </span>
              <span
                style={{
                  lineHeight: '114%',
                  color: 'rgb(0,0,0)',
                  fontSize: '16px',
                }}
              >
                “we”, “our”, or “us”),&nbsp;
              </span>
              <span
                style={{
                  lineHeight: '114%',
                  color: 'rgb(0,0,0)',
                  fontSize: '16px',
                }}
              >
                a legal entity incorporated under the law of the Republic of
                Azerbaijan (1405934651, with an office in Baku city, Nasimi
                district, A.Huseynov street,
              </span>
              <span
                style={{
                  lineHeight: '114%',
                  color: 'rgb(0,0,0)',
                  fontSize: '16px',
                }}
              >
                &nbsp;5/7, 454)
              </span>
              <strong>
                <span
                  style={{
                    lineHeight: '114%',
                    color: 'rgb(0,0,0)',
                    fontWeight: 'bold',
                    fontSize: '16px',
                  }}
                >
                  &nbsp;
                </span>
              </strong>
              <span
                style={{
                  lineHeight: '114%',
                  color: 'rgb(0,0,0)',
                  fontSize: '16px',
                }}
              >
                and
              </span>
              <strong>
                <span
                  style={{
                    lineHeight: '114%',
                    color: 'rgb(0,0,0)',
                    fontSize: '16px',
                  }}
                >
                  &nbsp;
                </span>
              </strong>
              <span
                style={{
                  lineHeight: '114%',
                  color: 'rgb(0,0,0)',
                  fontSize: '16px',
                }}
              >
                its partners
              </span>
              <span
                style={{
                  lineHeight: '114%',
                  color: 'rgb(255,0,0)',
                  fontSize: '16px',
                }}
              >
                &nbsp;
              </span>
              <span
                style={{
                  lineHeight: '114%',
                  color: 'rgb(0,0,0)',
                  fontSize: '16px',
                }}
              >
                on how a user’s information (
              </span>
              <span
                style={{
                  lineHeight: '114%',
                  color: 'rgb(0,0,0)',
                  fontSize: '16px',
                }}
              >
                hereinafter referred to as “you”)
              </span>
              <span
                style={{
                  lineHeight: '114%',
                  color: 'rgb(0,0,0)',
                  fontSize: '16px',
                }}
              >
                &nbsp;is collected, utilized, and disclosed by us and our
                partners when you use our mobile application “KidFi” (
              </span>
              <span
                style={{
                  lineHeight: '114%',
                  color: 'rgb(0,0,0)',
                  fontSize: '16px',
                }}
              >
                hereinafter referred to as
              </span>
              <span
                style={{
                  lineHeight: '114%',
                  color: 'rgb(0,0,0)',
                  fontSize: '16px',
                }}
              >
                &nbsp;the “services”). &nbsp;&nbsp;
              </span>
            </p>
            <p
              style={{
                marginTop: '5.0000pt',
                marginBottom: '5.0000pt',
                textIndent: '18.0000pt',
                textAlign: 'justify',
                lineHeight: '114%',
              }}
            >
              <span
                style={{
                  lineHeight: '114%',
                  color: 'rgb(0,0,0)',
                  fontSize: '16px',
                }}
              >
                Please be aware that&nbsp;
              </span>
              <span
                style={{
                  lineHeight: '114%',
                  color: 'rgb(0,0,0)',
                  fontSize: '16px',
                }}
              >
                you agree to this Privacy Policy by checking the appropriate box
                during the registration process.
              </span>
            </p>
            <p
              style={{
                marginTop: '5.0000pt',
                marginBottom: '5.0000pt',
                textIndent: '18.0000pt',
                textAlign: 'justify',
                lineHeight: '114%',
              }}
            >
              <span
                style={{
                  lineHeight: '114%',
                  fontSize: '16px',
                }}
              >
                As we place a high priority on protecting your security and
                privacy, we abide by all applicable international agreements and
                legislation of the Republic of Azerbaijan governing data privacy
                protection, including the Law of the Republic of Azerbaijan on
                Personal Data, No. 998-IIIQ of May 11, 2010.
              </span>
            </p>
            <p
              style={{
                marginTop: '5.0000pt',
                marginBottom: '5.0000pt',
                textIndent: '18.0000pt',
                textAlign: 'justify',
                lineHeight: '114%',
              }}
            >
              <span
                style={{
                  lineHeight: '114%',
                  color: 'rgb(0,0,0)',
                  fontSize: '16px',
                }}
              >
                Please note that Terms of Use are considered an integral part of
                this Privacy Policy. Together, these documents define the
                relationship between you and us.
              </span>
            </p>
            <h1 style={{ marginTop: '8.0000pt', marginBottom: '8.0000pt' }}>
              <strong>
                <span
                  style={{
                    color: 'rgb(0,0,0)',
                    fontSize: '16px',
                  }}
                >
                  BASIC NOTIONS
                </span>
              </strong>
              <span
                style={{
                  color: 'rgb(46,117,181)',
                  fontSize: '16px',
                }}
              >
                :
              </span>
            </h1>
            <ul
              style={{
                listStyleType: 'undefined',
                marginLeft: '3.6999999999999993px',
              }}
            >
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  -&nbsp;
                </span>
                <em>
                  <span
                    style={{
                      fontSize: '12.0000pt',
                    }}
                  >
                    Personal data
                  </span>
                </em>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  &nbsp;&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  –
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  &nbsp;any information that allows us to directly or indirectly
                  identify you;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  -&nbsp;
                </span>
                <em>
                  <span
                    style={{
                      fontSize: '12.0000pt',
                    }}
                  >
                    The subject of personal data
                  </span>
                </em>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  &nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  –&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  a user about whom personal information is collected,
                  processed, and disclosed;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  -&nbsp;
                </span>
                <em>
                  <span
                    style={{
                      fontSize: '12.0000pt',
                    }}
                  >
                    Kid/child
                  </span>
                </em>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  &nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  –&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  anyone under the age of 18;&nbsp;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  -&nbsp;
                </span>
                <em>
                  <span
                    style={{
                      fontSize: '12.0000pt',
                    }}
                  >
                    Identification number&nbsp;
                  </span>
                </em>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  –&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  a single unique code that allows identifying relevant
                  information about a person;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  -&nbsp;
                </span>
                <em>
                  <span
                    style={{
                      fontSize: '12.0000pt',
                    }}
                  >
                    Collection of personal data
                  </span>
                </em>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  &nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  –&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  obtaining personal data about the subject in accordance with
                  the legislation;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  -&nbsp;
                </span>
                <em>
                  <span
                    style={{
                      fontSize: '12.0000pt',
                    }}
                  >
                    Processing of personal data
                  </span>
                </em>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  &nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  –
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  &nbsp;operations on personal data such as writing,
                  systematization, renewal, change, extraction, anonymization,
                  storage, transmission, and destruction;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  -&nbsp;
                </span>
                <em>
                  <span
                    style={{
                      fontSize: '12.0000pt',
                    }}
                  >
                    Disclosure of personal data
                  </span>
                </em>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  &nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  –&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  making the data available to service providers or our partners
                  - third parties in accordance with applicable laws.&nbsp;
                </span>
              </li>
            </ul>
            <h1>
              <strong>
                <span
                  style={{
                    color: 'rgb(0,0,0)',
                    fontSize: '16px',
                  }}
                >
                  WHO WE ARE?
                </span>
              </strong>
            </h1>
            <p
              style={{
                marginTop: '5.0000pt',
                marginBottom: '5.0000pt',
                textIndent: '18.0000pt',
                textAlign: 'justify',
                lineHeight: '114%',
              }}
            >
              <span
                style={{
                  lineHeight: '114%',
                  fontSize: '16px',
                }}
              >
                The legal entity that is responsible for your information under
                this Privacy Policy (the data controller) is&nbsp;
              </span>
              <span
                style={{
                  lineHeight: '114%',
                  color: 'rgb(0,0,0)',
                  fontSize: '16px',
                }}
              >
                “KIDFI” LLC
              </span>
              <strong>
                <span
                  style={{
                    lineHeight: '114%',
                    color: 'rgb(0,0,0)',
                    fontSize: '16px',
                  }}
                >
                  ,
                </span>
              </strong>
              <span
                style={{
                  lineHeight: '114%',
                  color: 'rgb(0,0,0)',
                  fontSize: '16px',
                }}
              >
                &nbsp;a legal entity incorporated under the law of the Republic
                of Azerbaijan (1405934651, with an office in Baku city, Nasimi
                district, A.Huseynov street,
              </span>
              <span
                style={{
                  lineHeight: '114%',
                  color: 'rgb(0,0,0)',
                  fontSize: '16px',
                }}
              >
                &nbsp;5/7, 454
              </span>
              <strong>
                <span
                  style={{
                    lineHeight: '114%',
                    color: 'rgb(0,0,0)',
                    fontWeight: 'bold',
                    fontSize: '16px',
                  }}
                >
                  ).
                </span>
              </strong>
            </p>
            <p
              style={{
                marginTop: '5.0000pt',
                marginBottom: '5.0000pt',
                textIndent: '18.0000pt',
                textAlign: 'justify',
                lineHeight: '114%',
              }}
            >
              <span
                style={{
                  lineHeight: '114%',
                  color: 'rgb(0,0,0)',
                  fontSize: '16px',
                }}
              >
                We will take all necessary organizational and technical measures
                to safeguard your data against illegal access, accidental loss,
                unauthorized alteration, and other unlawful acts of third
                parties. In the circumstances outlined in this Privacy Policy,
                besides us, some third parties may have access to your data.
              </span>
            </p>
            <h1 style={{ marginTop: '8.0000pt', marginBottom: '8.0000pt' }}>
              <strong>
                <span
                  style={{
                    color: 'rgb(0,0,0)',
                    fontSize: '16px',
                  }}
                >
                  INFORMATION COLLECTED BY US
                </span>
              </strong>
            </h1>
            <p
              style={{
                marginTop: '5.0000pt',
                marginBottom: '5.0000pt',
                textIndent: '18.0000pt',
                textAlign: 'justify',
                lineHeight: '114%',
              }}
            >
              <span
                style={{
                  lineHeight: '114%',
                  fontSize: '16px',
                }}
              >
                It should be mentioned that to support our services, we need to
                collect certain information about yourself and your kid (s).
                This includes:
              </span>
            </p>
            <ul style={{ listStyleType: 'undefined', marginLeft: '3.5px' }}>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  -&nbsp;
                </span>
                <em>
                  <span
                    style={{
                      fontSize: '12.0000pt',
                    }}
                  >
                    Information provided by you&nbsp;
                  </span>
                </em>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  – While signing up and creating an account, you provide us
                  with login information, such as your full name, phone number,
                  date of birth, gender, and promo code (if any). You may choose
                  to add to your profile information such as your profile photo,
                  your location, and your electronic address. For realizing
                  financial operations, you should provide us with your card
                  information, and creating a digital card, you should provide
                  information, such as your Identification Number. When adding
                  your kids, you share with us information such as the kid’s
                  full name, date of birth, gender, reward amount and promo code
                  (if any), and optionally profile photo. While your child is
                  passing quizzes, he/she provides us with information such as
                  his/her answers to the questions. In case you contact our
                  customer support team, the information you share with us
                  during the interaction is collected by us;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  -&nbsp;
                </span>
                <em>
                  <span
                    style={{
                      fontSize: '12.0000pt',
                    }}
                  >
                    Usage information
                  </span>
                </em>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  &nbsp;– Information about your activity on our services,
                  features you have been using, how you use them such as the
                  date and time you logged in, clicks, and pages shown to you
                  are collected &nbsp;by us;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  -&nbsp;
                </span>
                <em>
                  <span
                    style={{
                      fontSize: '12.0000pt',
                    }}
                  >
                    Device data
                  </span>
                </em>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  &nbsp;– including hardware and software information such as
                  device ID and type, IP address, time zones, operating system,
                  and identifiers linked to cookies or other technologies that
                  may uniquely identify your device(s), used by you to access
                  our services may also be collected by us;&nbsp;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  -&nbsp;
                </span>
                <em>
                  <span
                    style={{
                      fontSize: '12.0000pt',
                    }}
                  >
                    Additional information with your approval
                  </span>
                </em>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  &nbsp;– Depending on the service and device you use, we may
                  obtain your precise geolocation with your consent through GPS,
                  Bluetooth, or Wi-Fi connections.
                </span>
              </li>
            </ul>
            <h1 style={{ marginTop: '8.0000pt', marginBottom: '8.0000pt' }}>
              <strong>
                <span
                  style={{
                    color: 'rgb(0,0,0)',
                    fontSize: '16px',
                  }}
                >
                  COOKIES AND OTHER SIMILAR TECHNOLOGIES
                </span>
              </strong>
            </h1>
            <p
              style={{
                marginTop: '5.0000pt',
                marginBottom: '5.0000pt',
                textIndent: '18.0000pt',
                textAlign: 'justify',
                lineHeight: '114%',
              }}
            >
              <span
                style={{
                  lineHeight: '114%',
                  fontSize: '16px',
                }}
              >
                &nbsp;We use cookies and similar technologies&nbsp;
              </span>
              <span
                style={{
                  lineHeight: '114%',
                  color: 'rgb(0,0,0)',
                  fontSize: '16px',
                }}
              >
                for the collection and use of information about&nbsp;
              </span>
              <span
                style={{
                  lineHeight: '114%',
                  fontSize: '16px',
                }}
              >
                you and/or your device(s)&nbsp;
              </span>
              <span
                style={{
                  lineHeight: '114%',
                  color: 'rgb(0,0,0)',
                  fontSize: '16px',
                }}
              >
                to&nbsp;
              </span>
              <span
                style={{
                  lineHeight: '114%',
                  color: 'rgb(0,0,0)',
                  fontSize: '16px',
                }}
              >
                store your preferences, make your visit more pleasurable,
                remember user account information, reduce the need for multiple
                log-ins,
              </span>
              <span
                style={{
                  lineHeight: '114%',
                  color: 'rgb(0,0,0)',
                  fontSize: '16px',
                }}
              >
                &nbsp;to make our services function and to know how and by whom
                the service is utilized. Though some functions of our service
                may not operate then properly,&nbsp;
              </span>
              <span
                style={{
                  lineHeight: '114%',
                  color: 'rgb(0,0,0)',
                  fontSize: '16px',
                }}
              >
                you have a right to disable cookies in your device`s settings.
              </span>
            </p>
            <h1 style={{ marginTop: '8.0000pt', marginBottom: '8.0000pt' }}>
              <strong>
                <span
                  style={{
                    color: 'rgb(0,0,0)',
                    fontSize: '16px',
                  }}
                >
                  REASONS FOR WHAT WE USE YOUR INFORMATION
                </span>
              </strong>
            </h1>
            <p
              style={{
                marginTop: '5.0000pt',
                marginBottom: '5.0000pt',
                textIndent: '18.0000pt',
                textAlign: 'justify',
                lineHeight: '114%',
              }}
            >
              <span
                style={{
                  lineHeight: '114%',
                  fontSize: '16px',
                }}
              >
                Followings are the main reasons why we collect, use and disclose
                your information:
              </span>
            </p>
            <ul style={{ listStyleType: 'undefined', marginLeft: '17px' }}>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  -&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  To develop, improve and deliver our services and provide new
                  KidFi services to you;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  -&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  Create and administer your account to facilitate your
                  communication with your kid(s);
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  -&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  Realize your transactions;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  -&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  Analyze your profile, activity, and preferences on the app to
                  understand which features we should improve;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  -&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  Provide you with customer support service and reply to your
                  questions;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  -&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  Communicate with you about products or services that we think
                  might be interesting to you;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  -&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  To prevent fraud or other illegal or unauthorized activities;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  -&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  To comply with legal requirements and assist law-enforcement
                  agencies.
                </span>
              </li>
            </ul>
            <h1>
              <strong>
                <span
                  style={{
                    color: 'rgb(0,0,0)',
                    fontSize: '16px',
                  }}
                >
                  HOW DO WE SHARE YOUR INFORMATION?
                </span>
              </strong>
            </h1>
            <p
              style={{
                marginTop: '5.0000pt',
                marginBottom: '5.0000pt',
                textIndent: '18.0000pt',
                textAlign: 'justify',
                lineHeight: '114%',
              }}
            >
              <span
                style={{
                  lineHeight: '114%',
                  color: 'rgb(0,0,0)',
                  fontSize: '16px',
                }}
              >
                Your data will be shared with third parties only in accordance
                with the guidelines outlined in this Privacy Policy.
              </span>
            </p>
            <p
              style={{
                marginTop: '5.0000pt',
                marginBottom: '5.0000pt',
                textIndent: '18.0000pt',
                textAlign: 'justify',
                lineHeight: '114%',
              }}
            >
              <span
                style={{
                  lineHeight: '114%',
                  color: 'rgb(0,0,0)',
                  fontSize: '16px',
                }}
              >
                With partners and service providers who help us run and improve
                our services, we share some information. These third parties
                support us in several ways, including customer service,
                analytics, processing payments, and security operations. All of
                our partners and service providers must acquiesce to strong
                confidentiality requirements.
              </span>
            </p>
            <p
              style={{
                marginTop: '5.0000pt',
                marginBottom: '5.0000pt',
                textIndent: '18.0000pt',
                textAlign: 'justify',
                lineHeight: '114%',
              }}
            >
              <span
                style={{
                  lineHeight: '114%',
                  fontSize: '16px',
                }}
              >
                If it is legitimately necessary, we may also share information
                with other businesses for legitimate reasons, such as company
                audits, to comply with applicable laws and procedures.
              </span>
            </p>
            <p
              style={{
                marginTop: '5.0000pt',
                marginBottom: '5.0000pt',
                textIndent: '18.0000pt',
                textAlign: 'justify',
                lineHeight: '114%',
              }}
            >
              <span
                style={{
                  lineHeight: '114%',
                  fontSize: '16px',
                }}
              >
                We may disclose your data to our personnel, any member of our
                group, suppliers, or subcontractors if it is reasonably
                necessary for the purposes of this Privacy Policy, provided that
                they do not make independent use of your data, and have agreed
                to safeguard it.
              </span>
            </p>
            <p
              style={{
                marginTop: '5.0000pt',
                marginBottom: '5.0000pt',
                textIndent: '18.0000pt',
                textAlign: 'justify',
                lineHeight: '114%',
              }}
            >
              <span
                style={{
                  lineHeight: '114%',
                  fontSize: '16px',
                }}
              >
                In cases of a merger, acquisition, division, separation,
                transformation, or selling by us all or a portion of our assets,
                any third party to which we sell our assets will have the right
                to continue to use your data according to the rules in this
                Privacy Policy.&nbsp;
              </span>
            </p>
            <h1 style={{ marginTop: '8.0000pt', marginBottom: '8.0000pt' }}>
              <strong>
                <span
                  style={{
                    color: 'rgb(0,0,0)',
                    fontSize: '16px',
                  }}
                >
                  YOUR RIGHTS
                </span>
              </strong>
            </h1>
            <p
              style={{
                marginTop: '5.0000pt',
                marginBottom: '5.0000pt',
                marginLeft: '18.0000pt',
                textAlign: 'justify',
                lineHeight: '114%',
              }}
            >
              <span
                style={{
                  lineHeight: '114%',
                  color: 'rgb(0,0,0)',
                  fontSize: '16px',
                }}
              >
                Regarding how we collect, use, and process your data, you have
                the following rights:
              </span>
            </p>
            <ul style={{ listStyleType: 'undefined', marginLeft: '8px' }}>
              <li>
                <span
                  style={{
                    color: 'rgb(0,0,0)',
                    fontSize: '12.0000pt',
                  }}
                >
                  1.&nbsp;
                </span>
                <em>
                  <span
                    style={{
                      fontSize: '16px',
                      color: 'rgb(0,0,0)',
                    }}
                  >
                    Access and obtain a copy of your data
                  </span>
                </em>
                <span
                  style={{
                    fontSize: '16px',
                    color: 'rgb(0,0,0)',
                  }}
                >
                  &nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  –&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '16px',
                    color: 'rgb(0,0,0)',
                  }}
                >
                  You can any time request to get a copy of the information we
                  have on you or your kid(s) and familiarize yourself with its
                  content;
                </span>
              </li>
              <li>
                <span
                  style={{
                    color: 'rgb(0,0,0)',
                    fontSize: '12.0000pt',
                  }}
                >
                  2.&nbsp;
                </span>
                <em>
                  <span
                    style={{
                      fontSize: '16px',
                      color: 'rgb(0,0,0)',
                    }}
                  >
                    Correct your data and object to us processing data about you
                    or your kid (s)
                  </span>
                </em>
                <span
                  style={{
                    fontSize: '16px',
                    color: 'rgb(0,0,0)',
                  }}
                >
                  &nbsp;–&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  If you believe that information we hold about you or your kid
                  (s)&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '16px',
                    color: 'rgb(0,0,0)',
                  }}
                >
                  is inaccurate or incomplete,&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  or that we are no longer entitled to use it&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '16px',
                    color: 'rgb(0,0,0)',
                  }}
                >
                  for the reasons for which it was obtained
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  &nbsp;and want to request its rectification, deletion or
                  object to its ways of processing, you can contact us
                </span>
                <span
                  style={{
                    fontSize: '16px',
                    color: 'rgb(0,0,0)',
                  }}
                >
                  ;
                </span>
              </li>
              <li>
                <span
                  style={{
                    color: 'rgb(0,0,0)',
                    fontSize: '12.0000pt',
                  }}
                >
                  3.&nbsp;
                </span>
                <em>
                  <span
                    style={{
                      fontSize: '16px',
                      color: 'rgb(0,0,0)',
                    }}
                  >
                    Delete your data and&nbsp;
                  </span>
                </em>
                <em>
                  <span
                    style={{
                      fontSize: '12.0000pt',
                    }}
                  >
                    uninstall
                  </span>
                </em>
                <em>
                  <span
                    style={{
                      fontSize: '16px',
                      color: 'rgb(0,0,0)',
                    }}
                  >
                    &nbsp;the app&nbsp;
                  </span>
                </em>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  – You can delete your account any time directly on the
                  application
                </span>
                <span
                  style={{
                    fontSize: '16px',
                    color: 'rgb(0,0,0)',
                  }}
                >
                  &nbsp;and&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  stop the collection of all information by uninstalling an
                  application using the default uninstall procedure for your
                  device;
                </span>
              </li>
              <li>
                <span
                  style={{
                    color: 'rgb(0,0,0)',
                    fontSize: '12.0000pt',
                  }}
                >
                  4.&nbsp;
                </span>
                <em>
                  <span
                    style={{
                      fontSize: '16px',
                      color: 'rgb(0,0,0)',
                    }}
                  >
                    Make a complaint to the relevant authority
                  </span>
                </em>
                <span
                  style={{
                    fontSize: '16px',
                    color: 'rgb(0,0,0)',
                  }}
                >
                  &nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  –&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '16px',
                    color: 'rgb(0,0,0)',
                  }}
                >
                  Please let us know if you have any concerns about the way we
                  collect and process your data by contacting us via&nbsp;
                </span>
                <a href="mailto:info@kidfi.co">
                  <strong>
                    <u>
                      <span
                        style={{
                          fontSize: '12.0000pt',
                          color: 'rgb(5,99,193)',
                          textDecoration: 'underline',
                        }}
                      >
                        info@kidfi.co
                      </span>
                    </u>
                  </strong>
                </a>
                <strong>
                  <span
                    style={{
                      fontSize: '16px',
                      color: 'rgb(0,0,0)',
                    }}
                  >
                    .&nbsp;
                  </span>
                </strong>
                <span
                  style={{
                    fontSize: '16px',
                    color: 'rgb(0,0,0)',
                  }}
                >
                  After contacting us, if you believe your rights haven’t been
                  realized, you can file a complaint with the appropriate
                  executive authority or court and seek compensation for the
                  moral and material harm you have suffered.
                </span>
              </li>
            </ul>
            <h1 style={{ marginTop: '8.0000pt', marginBottom: '8.0000pt' }}>
              <strong>
                <span
                  style={{
                    color: 'rgb(0,0,0)',
                    fontSize: '16px',
                  }}
                >
                  HOW DO WE SAFEGUARD YOUR INFORMATION?
                </span>
              </strong>
            </h1>
            <p
              style={{
                marginTop: '5.0000pt',
                marginBottom: '5.0000pt',
                textIndent: '18.0000pt',
                textAlign: 'justify',
                lineHeight: '114%',
              }}
            >
              <span
                style={{
                  lineHeight: '114%',
                  fontSize: '16px',
                }}
              >
                We take measures to keep your and your kid (s) information safe
                from unauthorized access, disclosure, change, or erasure.
                However, we cannot guarantee that it will always be secure. To
                keep our physical, technical, and organizational security
                measures up to date, we do routinely check our systems for flaws
                and threats as well as review our data collection, storage, and
                processing practices and policies regularly. If we suspect or
                identify any breach of security, we have the right to
                immediately cease your usage of all or a portion of our
                services.&nbsp;
              </span>
            </p>
            <p
              style={{
                marginTop: '5.0000pt',
                marginBottom: '5.0000pt',
                textIndent: '18.0000pt',
                textAlign: 'justify',
                lineHeight: '114%',
              }}
            >
              <span
                style={{
                  lineHeight: '114%',
                  fontSize: '16px',
                }}
              >
                Please let us know via&nbsp;
              </span>
              <a href="mailto:info@kidfi.co">
                <strong>
                  <u>
                    <span
                      style={{
                        lineHeight: '114%',
                        color: 'rgb(5,99,193)',
                        textDecoration: 'underline',
                        fontSize: '16px',
                      }}
                    >
                      info@kidfi.co
                    </span>
                  </u>
                </strong>
              </a>
              <strong>
                <span
                  style={{
                    lineHeight: '114%',
                    color: 'rgb(0,0,0)',
                    fontSize: '16px',
                  }}
                >
                  &nbsp;
                </span>
              </strong>
              <span
                style={{
                  lineHeight: '114%',
                  fontSize: '16px',
                }}
              >
                if you think the security of your account or your information
                has been breached.
              </span>
            </p>
            <h1 style={{ marginTop: '5.0000pt', marginBottom: '5.0000pt' }}>
              <strong>
                <span
                  style={{
                    color: 'rgb(0,0,0)',
                    fontSize: '16px',
                  }}
                >
                  DATA STORAGE
                </span>
              </strong>
            </h1>
            <p
              style={{
                marginTop: '5.0000pt',
                marginBottom: '5.0000pt',
                textIndent: '18.0000pt',
                textAlign: 'justify',
                lineHeight: '114%',
              }}
            >
              <span
                style={{
                  lineHeight: '114%',
                  color: 'rgb(0,0,0)',
                  fontSize: '16px',
                }}
              >
                Your data is kept on our systems and used only to carry out the
                tasks necessary to deliver our services to you or as otherwise
                described in this Privacy Policy. We do not keep your data any
                longer than is necessary to achieve the particular goals. When
                you delete your account or after a year of inactivity, we
                usually delete your data unless we need to keep it to adhere to
                legal obligations, prove that we complied with legal
                obligations, settle a necessary claim or dispute, or if it is
                necessary for our legitimate business objectives, like
                preventing fraud and enhancing user safety and security.
              </span>
            </p>
            <p
              style={{
                marginTop: '5.0000pt',
                marginBottom: '5.0000pt',
                textIndent: '18.0000pt',
                textAlign: 'justify',
                lineHeight: '114%',
              }}
            >
              <span
                style={{
                  lineHeight: '114%',
                  fontSize: '16px',
                }}
              >
                Remember that even though our systems are made to delete data in
                accordance with the aforementioned rules, we are unable to
                guarantee that all of the data will be removed within a certain
                amount of time owing to technical limitations.
              </span>
            </p>
            <h1 style={{ marginTop: '7.0000pt', marginBottom: '7.0000pt' }}>
              <strong>
                <span
                  style={{
                    color: 'rgb(0,0,0)',
                    fontSize: '16px',
                  }}
                >
                  CHILDREN’S PRIVACY&nbsp;
                </span>
              </strong>
            </h1>
            <p
              style={{
                marginTop: '7.0000pt',
                marginBottom: '7.0000pt',
                textIndent: '18.0000pt',
                textAlign: 'justify',
                lineHeight: '114%',
              }}
            >
              <span
                style={{
                  lineHeight: '114%',
                  fontSize: '16px',
                }}
              >
                Our parent services are designed for users who are 18 (eighteen)
                years of age or older. We do not knowingly collect personal
                information from anyone under the age of 18 (eighteen), except
                for the information that the parent is providing during the kid
                (s) addition process in our app. Children have a separate
                account whose login information is kept on our authentication
                services.&nbsp;
              </span>
            </p>
            <h1 style={{ marginTop: '7.0000pt', marginBottom: '7.0000pt' }}>
              <strong>
                <span
                  style={{
                    color: 'rgb(0,0,0)',
                    fontSize: '16px',
                  }}
                >
                  CHANGES TO PRIVACY POLICY
                </span>
              </strong>
            </h1>
            <p
              style={{
                marginTop: '7.0000pt',
                marginBottom: '7.0000pt',
                textIndent: '18.0000pt',
                textAlign: 'justify',
                lineHeight: '114%',
              }}
            >
              <span
                style={{
                  lineHeight: '114%',
                  fontSize: '16px',
                }}
              >
                To help you more effectively educate your kid(s), we reserve the
                right to make changes to the current Privacy Policy whenever we
                see it necessary. We will notify you in advance of any
                significant changes so you have a chance to review them.&nbsp;
              </span>
            </p>
            <h1 style={{ marginTop: '7.0000pt', marginBottom: '7.0000pt' }}>
              <strong>
                <span
                  style={{
                    color: 'rgb(0,0,0)',
                    fontSize: '16px',
                  }}
                >
                  CONTACT
                </span>
              </strong>
            </h1>
            <p
              style={{
                marginTop: '5.0000pt',
                marginBottom: '5.0000pt',
                textIndent: '18.0000pt',
                textAlign: 'justify',
                lineHeight: '114%',
              }}
            >
              <span
                style={{
                  lineHeight: '114%',
                  fontSize: '16px',
                }}
              >
                In case you have any recommendations or questions about this
                Privacy Policy or any other privacy matters, you can direct them
                to us&nbsp;
              </span>
              <span
                style={{
                  lineHeight: '114%',
                  fontSize: '16px',
                }}
              >
                via&nbsp;
              </span>
              <a href="mailto:info@kidfi.co">
                <strong>
                  <u>
                    <span
                      style={{
                        lineHeight: '114%',
                        color: 'rgb(5,99,193)',
                        textDecoration: 'underline',
                        fontSize: '16px',
                      }}
                    >
                      info@kidfi.co
                    </span>
                  </u>
                </strong>
              </a>
              <strong>
                <span
                  style={{
                    lineHeight: '114%',
                    color: 'rgb(0,0,0)',
                    fontSize: '16px',
                  }}
                >
                  .
                </span>
              </strong>
            </p>
            <p
              style={{
                marginTop: '5.0000pt',
                marginBottom: '5.0000pt',
                textIndent: '18.0000pt',
                textAlign: 'justify',
                lineHeight: '114%',
              }}
            >
              <span
                style={{
                  lineHeight: '114%',
                  fontSize: '16px',
                }}
              >
                &nbsp;
              </span>
            </p>
            <p
              style={{
                marginTop: '5.0000pt',
                marginBottom: '5.0000pt',
                lineHeight: '114%',
              }}
            >
              <strong>
                <span
                  style={{
                    lineHeight: '114%',
                    color: 'rgb(0,0,0)',
                    fontSize: '16px',
                  }}
                >
                  “KIDFI” LLC
                </span>
              </strong>
            </p>
            <p
              style={{
                marginTop: '5.0000pt',
                marginBottom: '5.0000pt',
                textAlign: 'justify',
                lineHeight: '114%',
              }}
            >
              <strong>
                <span
                  style={{
                    lineHeight: '114%',
                    color: 'rgb(0,0,0)',
                    fontSize: '16px',
                  }}
                >
                  1405934651, Baku city, Nasimi district, A.Huseynov street,
                </span>
              </strong>
              <strong>
                <span
                  style={{
                    lineHeight: '114%',
                    color: 'rgb(0,0,0)',
                    fontWeight: 'bold',
                    fontSize: '16px',
                  }}
                >
                  &nbsp;5/7, 454
                </span>
              </strong>
            </p>
            <p
              style={{
                marginTop: '5.0000pt',
                marginBottom: '5.0000pt',
                textAlign: 'justify',
                lineHeight: '114%',
              }}
            >
              <strong>
                <span
                  style={{
                    lineHeight: '114%',
                    color: 'rgb(0,0,0)',
                    fontSize: '16px',
                  }}
                >
                  Last updated: August 2022&nbsp;
                </span>
              </strong>
            </p>
          </>
        ) : lang === 'ru' ? (
          <>
            <p style={{ textAlign: 'center' }}>
              <strong>
                <span style={{ fontSize: '16px' }}>
                  ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ
                </span>
              </strong>
            </p>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify' }}>
              <strong>
                <span style={{ fontSize: '16px' }}>Владелец ООО «</span>
              </strong>
              <strong>
                <span style={{ fontSize: '16px' }}>KidFi</span>
              </strong>
              <strong>
                <span style={{ fontSize: '16px' }}>»</span>
              </strong>
            </p>
            <p style={{ textIndent: '18.0000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                В настоящей Политике конфиденциальности разъясняются процедуры и
                политика&nbsp;
              </span>
              <strong>
                <span style={{ fontSize: '16px' }}>ООО «KIDFI»</span>
              </strong>
              <span style={{ fontSize: '16px' }}>
                &nbsp;(далее «мы», «наш» или «нас»), юридического лица,
                зарегистрированного в соответствии с законодательством
                Азербайджанской Республики (1405934651, с офисом в городе Баку,
                Насиминский район, улица А.Гусейнова, 5/7, 454) и его партнеров
                о том, как информация о пользователе (далее «вы») собирается,
                используется и раскрывается нами и нашими партнерами при
                использовании вами нашего мобильного приложения «KidFi» (далее -
                «сервисы»).
              </span>
            </p>
            <p style={{ textIndent: '18.0000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                Обратите внимание, что вы соглашаетесь с настоящей Политикой
                конфиденциальности, установив соответствующий флажок в процессе
                регистрации.
              </span>
            </p>
            <p style={{ textIndent: '18.0000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                Поскольку мы уделяем большое внимание защите вашей безопасности
                и конфиденциальности, мы соблюдаем все применимые международные
                соглашения и законодательство Азербайджанской Республики,
                регулирующие защиту конфиденциальности данных, включая Закон
                Азербайджанской Республики о персональных данных № 998-IIIQ от
                11 мая 2010 г.
              </span>
            </p>
            <p style={{ textIndent: '18.0000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                Обратите внимание, что Правила пользования считаются
                неотъемлемой частью настоящей Политики конфиденциальности.
                Вместе эти документы определяют отношения между вами и нами.
              </span>
            </p>
            <h1>
              <strong>
                <span
                  style={{
                    color: 'rgb(0,0,0)',
                    fontSize: '16px',
                  }}
                >
                  ОСНОВНЫЕ ПОНЯТИЯ:
                </span>
              </strong>
            </h1>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>-&nbsp;</span>
              <em>
                <span style={{ fontSize: '16px' }}>Персональные данные</span>
              </em>
              <span style={{ fontSize: '16px' }}>
                &nbsp;– любая информация, позволяющая прямо или косвенно
                идентифицировать вас;
              </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>-&nbsp;</span>
              <em>
                <span style={{ fontSize: '16px' }}>
                  Субъект персональных данных
                </span>
              </em>
              <span style={{ fontSize: '16px' }}>
                &nbsp;– пользователь, персональная информация о котором
                собирается, обрабатывается и раскрывается;
              </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>-&nbsp;</span>
              <em>
                <span style={{ fontSize: '16px' }}>Ребенок</span>
              </em>
              <span style={{ fontSize: '16px' }}>
                &nbsp;– любой человек в возрасте до 18 лет;
              </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>-&nbsp;</span>
              <em>
                <span style={{ fontSize: '16px' }}>
                  Идентификационный номер
                </span>
              </em>
              <span style={{ fontSize: '16px' }}>
                &nbsp;– единый уникальный код, позволяющий идентифицировать
                релевантную информацию о человеке;
              </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>-&nbsp;</span>
              <em>
                <span style={{ fontSize: '16px' }}>
                  Сбор персональных данных
                </span>
              </em>
              <span style={{ fontSize: '16px' }}>
                &nbsp;– получение персональных данных о субъекте в соответствии
                с законодательством;
              </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>-&nbsp;</span>
              <em>
                <span style={{ fontSize: '16px' }}>
                  Обработка персональных данных
                </span>
              </em>
              <span style={{ fontSize: '16px' }}>
                &nbsp;– операции с персональными данными, такие как запись,
                систематизация, обновление, изменение, извлечение,
                обезличивание, хранение, передача, уничтожение;
              </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>-&nbsp;</span>
              <em>
                <span style={{ fontSize: '16px' }}>
                  Раскрытие персональных данных
                </span>
              </em>
              <span style={{ fontSize: '16px' }}>
                &nbsp;– предоставление данных поставщикам услуг или нашим
                партнерам – третьим лицам в соответствии с действующим
                законодательством.
              </span>
            </p>
            <h1>
              <strong>
                <span
                  style={{
                    color: 'rgb(0,0,0)',
                    fontSize: '16px',
                  }}
                >
                  КТО МЫ ТАКИЕ?
                </span>
              </strong>
            </h1>
            <p style={{ textIndent: '18.0000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                Юридическим лицом, которое несет ответственность за вашу
                информацию в соответствии с настоящей Политикой
                конфиденциальности (контроллер данных), является&nbsp;
              </span>
              <strong>
                <span style={{ fontSize: '16px' }}>ООО «KIDFI»</span>
              </strong>
              <span style={{ fontSize: '16px' }}>
                , юридическое лицо, зарегистрированное в соответствии с
                законодательством Азербайджанской Республики (1405934651, с
                офисом в городе Баку, Насиминский район, ул. А.Гусейнова, 5/7,
                454).
              </span>
            </p>
            <p style={{ textIndent: '18.0000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                Мы предпримем все необходимые организационные и технические меры
                для защиты ваших данных от несанкционированного доступа,
                случайной потери, несанкционированного изменения и других
                неправомерных действий третьих лиц. В обстоятельствах,
                изложенных в настоящей Политике конфиденциальности, помимо нас,
                некоторые третьи лица могут иметь доступ к вашим данным.
              </span>
            </p>
            <h1>
              <strong>
                <span
                  style={{
                    color: 'rgb(0,0,0)',
                    fontSize: '16px',
                  }}
                >
                  СОБИРАЕМАЯ НАМИ ИНФОРМАЦИЯ
                </span>
              </strong>
            </h1>
            <p style={{ textIndent: '18.0000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                Следует отметить, что для поддержки наших услуг нам необходимо
                собрать определенную информацию о вас и вашем ребенке (детях).
                Это включает:
              </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>-&nbsp;</span>
              <em>
                <span style={{ fontSize: '16px' }}>
                  Информация, предоставленная вами
                </span>
              </em>
              <span style={{ fontSize: '16px' }}>
                &nbsp;– При регистрации и создании учетной записи вы
                предоставляете нам данные для входа, такие как ваше полное имя,
                номер телефона, дата рождения, пол и промо-код (если есть). Вы
                можете добавить в свой профиль такую информацию, как фото
                профиля, ваше местоположение и ваш электронный адрес. Для
                осуществления финансовых операций вы должны предоставить нам
                информацию о своей банковской карте, а при создании цифровой
                карты вы должны предоставить информацию, такую как ваш
                Идентификационный Номер. Добавляя своих детей, вы сообщаете нам
                такую информацию, как полное имя ребенка, дату рождения, пол,
                сумму вознаграждения и промо-код (если есть), а также, при
                желании, фотографию профиля. Пока ваш ребенок проходит тесты,
                он/она предоставляет нам информацию, такую как его/ее ответы на
                вопросы. В случае, если вы обратитесь в нашу службу поддержки
                клиентов, информация, которой вы поделитесь с нами во время
                взаимодействия, будет собираться нами;
              </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>-&nbsp;</span>
              <em>
                <span style={{ fontSize: '16px' }}>
                  Информация об использовании
                </span>
              </em>
              <span style={{ fontSize: '16px' }}>
                &nbsp;– Мы собираем информацию о вашей активности в наших
                службах, функциях, которые вы использовали, о том, как вы их
                используете, например о дате и времени входа в систему, кликах и
                показанных вам страницах;
              </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>-&nbsp;</span>
              <em>
                <span style={{ fontSize: '16px' }}>Данные устройства</span>
              </em>
              <span style={{ fontSize: '16px' }}>
                &nbsp;– мы также можем собирать информацию об оборудовании и
                программном обеспечении, такую как идентификатор и тип
                устройства, IP-адрес, часовые пояса, операционная система и
                идентификаторы, связанные с файлами cookie или другими
                технологиями, которые могут однозначно идентифицировать ваши
                устройства, используемые вами для доступа к нашим
                сервисам;&nbsp;
              </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>-&nbsp;</span>
              <em>
                <span style={{ fontSize: '16px' }}>
                  Дополнительная информация, получаемая с вашего согласия
                </span>
              </em>
              <span style={{ fontSize: '16px' }}>
                &nbsp;– В зависимости от сервиса и устройства, которые вы
                используете, мы можем получить вашу точную геолокацию с вашего
                согласия через соединения GPS, Bluetooth или Wi-Fi.
              </span>
            </p>
            <h1>
              <strong>
                <span
                  style={{
                    color: 'rgb(0,0,0)',
                    fontSize: '16px',
                  }}
                >
                  ФАЙЛЫ COOKIE И ДРУГИЕ ПОДОБНЫЕ ТЕХНОЛОГИИ
                </span>
              </strong>
            </h1>
            <p style={{ textIndent: '18.0000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                Мы используем файлы cookie и аналогичные технологии для сбора и
                использования информации о вас и / или вашем устройстве
                (устройствах), чтобы сохранить ваши предпочтения, сделать ваш
                визит более приятным, запомнить информацию об учетной записи
                пользователя, уменьшить необходимость многократных входов в
                систему, чтобы наши сервисы функционировали и знали, как и кем
                используется услуга. Хотя некоторые функции нашего сервиса могут
                работать неправильно, вы имеете право отключить файлы cookie в
                настройках вашего устройства.
              </span>
            </p>
            <h1>
              <strong>
                <span
                  style={{
                    color: 'rgb(0,0,0)',
                    fontSize: '16px',
                  }}
                >
                  ДЛЯ ЧЕГО МЫ ИСПОЛЬЗУЕМ ВАШУ ИНФОРМАЦИЮ?
                </span>
              </strong>
            </h1>
            <p style={{ textIndent: '18.0000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                Ниже приведены основные причины, по которым мы собираем,
                используем и раскрываем вашу информацию:
              </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                - Развивать, улучшать и предоставлять наши услуги, а также
                предоставлять вам новые услуги «Kidfi»;
              </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                - Создавать и администрировать вашу учетную запись, чтобы
                облегчить ваше общение с вашим ребенком (детьми);
              </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                - Реализовать ваши транзакции;
              </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                - Проанализировать аш профиль, активность и предпочтения в
                приложении, чтобы понять, какие функции нам следует улучшить;
              </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                - Предоставить вам службу поддержки клиентов и ответить на ваши
                вопросы;
              </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                - Общаться с вами о продуктах или услугах, которые, по нашему
                мнению, могут быть вам интересны;
              </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                - Предотвратить мошенничество или другие незаконные или
                несанкционированные действия;
              </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                - Соблюдать требования законодательства и оказывать содействие
                правоохранительным органам.
              </span>
            </p>
            <h1>
              <strong>
                <span
                  style={{
                    color: 'rgb(0,0,0)',
                    fontSize: '16px',
                  }}
                >
                  КАК МЫ РАСПОРЯЖАЕМСЯ ВАШЕЙ ИНФОРМАЦИЕЙ?
                </span>
              </strong>
            </h1>
            <p style={{ textIndent: '18.0000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                Ваши данные будут переданы третьим лицам только в соответствии с
                руководящими принципами, изложенными в настоящей Политике
                конфиденциальности.
              </span>
            </p>
            <p style={{ textIndent: '18.0000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                Мы делимся некоторой информацией с партнерами и поставщиками
                услуг, которые помогают нам запускать и улучшать наши услуги.
                Эти третьи стороны поддерживают нас несколькими способами,
                включая обслуживание клиентов, аналитику, обработку платежей и
                операции по обеспечению безопасности. Все наши партнеры и
                поставщики услуг будут соблюдать строгие требования
                конфиденциальности.
              </span>
            </p>
            <p style={{ textIndent: '18.0000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                Если это законно необходимо, мы также можем передавать
                информацию другим компаниям по законным основаниям, таким как
                аудит компании, в соответствии с применимыми законами и
                процедурами.
              </span>
            </p>
            <p style={{ textIndent: '18.0000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                Мы можем раскрывать ваши данные нашему персоналу, любому члену
                нашей группы, поставщикам или субподрядчикам, если это разумно
                необходимо для целей настоящей Политики конфиденциальности, при
                условии, что они не используют ваши данные самостоятельно и
                согласились их защищать.
              </span>
            </p>
            <p style={{ textIndent: '18.0000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                В случае слияния, приобретения, разделения, преобразования или
                продажи нами всех или части наших активов любая третья сторона,
                которой мы продаем наши активы, будет иметь право продолжать
                использовать ваши данные в соответствии с правилами настоящей
                Политики конфиденциальности.
              </span>
            </p>
            <h1>
              <strong>
                <span
                  style={{
                    color: 'rgb(0,0,0)',
                    fontSize: '16px',
                  }}
                >
                  ВАШИ ПРАВА
                </span>
              </strong>
            </h1>
            <p style={{ textIndent: '18.0000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                Касается того, как мы собираем, используем и обрабатываем ваши
                данные, у вас есть следующие права:
              </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>1.&nbsp;</span>
              <em>
                <span style={{ fontSize: '16px' }}>Получить</span>
              </em>
              <span style={{ fontSize: '16px' }}>&nbsp;д</span>
              <em>
                <span style={{ fontSize: '16px' }}>
                  оступ и копии своих данных
                </span>
              </em>
              <span style={{ fontSize: '16px' }}>
                &nbsp;– Вы можете в любое время запросить копию имеющейся у нас
                информации о вас или ваших детях и ознакомиться с ее
                содержанием;
              </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>2.&nbsp;</span>
              <em>
                <span style={{ fontSize: '16px' }}>
                  Исправить свои данные и возражать против обработки нами данных
                  о вас или ваших детях
                </span>
              </em>
              <span style={{ fontSize: '16px' }}>
                &nbsp;– Если вы считаете, что информация, которую мы храним о
                вас или ваших детях, является неточной или неполной, или что мы
                больше не имеем права использовать ее по причинам, по которым
                она была получена, и хотите запросить его исправление, удаление
                или выразить несогласие с его способами обработки, вы можете
                связаться с нами;
              </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>3.&nbsp;</span>
              <em>
                <span style={{ fontSize: '16px' }}>
                  Удалить свои данные и удалить приложение
                </span>
              </em>
              <span style={{ fontSize: '16px' }}>
                &nbsp;– Вы можете удалить свою учетную запись в любое время
                непосредственно в приложении и прекратить сбор всей информации,
                удалив приложение, используя процедуру удаления по умолчанию для
                вашего устройства;
              </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>4.&nbsp;</span>
              <em>
                <span style={{ fontSize: '16px' }}>
                  Подать жалобу в соответствующий орган
                </span>
              </em>
              <span style={{ fontSize: '16px' }}>
                &nbsp;– Пожалуйста, сообщите нам, если у вас есть какие-либо
                сомнения по поводу того, как мы собираем и обрабатываем ваши
                данные, связавшись с нами через&nbsp;
              </span>
              <a href="mailto:info@kidfi.co">
                <strong>
                  <u>
                    <span
                      style={{
                        color: 'rgb(5,99,193)',
                        textDecoration: 'underline',
                        fontSize: '16px',
                      }}
                    >
                      info@kidfi.co
                    </span>
                  </u>
                </strong>
              </a>
              <span style={{ fontSize: '16px' }}>
                . После обращения к нам, если вы считаете, что ваши права не
                были реализованы, вы можете подать жалобу в соответствующий
                орган исполнительной власти или суд и потребовать компенсации за
                причиненный вам моральный и материальный вред.
              </span>
            </p>
            <h1>
              <strong>
                <span
                  style={{
                    color: 'rgb(0,0,0)',
                    fontSize: '16px',
                  }}
                >
                  КАК МЫ ЗАЩИЩАЕМ ВАШУ ИНФОРМАЦИЮ?
                </span>
              </strong>
            </h1>
            <p style={{ textIndent: '22.5000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                Мы предпринимаем меры для защиты вашей информации и информации
                вашего ребенка (детей) от несанкционированного доступа,
                раскрытия, изменения или удаления. Однако мы не можем
                гарантировать, что он всегда будет в безопасности. Чтобы
                поддерживать наши физические, технические и организационные меры
                безопасности в актуальном состоянии, мы регулярно проверяем наши
                системы на наличие недостатков и угроз, а также регулярно
                пересматриваем наши методы и политику сбора, хранения и
                обработки данных. Если мы заподозрим или выявим какое-либо
                нарушение безопасности, мы имеем право немедленно прекратить
                использование вами всех или части наших услуг.&nbsp;
              </span>
            </p>
            <p style={{ textIndent: '22.5000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                Пожалуйста, сообщите нам об этом через&nbsp;
              </span>
              <a href="mailto:info@kidfi.co">
                <strong>
                  <u>
                    <span
                      style={{
                        color: 'rgb(5,99,193)',
                        textDecoration: 'underline',
                        fontSize: '16px',
                      }}
                    >
                      info@kidfi.co
                    </span>
                  </u>
                </strong>
              </a>
              <span style={{ fontSize: '16px' }}>
                , если вы считаете, что безопасность вашей учетной записи или
                вашей информации была нарушена.
              </span>
            </p>
            <h1>
              <strong>
                <span
                  style={{
                    color: 'rgb(0,0,0)',
                    fontSize: '16px',
                  }}
                >
                  ХРАНЕНИЕ ДАННЫХ
                </span>
              </strong>
            </h1>
            <p style={{ textIndent: '18.0000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                Ваши данные хранятся в наших системах и используются только для
                выполнения задач, необходимых для предоставления вам наших
                услуг, или в иных описанных в настоящей Политике
                конфиденциальности целях. Мы не храним ваши данные дольше, чем
                это необходимо для достижения конкретных целей. Когда вы
                удаляете свою учетную запись или после года бездействия, мы
                обычно удаляем ваши данные, если только нам не нужно сохранить
                их для соблюдения юридических обязательств, доказать, что мы
                выполнили юридические обязательства, урегулировать необходимую
                претензию или спор, или если это необходимо для наших законных
                бизнес-целей, таких как предотвращение мошенничества и повышение
                безопасность и защита пользователей.
              </span>
            </p>
            <p style={{ textIndent: '18.0000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                Помните, что, несмотря на то, что наши системы предназначены для
                удаления данных в соответствии с вышеупомянутыми правилами, мы
                не можем гарантировать, что все данные будут удалены в течение
                определенного периода времени из-за технических ограничений.
              </span>
            </p>
            <h1>
              <strong>
                <span
                  style={{
                    color: 'rgb(0,0,0)',
                    fontSize: '16px',
                  }}
                >
                  КОНФИДЕНЦИАЛЬНОСТЬ ДЕТЕЙ&nbsp;
                </span>
              </strong>
            </h1>
            <p style={{ textIndent: '18.0000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                Наши родительские сервисы предназначены для пользователей в
                возрасте 18 (восемнадцати) лет и старше. Мы сознательно не
                собираем личную информацию от лиц младше 18 (восемнадцати) лет,
                за исключением информации, которую родители предоставляют в
                процессе добавления ребенка (детей) в наше приложение. У детей
                есть отдельная учетная запись, регистрационные данные которой
                хранятся в наших службах аутентификации.
              </span>
            </p>
            <h1>
              <strong>
                <span
                  style={{
                    color: 'rgb(0,0,0)',
                    fontSize: '16px',
                  }}
                >
                  ИЗМЕНЕНИЯ В ПОЛИТИКЕ КОНФИДЕНЦИАЛЬНОСТИ
                </span>
              </strong>
            </h1>
            <p style={{ textIndent: '18.0000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                Чтобы помочь вам более эффективно обучать своих детей, мы
                оставляем за собой право вносить изменения в действующую
                Политику конфиденциальности, когда сочтем это необходимым. Мы
                заранее уведомим вас о любых существенных изменениях, чтобы у
                вас была возможность ознакомиться с ними.
              </span>
            </p>
            <h1>
              <strong>
                <span
                  style={{
                    color: 'rgb(0,0,0)',
                    fontSize: '16px',
                  }}
                >
                  ДЛЯ СВЯЗИ С НАМИ
                </span>
              </strong>
            </h1>
            <p style={{ textIndent: '18.0000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                В случае, если у вас есть какие-либо рекомендации или вопросы по
                поводу этой Политики конфиденциальности или любых других
                вопросов конфиденциальности, вы можете направить их нам по
                адресу&nbsp;
              </span>
              <a href="mailto:info@kidfi.co">
                <strong>
                  <u>
                    <span
                      style={{
                        color: 'rgb(5,99,193)',
                        textDecoration: 'underline',
                        fontSize: '16px',
                      }}
                    >
                      info@kidfi.co
                    </span>
                  </u>
                </strong>
              </a>
              <span style={{ fontSize: '16px' }}>.</span>
            </p>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify' }}>
              <strong>
                <span style={{ fontSize: '16px' }}>ООО “</span>
              </strong>
              <strong>
                <span style={{ fontSize: '16px' }}>KIDFI</span>
              </strong>
              <strong>
                <span style={{ fontSize: '16px' }}>”</span>
              </strong>
            </p>
            <p style={{ textAlign: 'justify' }}>
              <strong>
                <span style={{ fontSize: '16px' }}>
                  1405934651, город Баку, Насиминский район, улица А.Гусейнова,
                  5/7, 454
                </span>
              </strong>
            </p>
            <p style={{ textAlign: 'justify' }}>
              <strong>
                <span style={{ fontSize: '16px' }}>
                  Последнее обновление: август 2022
                </span>
              </strong>
            </p>
          </>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};
