import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import './MenuLink.css';

interface MenuLinkProps {
  setDefault: () => void;
  title: string;
  active: any;
  id: string;
  setMenuOpen?: any;
  isIndividual?: boolean;
}

export const MenuLink: React.FC<MenuLinkProps> = ({
  setDefault,
  title,
  active,
  id,
  setMenuOpen,
  isIndividual,
}) => {
  const [isActive, setActive] = useState(active[id]);
  useEffect(() => {
    setActive(active[id]);
  }, [active]);

  const navigate = useNavigate();

  return (
    <>
      <p
        className={`header-menu__link ${isActive ? 'active' : ''}`}
        onClick={() => {
          navigate(isIndividual ? '/api' : '/', { state: { targetId: id } });
          setDefault();
          setTimeout(() => setActive(true));
          setMenuOpen && setMenuOpen(false);
        }}
      >
        {title}
      </p>
    </>
  );
};
