import { getLocaleData } from 'helpers/getLocaleData';
import { az, en, ru } from './lang';

export const getData = (lang: string) => {
  const baseLang = getLocaleData(lang, az, en, ru);
  return {
    title: baseLang.title,
    description: baseLang.description,
    buttonTitle: baseLang.buttonTitle,
    modalTitle: baseLang.modalTitle,
    modalDescription: baseLang.modalDescription,
    rewardMoney: baseLang.rewardMoney,
    goToTest: baseLang.goToTest,
    birthday: baseLang.birthday,
    email: baseLang.email,
    female: baseLang.female,
    male: baseLang.male,
    next: baseLang.next,
    unknown: baseLang.unknown,
    quizTitle: baseLang.quizTitle,
    thankYou: baseLang.thankYou,
    submissionSent: baseLang.submissionSent,
  };
};

export const quizData = {
  az: [
    {
      question: 'Pul nədir?',
      type: 'single',
      answers: [
        {
          title: 'Zinət əşyası',
          type: false,
        },
        {
          title: 'Mübadilə vasitəsi',
          type: true,
        },
        {
          title: 'Xərc',
          type: false,
        },
        {
          title: 'Kağız',
          type: false,
        },
      ],
    },
    {
      question: 'İnsanlar pulu necə qazanırlar? Bir neçə variant seç.',
      type: 'multiple',
      answers: [
        {
          title: 'Məhsul sataraq',
          type: true,
        },
        {
          title: 'Banka gedərək',
          type: false,
        },
        {
          title: 'Xidmət göstərərək',
          type: true,
        },
        {
          title: 'İşləyərək',
          type: true,
        },
        {
          title: 'Oyun oynayaraq',
          type: false,
        },
      ],
    },
    {
      question: 'Peşəkar olmaq nə üçün vacibdir?',
      type: 'single',
      answers: [
        {
          title:
            'Gələcəkdə daha perspektivli işlərdə çalışmaq və gəlir əldə etmək üçün',
          type: true,
        },
        {
          title: 'Daha savadlı olmaq və seçilmək üçün',
          type: false,
        },
        {
          title: 'Bir sıra ölkələrə səfər edə bilmək üçün',
          type: false,
        },
        {
          title: 'Fərqli sahələrdə çalışa bilmək üçün',
          type: false,
        },
      ],
    },
    {
      question: 'Aşağıdakılardan hansı pulun funksiyasına aid deyil?',
      type: 'single',
      answers: [
        {
          title: 'Tədavül',
          type: false,
        },
        {
          title: 'Dəyər',
          type: false,
        },
        {
          title: 'Yığım',
          type: false,
        },
        {
          title: 'Gəlir',
          type: true,
        },
      ],
    },
    {
      question: 'Tədavül funksiyası nə deməkdir?',
      type: 'single',
      answers: [
        {
          title: 'Pulun yığım məqsədilə toplanması',
          type: false,
        },
        {
          title: 'Məhsul və xidmətlər üçün qiymətin təyin edilməsi',
          type: false,
        },
        {
          title: 'Pulun milli valyutaya çevrilməsi',
          type: false,
        },
        {
          title: 'Pulun əmtəə mübadiləsi üçün dövriyyəyə buraxılması',
          type: true,
        },
      ],
    },
    {
      question: 'Dəyər funksiyası nə deməkdir?',
      type: 'single',
      answers: [
        {
          title: 'Pulun yığım məqsədilə toplanması',
          type: false,
        },
        {
          title: 'Məhsul və xidmətlər üçün qiymətin təyin edilməsi',
          type: true,
        },
        {
          title: 'Pulun milli valyutaya çevrilməsi',
          type: false,
        },
        {
          title: 'Pulun əmtəə mübadiləsi üçün dövriyyəyə buraxılması',
          type: false,
        },
      ],
    },
    {
      question: 'Yığım funksiyasına hansı aiddir?',
      type: 'single',
      answers: [
        {
          title: 'Pulun yığım məqsədilə toplanması',
          type: true,
        },
        {
          title: 'Məhsul və xidmətlər üçün qiymətin təyin edilməsi',
          type: false,
        },
        {
          title: 'Pulun milli valyutaya çevrilməsi',
          type: false,
        },
        {
          title: 'Pulun əmtəə mübadiləsi üçün dövriyyəyə buraxılması',
          type: false,
        },
      ],
    },
    {
      question:
        'Kağız və qəpik pullar istifadə edilmədən, olunan ödəniş necə adlanır?',
      type: 'single',
      answers: [
        {
          title: 'Elektron',
          type: false,
        },
        {
          title: 'Online',
          type: false,
        },
        {
          title: 'Nağdsız',
          type: true,
        },
        {
          title: 'Barter',
          type: false,
        },
      ],
    },
  ],
  en: [
    {
      question: 'What is money?',
      type: 'single',
      answers: [
        {
          title: 'Jewelry',
          type: false,
        },
        {
          title: 'Medium of exchange',
          type: true,
        },
        {
          title: 'An expense',
          type: false,
        },
        {
          title: 'A paper',
          type: false,
        },
      ],
    },
    {
      question: 'How do people earn money? Select multiple answers.',
      type: 'multiple',
      answers: [
        {
          title: 'By selling goods',
          type: true,
        },
        {
          title: 'By going to the bank',
          type: false,
        },
        {
          title: 'By providing a service',
          type: true,
        },
        {
          title: 'By working',
          type: true,
        },
        {
          title: 'By playing games',
          type: true,
        },
      ],
    },
    {
      question: 'Why is it important to be professional?',
      type: 'single',
      answers: [
        {
          title:
            'To have more promising work and opportunities and ways to make profit in the future',
          type: true,
        },
        {
          title: 'To become more educated and be distinct',
          type: false,
        },
        {
          title: 'To be able to take trips to different countries',
          type: false,
        },
        {
          title: 'To be able to work in many different job fields',
          type: false,
        },
      ],
    },
    {
      question: 'Which of the following is not a funciton of money?',
      type: 'single',
      answers: [
        {
          title: 'Circulation',
          type: false,
        },
        {
          title: 'Value',
          type: false,
        },
        {
          title: 'Saving',
          type: false,
        },
        {
          title: 'Profit',
          type: true,
        },
      ],
    },
    {
      question: 'What is the circulation of currency function?',
      type: 'single',
      answers: [
        {
          title: 'Gathering money for saving',
          type: false,
        },
        {
          title: 'Pricing for products and services',
          type: false,
        },
        {
          title: 'Conversion of money to national currency',
          type: false,
        },
        {
          title:
            'The release of money into society for the purpose of commodity trading',
          type: true,
        },
      ],
    },
    {
      question: 'What is the value function of money?',
      type: 'single',
      answers: [
        {
          title: 'Gathering money for saving',
          type: false,
        },
        {
          title: 'Pricing for products and services',
          type: true,
        },
        {
          title: 'Conversion of money to national currency',
          type: false,
        },
        {
          title:
            'The release of money into society for the purpose of commodity trading',
          type: false,
        },
      ],
    },
    {
      question: 'To which is the saving function relevant to?',
      type: 'single',
      answers: [
        {
          title: 'Gathering money for saving',
          type: true,
        },
        {
          title: 'Pricing for products and services',
          type: false,
        },
        {
          title: 'Conversion of money to national currency',
          type: false,
        },
        {
          title:
            'The release of money into society for the purpose of commodity trading',
          type: false,
        },
      ],
    },
    {
      question: 'What do we call payments made without paper notes or coins?',
      type: 'single',
      answers: [
        {
          title: 'Electronic',
          type: false,
        },
        {
          title: 'Online',
          type: false,
        },
        {
          title: 'Cashless',
          type: true,
        },
        {
          title: 'Barter',
          type: false,
        },
      ],
    },
  ],
  ru: [
    {
      question: 'Что такое деньги?',
      type: 'single',
      answers: [
        {
          title: 'Драгоценности',
          type: false,
        },
        {
          title: 'Средство обмена',
          type: true,
        },
        {
          title: 'Трата',
          type: false,
        },
        {
          title: 'Бумага',
          type: false,
        },
      ],
    },
    {
      question: 'Как люди зарабатывают деньги?  Выберите несколько вариантов.',
      type: 'multiple',
      answers: [
        {
          title: 'Продавая продукты',
          type: true,
        },
        {
          title: 'Посещая банк',
          type: false,
        },
        {
          title: 'Предоставляя услуги',
          type: true,
        },
        {
          title: 'Работая',
          type: true,
        },
        {
          title: 'Играя в игры',
          type: false,
        },
      ],
    },
    {
      question: 'Почему важно быть профессионалом?',
      type: 'single',
      answers: [
        {
          title:
            'Для того, чтобы работать на более перспективных работах и ​​получать доход в будущем',
          type: true,
        },
        {
          title: 'Для того, чтобы стать грамотнее',
          type: false,
        },
        {
          title: 'Для того, чтобы  путешествовать',
          type: false,
        },
        {
          title: 'Для того, чтобы работать в различных сферах',
          type: false,
        },
      ],
    },
    {
      question: 'Что из перечисленного не является функцией денег?',
      type: 'single',
      answers: [
        {
          title: 'Оборот',
          type: false,
        },
        {
          title: 'Ценность',
          type: false,
        },
        {
          title: 'Сбор',
          type: false,
        },
        {
          title: 'Доход',
          type: true,
        },
      ],
    },
    {
      question: 'Что значит циркуляция денег?',
      type: 'single',
      answers: [
        {
          title: 'Сбор средств для сбережений',
          type: false,
        },
        {
          title: 'Определение цен продуктов и сервисов.',
          type: false,
        },
        {
          title: 'Конвертация денег в национальную валюту',
          type: false,
        },
        {
          title: 'Ввод денег в обращение для обмена товаров',
          type: true,
        },
      ],
    },
    {
      question: 'Что такое ценность денег?',
      type: 'single',
      answers: [
        {
          title: 'Сбор средств для сбережений',
          type: false,
        },
        {
          title: 'Определение цен продуктов и сервисов.',
          type: true,
        },
        {
          title: 'Конвертация денег в национальную валюту',
          type: false,
        },
        {
          title: 'Ввод денег в обращение для обмена товаров',
          type: false,
        },
      ],
    },
    {
      question: 'Что из следующего относится к функции накопления?',
      type: 'single',
      answers: [
        {
          title: 'Сбор средств для сбережений',
          type: true,
        },
        {
          title: 'Определение цен продуктов и сервисов.',
          type: false,
        },
        {
          title: 'Конвертация денег в национальную валюту',
          type: false,
        },
        {
          title: 'Ввод денег в обращение для обмена товаров',
          type: false,
        },
      ],
    },
    {
      question:
        'Как называется платеж, производимый без использования бумажных денег и копеек?',
      type: 'single',
      answers: [
        {
          title: 'Электронный',
          type: false,
        },
        {
          title: 'Онлайн',
          type: false,
        },
        {
          title: 'Безналичный расчет',
          type: true,
        },
        {
          title: 'Бартер',
          type: false,
        },
      ],
    },
  ],
};
