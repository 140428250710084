import React from 'react';
import './Partners.css';
import CentralBank from 'assets/images/central_bank.png';
import Landau from 'assets/images/landau.png';
import SabahLab from 'assets/images/sabah_lab_2.png';
import JA from 'assets/images/JA.png';
import QalaHayat from 'assets/images/qalahayat.png';
import Qala from 'assets/images/qala.png';
import ATB from 'assets/images/atb.png';
import { az, en, ru } from './lang';
import { getLocaleData } from 'helpers/getLocaleData';

interface IProps {
  id: string;
  lang: string;
}
export const Partners: React.FC<IProps> = ({ lang, id }) => {
  const baseLang = getLocaleData(lang, az, en, ru);

  return (
    <>
      <div className="partners" id={id}>
        <div className="container">
          <h6 className="title-primary text-center">{baseLang.title}</h6>
          <div className="d-flex flex-wrap justify-content-around align-items-center mb-6">
            <img src={CentralBank} alt="" />
            <img src={Landau} alt="" />
            <img src={SabahLab} alt="" />
          </div>
          <div className="d-flex flex-wrap justify-content-around align-items-center">
            <img src={ATB} alt="ATB" style={{ maxHeight: '100px' }} />
            <img
              src={QalaHayat}
              alt="Qala Hayat"
              style={{ maxHeight: '80px' }}
            />
            <img src={Qala} alt="Qala Sığorta" style={{ maxHeight: '80px' }} />
          </div>
        </div>
      </div>
    </>
  );
};
