export const az = {
  title: 'Biznes imkanlarınızı genişləndirin. %KidFi API% ilə!',
  description:
    'Müştərilərə Əlavə Dəyər Xidmət təqdim etməklə, əlavə satış kanalları yaradın.',
  buttonTitle: 'Sənədləşmə',
};
export const en = {
  title: 'Empower your business. With %KidFi API!%',
  description:
    'Create additional sales channels by providing Value Added Service to customers.',
  buttonTitle: 'Documentation',
};
export const ru = {
  title: 'Расширьте возможности своего бизнеса. C %KidFi API!%',
  description:
    'Создайте дополнительные каналы продаж, предоставляя клиентам дополнительные услуги.',
  buttonTitle: 'Документация',
};
