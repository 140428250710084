import React from 'react';
import AboutImageFirst from 'assets/images/about_first.png';
import AboutImageSecond from 'assets/images/about_second.png';
import './About.css';
import { getData } from './data';

interface IProps {
  id: string;
  lang: string;
}
export const About: React.FC<IProps> = ({ id, lang }) => {
  const data = getData(lang);
  return (
    <>
      <div className="about" id={id}>
        <div className="container">
          <div className="d-flex justify-content-between about-row">
            <div className="about-left">
              <img src={AboutImageFirst} alt="" />
            </div>
            <div className="about-right about-text">
              <h2 className="title-primary">{data.firstTitle}</h2>
              <p className="text-primary">{data.firstDescription}</p>
            </div>
          </div>
          <div className="d-flex justify-content-between about-row">
            <div className="about-left about-text">
              <h3 className="title-primary">{data.secondTitle}</h3>
              <p className="text-primary">{data.secondDescription}</p>
            </div>
            <div className="about-right">
              <img src={AboutImageSecond} alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
