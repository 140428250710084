export const az = {
  title: '%KidFi API% nədir?',
  description:
    'KidFi APİ ilə siz maliyyə savadlılığı ilə bağlı 3 dildə video və hər videoyaya aid test suallar əldə edəcəksiz.',
};
export const en = {
  title: 'What is %KidFi API?%',
  description:
    "With the KidFi API, you'll get financial literacy videos in 3 languages and quiz questions for each video.",
};
export const ru = {
  title: 'Что такое %KidFi API?%',
  description:
    'С KidFi API вы получите видеоролики по финансовой грамотности на 3 языках и вопросы для каждого видео.',
};
