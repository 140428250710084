import React from 'react';
import './Terms.css';

interface IProps {
  lang: string;
}

export const Terms: React.FC<IProps> = ({ lang }) => {
  return (
    <div className="terms">
      <div className="container">
        {lang === 'az' ? (
          <>
            <p
              style={{
                marginTop: '10.0000pt',
                marginBottom: '10.0000pt',
                textAlign: 'center',
              }}
            >
              <strong>
                <span style={{ fontSize: '19px' }}>İSTİFADƏÇİ QAYDALARI</span>
              </strong>
            </p>
            <p style={{ marginTop: '10.0000pt', marginBottom: '10.0000pt' }}>
              <strong>
                <span
                  style={{
                    color: 'rgb(0,0,0)',
                    fontSize: '19px',
                  }}
                >
                  “KIDFI” MMC tərəfindən
                </span>
              </strong>
            </p>
            <h1>
              <strong>
                <span style={{ fontSize: '19px' }}>Bizim haqqımızda</span>
              </strong>
            </h1>
            <p style={{ textIndent: '18.0000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                &quot;KidFi&quot; (bundan sonra &quot;biz&quot;) Azərbaycan
                Respublikasında №
              </span>
              <span
                style={{
                  color: 'rgb(0,0,0)',
                  fontSize: '16px',
                }}
              >
                1405934651&nbsp;
              </span>
              <span style={{ fontSize: '16px' }}>
                ilə qeydiyyatdan keçmiş&nbsp;
              </span>
              <strong>
                <span style={{ fontSize: '16px' }}>“KIDFI” MMC</span>
              </strong>
              <span style={{ fontSize: '16px' }}>
                &nbsp;tərəfindən idarə olunan, mobil cihazınıza və ya
                komputerinizə (birlikdə &quot;cihaz&quot;) yüklənə və
                quraşdırıla bilən mobil proqram təminatıdır (bundan sonra
                &quot;tətbiq&quot;). &nbsp;
              </span>
            </p>
            <h1>
              <strong>
                <span style={{ fontSize: '19px' }}>Tətbiq haqqında&nbsp;</span>
              </strong>
            </h1>
            <p style={{ textIndent: '18.0000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                &nbsp;&quot;KidFi&quot; uşaq(lar)ı maliyyə, qazanc və onlardan
                istifadə sahəsində və tətbiqdə göstərilən vəzifələrin yerinə
                yetirilməsi əsasında əsas biliklərlə təmin etmək&nbsp;
              </span>
              <span style={{ fontSize: '16px' }}>məqsədilə&nbsp;</span>
              <span style={{ fontSize: '16px' }}>
                uşaq(lar) və valideyn(lər) (birlikdə &quot;istifadəçi&quot;)
                üçün nəzərdə tutulmuş onlayn tətbiqdir.&nbsp;
              </span>
            </p>
            <p style={{ textIndent: '18.0000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                &quot;KidFi&quot; təhsil xidməti təminatçısı və ya təhsil
                müəssisəsi deyil.&nbsp;
              </span>
            </p>
            <p style={{ textIndent: '18.0000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                Tətbiqdən istifadə etməklə siz bu İstifadə Qaydalarında müəyyən
                edilmiş şərtlərə, həmçinin Məxfilik Siyasətimizə və tətbiqdə
                təklif etdiyimiz əlavə funksiyalar, məhsullar və ya xidmətləri
                satın aldığınız təqdirdə, sizə açıqlanan hər hansı məlumata
                razılıq vermiş olursunuz (bundan sonra &quot;müqavilə&quot;).
                Tətbiqdə qeydiyyatdan keçərkən və xidmətlərimizdən istifadə
                edərkən sizdən bu şərtləri qəbul etməyiniz xahiş olunacaq. Bu
                şərtlərə əməl etməyə razı deyilsinizsə, heç bir xidmətimizdən
                istifadə edə bilməyəcəksiniz.
              </span>
            </p>
            <p
              style={{
                textIndent: '18.0000pt',
                textAlign: 'justify',
                lineHeight: '114%',
              }}
            >
              <span
                style={{
                  lineHeight: '114%',
                  fontSize: '16px',
                }}
              >
                Bu müqaviləyə və tətbiqə yeni funksiyalar yaratmaq və ya biznes
                fəaliyyətimizdə dəyişikliklər etmək, habelə milli
                qanunvericilikdə və beynəlxalq standartlardakı dəyişikliklərə və
                tələblərə uyğunluğu təmin etmək üçün vaxtaşırı düzəlişlər və ya
                əlavələr edilə bilər. Qarşılaşacağınız versiya ən son versiyadır
                və tətbiqdə &quot;Parametrlər&quot; bölməsində, eləcə də&nbsp;
              </span>
              <a href="https://www.kidfi.co/">
                <u>
                  <span
                    style={{
                      lineHeight: '114%',
                      color: 'rgb(5,99,193)',
                      textDecoration: 'underline',
                      fontSize: '16px',
                    }}
                  >
                    https://www.kidfi.co/
                  </span>
                </u>
              </a>
              <span
                style={{
                  lineHeight: '114%',
                  fontSize: '16px',
                }}
              >
                &nbsp;saytında yerləşdirilib.&nbsp;
              </span>
            </p>
            <p
              style={{
                textIndent: '18.0000pt',
                textAlign: 'justify',
                lineHeight: '114%',
              }}
            >
              <span
                style={{
                  lineHeight: '114%',
                  fontSize: '16px',
                }}
              >
                Dəyişikliklər və ya əlavələr, istifadəçinin hüquq və
                öhdəliklərinə təsir edən əhəmiyyətli dəyişikliklər istisna
                olmaqla, istifadəçiyə əvvəlcədən xəbərdarlıq edilmədən həyata
                keçirilir. Bu halda, biz sizə dəyişikliklər barədə tətbiqdə
                bildiriş vasitəsilə və ya qeydiyyat zamanı göstərilən telefon
                nömrəniz də daxil olmaqla, ağlabatan üsullarla əvvəlcədən
                məlumat verəcəyik. Dəyişikliklər və əlavələr qüvvəyə mindikdən
                sonra tətbiqdən istifadə etməyə davam etsəniz, müqavilənin yeni
                versiyasına razılıq vermiş hesab olunursunuz.&nbsp;
              </span>
            </p>
            <p
              style={{
                textIndent: '18.0000pt',
                textAlign: 'justify',
                lineHeight: '114%',
              }}
            >
              <span
                style={{
                  lineHeight: '114%',
                  fontSize: '16px',
                }}
              >
                Siz razılaşırsınız ki, bu müqavilə hər hansı əvvəlki
                razılaşmaları (bu sənəddə xüsusi olaraq müzakirə edilən hallar
                istisna olmaqla) əvəz edir və bu müqaviləni qəbul etməzdən əvvəl
                baş vermiş hər hansı hərəkət və razılaşmalar da daxil olmaqla,
                &quot;KidFi&quot; ilə bütün münasibətlərinizi tənzimləyir.
                &nbsp;
              </span>
            </p>
            <h1>
              <strong>
                <span style={{ fontSize: '19px' }}>
                  Sizdən nə tələb olunur?
                </span>
              </strong>
            </h1>
            <p style={{ textIndent: '18.0000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                &quot;KidFi&quot;-da valideyn hesabı yaratmaq və tətbiqdən
                istifadə etmək üçün ən azı 18 yaşınız olmalıdır. Uşaqlar üçün
                hesablar valideynləri tərəfindən yaradılır və onların bütün
                fəaliyyətləri valideynləri tərəfindən idarə olunur və
                təsdiqlənir. Valideyn olaraq siz bəyan edir və zəmanət
                verirsiniz ki:
              </span>
            </p>
            <ul style={{ listStyleType: 'undefined', marginLeft: '26px' }}>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  •&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  Siz &quot;KidFi&quot; ilə məcburi qüvvəli müqavilə bağlaya
                  bilərsiniz;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  •&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  Siz mövcud qanunvericiliyə uyğun olaraq tətbiqdən istifadə
                  etməsi qadağan olunmuş şəxs deyilsiniz;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  •&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  Mövcud qanunvericiliyə uyğun olaraq uşaq(lar) üçün hesab
                  yaratmaq hüququnuz var;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  •&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  Siz bu müqaviləyə və qüvədə olan bütün milli və beynəlxalq
                  qanunlara və qaydalara əməl etməyə razılaşırsınız;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  •&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  Siz özünüz, övlad(lar)ınız, və hesab yaratmaq hüququnuz ilə
                  bağlı həqiqi məlumatları təqdim etmisiniz.
                </span>
              </li>
            </ul>
            <h1>
              <strong>
                <span style={{ fontSize: '19px' }}>
                  Şəxsi məlumatlarınızdan necə istifadə edirik?
                </span>
              </strong>
            </h1>
            <p style={{ textIndent: '18.0000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                Biz təqdim etdiyiniz şəxsi məlumatlardan yalnız Məxfilik
                Siyasətimizə uyğun olaraq istifadə edirik. Siz
                &quot;KidFi&quot;-da qeydiyyatdan keçmək üçün istifadə etdiyiniz
                etimadnamələrinizin məxfiliyinin qorunmasına cavabdehsiniz və bu
                etimadnamələrdən istifadə etməklə baş verən bütün hərəkətlərə
                görə yalnız siz məsuliyyət daşıyırsınız. Kiminsə hesabınıza
                giriş əldə etdiyinə inanırsınızsa, bu müqavilənin
                &quot;Əlaqə&quot; bölməsi vasitəsilə dərhal bizimlə əlaqə
                saxlayın.&nbsp;
              </span>
              <span style={{ fontSize: '16px' }}>
                Ətraflı məlumat üçün Məxfilik Siyasətimizə nəzər yetirin.
              </span>
            </p>
            <h1>
              <strong>
                <span style={{ fontSize: '19px' }}>Hesabın ləğvi</span>
              </strong>
            </h1>
            <p style={{ textIndent: '18.0000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                Siz öz hesabınızı və/və ya övlad(lar)ınızın hesabını bizə
                əvvəlcədən xəbərdarlıq etmədən istənilən vaxt və səbəbdən ləğv
                edə bilərsiniz. &quot;KidFi&quot; sizin və ya uşaq(lar)ınızın bu
                müqaviləni pozduğunu hesab edərsə, istənilən vaxt əvvəlcədən
                xəbərdarlıq etmədən hesabınızı və ya uşaq(lar)ınızın hesabını
                bağlaya bilər. Hesabınız və ya uşaq(lar)ınızın hesabı ləğv
                edildikdə, bu müqavilə ləğv edilmiş hesab olunacaq.
              </span>
            </p>
            <h1>
              <strong>
                <span style={{ fontSize: '19px' }}>
                  Təhlükəsizlik və məsuliyyətin məhdudlaşdırılması
                </span>
              </strong>
            </h1>
            <p style={{ textIndent: '18.0000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                &quot;KidFi&quot; uşaqları maliyyə sahəsində biliklərlə təmin
                etmək üçün yaradılmışdır və &quot;KidFi&quot; tətbiqdə və ya
                ondan kənar hər hansı istifadəçinin davranışına görə məsuliyyət
                daşımır. Siz uşaq(lar)ınızla qarşılıqlı əlaqədə olarkən, xüsusən
                də uşaq(lar)ınız tərəfindən istifadə üçün kart tələb edərkən
                ehtiyatlı olmağa razılaşırsınız. Siz qüvvədə olan bütün milli və
                beynəlxalq uşaqların müdafiəsi qaydalarına əməl etməlisiniz.
                Uşaq(lar)ınızla qarşılıqlı əlaqəniz, onların bu tətbiqdəki
                təhsili, xərclər və satınalmalar üçün yalnız siz məsuliyyət
                daşıyırsınız. &nbsp;&nbsp;
              </span>
            </p>
            <h1>
              <strong>
                <span style={{ fontSize: '19px' }}>
                  Valideyn(lər)in vəzifələri
                </span>
              </strong>
            </h1>
            <p style={{ textIndent: '18.0000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                &quot;KidFi&quot; sizi tətbiqə daxil olmaq və istifadə etmək
                üçün şəxsi, başqasına ötürülməyən hüquq ilə təmin edir. Bu hüquq
                sizə bu müqavilə ilə təmin edilmiş və icazə verilən tətbiqin
                üstünlüklərindən istifadə etmək üçün verilir. Beləliklə,
                siz&nbsp;
              </span>
              <span style={{ fontSize: '16px' }}>aşağıdakılarla&nbsp;</span>
              <span style={{ fontSize: '16px' }}>razılaşırsınız:</span>
            </p>
            <ul style={{ listStyleType: 'undefined', marginLeft: '26px' }}>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  •&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  Bizim yazılı razılığımız olmadan tətbiqdən və ya tətbiqdə olan
                  hər hansı məzmundan kommersiya məqsədləri üçün istifadə
                  etməmək, həmçinin &quot;KidFi&quot;-ın əvvəlcədən yazılı
                  razılığı olmadan müəllif hüquqları ilə qorunan materialları,
                  şəkilləri, əmtəə nişanlarını, ticarət adlarını və ya əqli
                  mülkiyyətin digər növlərini, məzmun və ya tətbiq vasitəsilə
                  əldə edilə bilən məxfi məlumatı hər hansı formada
                  kopyalamamaq, dəyişdirməmək, ötürməmək, hər hansı bir törəmə
                  əsər yaratmamaq, istifadə etməmək və ya çoxaltmamaq;&nbsp;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  •&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  Məlumata daxil olmaq, çıxarmaq, indeksləşdirmək və ya məhv
                  etmək məqsədilə tətbiqi axtarmaq üçün heç bir robot, bot və ya
                  tətbiqdən istifadə etməmək;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  •&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  Tətbiqin işinə mane ola biləcək, poza biləcək və ya mənfi
                  təsir göstərə biləcək şəkildə tətbiqdən istifadə etməmək;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  •&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  Virusları və ya digər zərərli kodları yükləməmək və ya
                  tətbiqin təhlükəsizliyini digər şəkildə pozmamaq;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  •&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  Hər hansı şəxsi istənilən digər vebsayta yönləndirmək üçün
                  &quot;KidFi&quot;-a istinad edən hər hansı vasitədən (və ya
                  hər hansı əmtəə nişanı, ticarət adı, loqo və ya
                  &quot;KidFi&quot; şüarı) istifadə etməmək;&nbsp;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  •&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  Tətbiqin hər hansı hissəsini dəyişdirməmək, uyğunlaşdırmamaq,
                  tərcümə etməmək, satmamaq, şifrəni açmamaq, dekompilyasiya
                  etməmək və ya başqa şəkildə sökməmək və ya başqalarını bunlara
                  məcbur etməmək;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  •&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  Bizim yazılı razılığımız olmadan &quot;KidFi&quot; tətbiqinin
                  proqram interfeysindən istifadə etməmək, daxil olmamaq və ya
                  dərc etməmək;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  •&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  Tətbiqi araşdırmamaq, skan etməmək və ya sınaqdan keçirməmək;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  •&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  Bu müqaviləni pozan hər hansı fəaliyyəti təşviq etməmək.
                </span>
              </li>
            </ul>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>Bundan əlavə:</span>
            </p>
            <ul style={{ listStyleType: 'undefined', marginLeft: '26px' }}>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  •&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  Tətbiqdən bu müqavilə ilə qadağan edilmiş və ya qeyri-qanuni
                  hər hansı məqsəd üçün istifadə etməmək;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  •&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  Tətbiqdən qərəzli məqsədlər üçün, xüsusilə, milli və ya
                  beynəlxalq qanunların uşaq hüquqlarının müdafiəsi ilə bağlı
                  bölməsinə münasibətdə istifadə etməmək;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  •&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  Tətbiqdən &quot;KidFi&quot;-a zərər vermək üçün istifadə
                  etməmək;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  •&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  Vaxtaşırı yenilənən Məxfilik Siyasətimizi pozmamaq;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  •&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  Spam göndərməmək, pul qəsb etməmək və ya istifadəçiləri
                  aldatmamaq;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  •&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  Heç kimin hüquqlarını, o cümlədən aşkarlıq, məxfilik,
                  müəlliflik hüquqları, əmtəə nişanları və ya digər əqli
                  mülkiyyət və ya müqavilə hüquqlarını pozan məzmun dərc
                  etməmək;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  •&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  Nifrətə sövq edən, təhdidləri ehtiva edən, açıq-aşkar seksual
                  və ya pornoqrafik, zorakılığa təhrik edən və ya qrafik və ya
                  əsassız zorakılıq ehtiva edən hər hansı məzmun yerləşdirməmək;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  •&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  Hər hansı qrupa və ya şəxsə qarşı irqçilik, təəssübkeşlik,
                  nifrət və ya hər hansı fiziki zərəri təbliğ edən məzmun
                  yerləşdirməmək;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  •&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  Başqa istifadəçilərdən hər hansı məqsəd üçün parol və ya
                  kommersiya və ya qeyri-qanuni məqsədlər üçün şəxsi məlumatı
                  tələb etməmək və ya onun icazəsi olmadan başqa şəxsin şəxsi
                  məlumatlarını yaymamaq;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  •&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  Başqa istifadəçinin hesabından istifadə etməmək, hesabı digər
                  istifadəçilərlə paylaşmamaq və ya birdən çox hesaba sahib
                  olmamaq;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  •&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  Şəxsiyyətiniz, uşaq(lar)ınızın şəxsiyyəti və uşaq(lar) üçün
                  hesab yaratmaq hüquq qabiliyyətinizlə bağlı həqiqi olmayan
                  məlumatlardan istifadə etməmək;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  •&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  Qüvvədə olan vergi qanunvericiliyini və ya çirkli pulların
                  yuyulmasına qarşı qanunvericiliyi pozmamaq.
                </span>
              </li>
            </ul>
            <p style={{ textIndent: '18.0000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                &quot;KidFi&quot; tətbiqdən qeyri-qanuni və/yaxud icazəsiz
                istifadəyə cavab olaraq, pozan şəxsin hesabının bağlanması da
                daxil olmaqla, hər hansı mövcud hüquqi tədbirləri araşdıra və
                görə bilər. Əgər pozuntu &quot;KidFi&quot; və ya onun
                istifadəçilərinə zərərlə nəticələnmişdirsə, &quot;KidFi&quot;
                həmçinin səlahiyyətli orqanlara məlumat vermək və dəymiş ziyanı
                tam şəkildə tələb etmək hüququnu özündə saxlayır.
              </span>
            </p>
            <h1>
              <strong>
                <span style={{ fontSize: '16px' }}>&quot;</span>
              </strong>
              <strong>
                <span style={{ fontSize: '19px' }}>KidFi&quot;</span>
              </strong>
              <strong>
                <span style={{ fontSize: '19px' }}>
                  -ın hüquq və vəzifələri
                </span>
              </strong>
            </h1>
            <p style={{ textIndent: '13.5000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                Hesab yaratmaqla siz &quot;KidFi&quot;-ı təqdim olunan
                məlumatları, eləcə də tətbiqdə dərc etdiyiniz, yüklədiyiniz,
                nümayiş etdirdiyiniz və ya başqa şəkildə əlçatan etdiyiniz
                (birlikdə &quot;nəşretmə&quot;) və ya digər istifadəçilərə
                ötürdüyünüz məlumatları (birlikdə &quot;məzmun&quot;)
                yerləşdirmək, saxlamaq, istifadə etmək, surətini çıxarmaq,
                nümayiş etdirmək, çoxaltmaq, uyğunlaşdırmaq, redaktə etmək, dərc
                etmək, dəyişdirmək və yaymaq üçün ötürülə bilən və qonorarsız
                hüquq ilə təmin edirsiniz.&nbsp;
              </span>
            </p>
            <p style={{ textIndent: '13.5000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                &quot;KidFi&quot; tərəfindən tətbiqdən kənar məzmun
                istifadəsinin qarşısını almaq üçün siz &quot;KidFi&quot;-a
                müəllif hüquqlarını pozaraq üçüncü şəxslər tərəfindən tətbiqdən
                götürülmüş məzmununuzun istifadəsi ilə bağlı sizin adınıza
                hərəkət etməyə icazə verirsiniz. Məzmununuza olan hüququmuz
                müvafiq qanunvericiliyə əsasən sizin hüquqlarınızla qarşılıqlı
                şəkildə tənzimlənir (məsələn, hər hansı məzmunun bu qanunlarla
                müəyyən edilmiş şəxsi məlumatları ehtiva etdiyi dərəcədə fərdi
                məlumatların mühafizəsi qanunları) və məhdud fəaliyyət, inkişaf,
                təminat və təkmilləşdirilməsi məqsədləri üçün nəzərdə
                tutulub.&nbsp;
              </span>
            </p>
            <p style={{ textIndent: '13.5000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                Hesabınızı yaratarkən təqdim etdiyiniz bütün məlumatların dəqiq
                və etibarlı olması ilə razılaşırsınız və sizin tətbiqdə məzmun
                yerləşdirmək hüququnuz var.
              </span>
            </p>
            <p style={{ textIndent: '13.5000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                Siz anlayır və razılaşırsınız ki, biz tətbiqdə yerləşdirdiyiniz
                hər hansı məzmuna nəzarət edə və ya yoxlaya bilərik. Bu
                Müqaviləni pozan və ya &quot;KidFi&quot;-ın nüfuzuna xələl
                gətirə bilən hər hansı məzmunu tamamilə və ya qismən silə
                bilərik.
              </span>
            </p>
            <p style={{ textIndent: '13.5000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                Siz bizim, filiallarımızın və üçüncü-tərəf partnyorlarımızın
                tətbiqdə reklam yerləşdirməyinə razılıq verirsiniz. Tətbiqimiz
                haqqında təklif və ya rəyləri &quot;KidFi&quot;-a təqdim etməklə
                razılaşırsınız ki, &quot;KidFi&quot; sizə kompensasiya ödəmədən
                bu cür rəylərdən hər hansı məqsəd üçün istifadə edə və paylaşa
                bilər.
              </span>
            </p>
            <p style={{ textIndent: '13.5000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                Siz razılaşırsınız ki, &quot;KidFi&quot; hesab məlumatlarınıza
                və məzmununuza qanunla tələb olunarsa və ya vicdanla belə
                girişin, saxlanmanın və ya açıqlamanın ağlabatan formada zəruri
                olduğuna inanaraq, məsələn, (a) hüquqi prosesə riayət etmək; (b)
                bu müqaviləyə əməl olunmasının təmin edilməsi; (c) hər hansı
                məzmunun üçüncü şəxslərin hüquqlarını pozduğuna dair iddialara
                cavab vermək; (d) müştəri xidməti sorğularınıza cavab vermək;
                (e) &quot;KidFi&quot; və ya hər hansı digər şəxsin hüquqlarını
                və ya şəxsi təhlükəsizliyini qorumaq üçün daxil ola, saxlaya və
                açıqlaya bilər.
              </span>
            </p>
            <h1>
              <strong>
                <span style={{ fontSize: '19px' }}>
                  KidFi-da ödənişlər, abunəlik və avtomatik yenilənmələr
                </span>
              </strong>
            </h1>
            <p style={{ textIndent: '18.0000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                Siz Tətbiq vasitəsilə satın alınan xidmətlər üçün ödəniş
                öhdəliklərinizə əməl etməlisiniz. Abunəliyinizə tətbiq edilən
                təkrarlanan ödənişlər üçün razılaşırsınız ki, bizim adımızdan
                fəaliyyət göstərən üçüncü tərəflər (“Provayder”) bu cür
                ödənişləri emal etmək üçün ödəniş məlumatınızı saxlaya bilər.
                Provayder öz mülahizəsinə uyğun olaraq istənilən vaxt
                xidmətlərinin qiymətlərini dəyişmək hüququna malikdir. Siz
                həmçinin Provayderə bu cür üçüncü tərəf təchizatçıları
                vasitəsilə Tətbiq vasitəsilə etdiyiniz təkrarlanan abunə
                haqlarınızı emal etmək üçün faylda olan Ödəniş Metodunuzdan
                istifadə etməyə icazə verirsiniz. Provayder pulun geri
                qaytarılmasına zəmanət vermir. Siz həmçinin sifariş etdiyiniz
                xidmətlər üçün vaxtında müvafiq rüsumları (o cümlədən, lakin
                bununla məhdudlaşmadan aylıq və ya illik abunəliklər üçün dövri
                ödənişlər və ya tətbiq oluna biləcək hər hansı əlavə inzibati
                ödənişlər də daxil olmaqla) və bütün əlaqədar vergiləri ödəməyə
                və vaxtı keçmiş məbləğlər üçün bütün toplama xərcləri və faizlər
                ilə bağlı bizi kompensasiya etməyə razılıq verirsiniz.
              </span>
            </p>
            <p style={{ textIndent: '18.0000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                Kredit kartı kimi istifadə etdiyiniz Ödəniş Metodu bitmə
                tarixinə çatarsa və ödəniş metodu məlumatlarınızı redaktə
                etməsəniz və ya Hesabınızı və ya müvafiq xidməti ləğv etməsəniz,
                siz bizə həmin ödəniş metodu ilə bağlı hesablaşmanı davam
                etdirməyə icazə verirsiniz və hər hansı toplanmamış məbləğlər
                üçün siz məsuliyyət daşıyırsınız.. Ödəniş etmək öhdəliyiniz
                abunəliyinizi ləğv edənə qədər abunəlik müddəti ərzində davam
                edir. Bütün tətbiq olunan vergilər alış zamanı bizə təqdim
                etdiyiniz faktura məlumatlarına əsasən hesablanır.
              </span>
            </p>
            <p style={{ textIndent: '18.0000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                Avtomatik yenilənmə ilə abunəlik alsanız, siz abunəliyi ləğv
                edənə qədər abunə haqqı Ödəniş Metodunuzdan tutulmağa davam
                edəcək. İlkin abunəlik və hər hansı sonrakı abunəlik müddətindən
                sonra abunəliyiniz abunə olarkən razılaşdığınız qiymətə əlavə
                ekvivalent müddətə avtomatik olaraq yenilənəcək.
              </span>
            </p>
            <p style={{ textIndent: '18.0000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                Abunəliyin avtomatik yenilənməsini istəmirsinizsə və ya
                abunəliyinizi dəyişdirmək və ya ləğv etmək istəyirsinizsə, hətta
                &quot;KidFi&quot; tətbiqini cihazınızdan silmiş və ya
                &quot;KidFi&quot; hesabınızı başqa şəkildə silmiş olsanız belə,
                üçüncü tərəf hesabınıza daxil olmalı və abunəliyinizi ləğv
                etməlisiniz. &quot;KidFi&quot; hesabınızın silinməsi və ya
                &quot;KidFi&quot; tətbiqinin cihazınızdan silinməsi
                abunəliyinizi ləğv etmir. Üçüncü tərəf hesabına abunəliyinizi
                ləğv edənə qədər &quot;KidFi&quot; Ödəniş Metodunuzdan debet
                edilmiş bütün vəsaitləri özündə saxlayacaq. Abunəliyinizi ləğv
                etsəniz, onu cari abunə müddətinin sonuna qədər istifadə edə
                biləcəksiniz və cari müddət bitdikdən sonra abunəliyiniz
                yenilənməyəcək.
              </span>
            </p>
            <h1>
              <strong>
                <span style={{ fontSize: '19px' }}>
                  KidFi-da əmtəə və xidmətlərin satın alınması
                </span>
              </strong>
            </h1>
            <p style={{ textIndent: '13.5000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                &quot;KidFi&quot; Google Pay, ApplePay &nbsp;və/və ya
                &quot;KidFi&quot; tərəfindən icazə verilən digər ödəniş
                platformaları vasitəsilə satınalma üçün məhsul və xidmətlər
                təklif edə bilər (bundan sonra &quot;tətbiqdaxili
                satınalma&quot;). Tətbiqdaxili satınalma zamanı sizdən müvafiq
                ödəniş xidməti provayderi ilə alışı təsdiqləməyiniz və ödəniş
                üsulunu (kartınızdan və ya üçüncü tərəf hesabınızdan, məsələn,
                Google Pay və ya ApplePay-dən istifadə etməklə) (bundan sonra
                &quot;ödəniş metodu&quot;) seçməyiniz tələb olunacaq. Sizdən
                vergi şəklində əlavə ödəniş tutula bilər.
              </span>
            </p>
            <h1>
              <strong>
                <span style={{ fontSize: '19px' }}>
                  Üçüncü-tərəf xidmətləri&nbsp;
                </span>
              </strong>
            </h1>
            <p style={{ textIndent: '18.0000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                Tətbiqdə üçüncü şəxslər tərəfindən təklif olunan reklamlar və
                promosyonlar, həmçinin digər vebsaytlara və ya resurslara
                keçidlər ola bilər. &quot;KidFi&quot; bu cür kənar veb-saytların
                və ya resursların mövcudluğu və ya olmamasına görə məsuliyyət
                daşımır. Tətbiqimiz vasitəsilə mümkün olan üçüncü şəxslərlə
                qarşılıqlı əlaqədə olmaq qərarına gəlsəniz, belə bir əlaqənin
                şərtləri sizinlə onlar arasındakı başqa razılaşma ilə
                tənzimlənəcək. &quot;KidFi&quot; üçüncü şəxslərin şərtlərinə və
                ya hərəkətlərinə görə məsuliyyət daşımır.
              </span>
            </p>
            <h1>
              <strong>
                <span style={{ fontSize: '19px' }}>Zəmanətlərdən imtina</span>
              </strong>
            </h1>
            <p style={{ textIndent: '18.0000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                Siz açıq şəkildə razılaşırsınız ki, tətbiqin istifadəsi üçün
                məsuliyyəti öz üzərinizə götürürsünüz və tətbiqdən yalnız öz
                riskinizlə istifadə edirsiniz. Biz açıq və ya nəzərdə tutulan
                istənilən növ, o cümlədən, lakin bununla məhdudlaşmayaraq,
                satıla bilənlik, müəyyən istifadə və ya məqsəd üçün uyğunluq,
                pozulmama, işləklik, vəziyyət, dəyər, məlumatların dəqiqliyi və
                sistem inteqrasiyası ilə bağlı hər hansı zəmanətdən açıq şəkildə
                imtina edirik. &quot;KidFi&quot; tətbiqin fasiləsiz, təhlükəsiz
                və ya səhvsiz olacağını təmsil etmir və ya zəmanət vermir,
                tətbiqdəki hər hansı qüsur və ya səhvlərin düzəldiləcəyinə və ya
                tətbiq vasitəsilə alınan hər hansı məlumatın dəqiq olacağına
                zəmanət vermir.
              </span>
            </p>
            <h1>
              <strong>
                <span style={{ fontSize: '19px' }}>
                  Məsuliyyətin məhdudlaşdırılması
                </span>
              </strong>
            </h1>
            <p style={{ textIndent: '18.0000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                Siz açıq şəkildə razılaşırsınız ki, Azərbaycan Respublikasının
                qüvvədə olan qanunvericiliyi ilə icazə verilən həddə, heç bir
                halda “KidFi” və ya onun vəzifəli şəxsləri, işçiləri,
                direktorları, törəmə şirkətləri, filialları, agentləri və ya
                lisenziya verənləri tətbiq və ya xidmətlərdən istifadənizlə
                bağlı yaranan hər hansı dolayı, təsadüfi və ya digər zərərlər, o
                cümlədən, lakin bununla məhdudlaşmayaraq, mənfəət itkisi,
                məlumatların istifadəsi, işgüzar nüfuza dəyən zərərdən
                nəticələnən itkilər və ya digər qeyri-maddi itkilər üçün, bu cür
                zərərlərin müqavilə, hüquq pozuntusu, zəmanət, qanun və ya digər
                formaya əsaslanmasından asılı olmayaraq, məsuliyyət daşımır.
                Mövcud qanunvericiliklə icazə verilən həddə, hər hansı nəzərdə
                tutulan zəmanətlərdən imtina və ya öhdəlikləri məhdudlaşdırma,
                belə zəmanətin əhatə dairəsi və müddəti, habelə
                &quot;KidFi&quot;-nin məsuliyyətinin həddi belə qanunvericilik
                ilə icazə verilən minimum hədd olacaqdır.
              </span>
            </p>
            <h1>
              <strong>
                <span style={{ fontSize: '19px' }}>Təzminat</span>
              </strong>
            </h1>
            <p style={{ textIndent: '18.0000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                Siz &quot;KidFi&quot;-ı, onun vəzifəli şəxslərini,
                direktorlarını, işçilərini, agentlərini, törəmə şirkətlərini,
                filiallarını, lisenziya verənlərini və tədarükçülərini hər hansı
                iddialardan, hərəkətlərdən, tələblərdən, öhdəliklərdən və
                hesablaşmalardan, o cümlədən, lakin bununla məhdudlaşmayaraq, bu
                şərtləri pozmağınız nəticəsində yaranan ağlabatan hüquqi və
                mühasibat haqlarından (bundan sonra &quot;iddialar&quot;)
                kompensasiya etməyə, müdafiə etməyə və təhlükəsizliyini təmin
                etməyə açıq şəkildə razılaşırsınız.&nbsp;
              </span>
            </p>
            <h1>
              <strong>
                <span style={{ fontSize: '19px' }}>
                  Tətbiq olunan qanunvericilik
                </span>
              </strong>
            </h1>
            <p style={{ textIndent: '13.5000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                Bu müqavilənin mövcudluğu və ya təfsiri ilə bağlı bütün
                mübahisələr, anlaşılmazlıqlar və/yaxud fikir ayrılıqları (bundan
                sonra &quot;mübahisə&quot;) üçün tərəflər əvvəlcə məsələni öz
                aralarında sülh danışıqları yolu ilə həll etməyə çalışırlar.
                Mübahisə ilə bağlı tərəflər razılığa gəlmədikdə, məsələ
                Azərbaycan Respublikasının müvafiq məhkəmələri tərəfindən həll
                edilir və məhkəmənin qərarı tərəflər üçün qəti və məcburi olur.
                Tərəflər Azərbaycan Respublikasının məhkəmələrinin
                səlahiyyətlərini tam qəbul edirlər.&nbsp;
              </span>
            </p>
            <h1>
              <strong>
                <span style={{ fontSize: '19px' }}>Digər</span>
              </strong>
            </h1>
            <p style={{ textIndent: '18.0000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                Bu müqavilənin hər hansı müddəasının etibarsız olduğu aşkar
                edilərsə, müqavilənin qalan hissəsi tam qüvvədə qalacaq.
                &quot;KidFi&quot;-ın hər hansı hüquq və ya bu müqavilənin hər
                hansı müddəasını həyata keçirməməsi belə hüquq və ya müddəadan
                imtina demək deyil. Siz razılaşırsınız ki, &quot;KidFi&quot;
                hesabınız başqasına ötürülə bilməz və hesabınız və onun məzmunu
                ilə bağlı bütün hüquqlarınız ölümünüzdən sonra ləğv edilir. Bu
                müqavilə nəticəsində heç bir agentlik, tərəfdaşlıq, birgə
                müəssisə, fidusiar və ya digər xüsusi münasibət və ya əmək
                münasibətləri yaradılmır və siz &quot;KidFi&quot; adından və ya
                onunla əlaqəli hər hansı bir şəkildə heç bir bəyanat verə
                bilməzsiniz.
              </span>
            </p>
            <h1>
              <strong>
                <span style={{ fontSize: '19px' }}>Əlaqə</span>
              </strong>
            </h1>
            <p
              style={{
                marginTop: '5.0000pt',
                marginBottom: '5.0000pt',
                textIndent: '18.0000pt',
                textAlign: 'justify',
                lineHeight: '114%',
              }}
            >
              <span
                style={{
                  lineHeight: '114%',
                  fontSize: '16px',
                }}
              >
                Bu müqavilə və ya digər məsələlər ilə bağlı hər hansı tövsiyə və
                ya sualınız olarsa, onları&nbsp;
              </span>
              <a href="mailto:info@kidfi.co">
                <strong>
                  <u>
                    <span
                      style={{
                        lineHeight: '114%',
                        color: 'rgb(5,99,193)',
                        textDecoration: 'underline',
                        fontSize: '16px',
                      }}
                    >
                      info@kidfi.co
                    </span>
                  </u>
                </strong>
              </a>
              <strong>
                <span
                  style={{
                    lineHeight: '114%',
                    color: 'rgb(0,0,0)',
                    fontSize: '16px',
                  }}
                >
                  &nbsp;
                </span>
              </strong>
              <span
                style={{
                  lineHeight: '114%',
                  fontSize: '16px',
                }}
              >
                vasitəsilə bizə yönləndirə bilərsiniz.
              </span>
            </p>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>&nbsp;</span>
            </p>
            <p
              style={{
                marginTop: '5.0000pt',
                marginBottom: '5.0000pt',
                lineHeight: '114%',
              }}
            >
              <strong>
                <span
                  style={{
                    lineHeight: '114%',
                    color: 'rgb(0,0,0)',
                    fontSize: '16px',
                  }}
                >
                  “KIDFI” MMC
                </span>
              </strong>
            </p>
            <p
              style={{
                marginTop: '5.0000pt',
                marginBottom: '5.0000pt',
                lineHeight: '114%',
              }}
            >
              <strong>
                <span
                  style={{
                    lineHeight: '114%',
                    color: 'rgb(0,0,0)',
                    fontSize: '16px',
                  }}
                >
                  1405934651, Bakı şəhəri, Nəsimi rayonu, Ə.Hüseynov küçəsi,
                </span>
              </strong>
              <strong>
                <span
                  style={{
                    lineHeight: '114%',
                    color: 'rgb(0,0,0)',
                    fontWeight: 'bold',
                    fontSize: '16px',
                  }}
                >
                  &nbsp;5/7, 454
                </span>
              </strong>
            </p>
            <p
              style={{
                marginTop: '5.0000pt',
                marginBottom: '5.0000pt',
                textAlign: 'justify',
                lineHeight: '114%',
              }}
            >
              <strong>
                <span
                  style={{
                    lineHeight: '114%',
                    color: 'rgb(0,0,0)',
                    fontSize: '16px',
                  }}
                >
                  Son yenilənmə: Avqust 2022
                </span>
              </strong>
            </p>
            <p style={{ textIndent: '13.5000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>&nbsp;</span>
            </p>
          </>
        ) : lang === 'en' ? (
          <>
            <p style={{ textAlign: 'center' }}>
              <strong>
                <span style={{ fontSize: '19px' }}>TERMS OF USE</span>
              </strong>
            </p>
            <p style={{ textAlign: 'justify', lineHeight: '114%' }}>
              <strong>
                <span
                  style={{
                    lineHeight: '114%',
                    color: 'rgb(0,0,0)',
                    fontSize: '19px',
                  }}
                >
                  Issued By “KIDFI” LLC
                </span>
              </strong>
            </p>
            <h1>
              <strong>
                <span style={{ fontSize: '19px' }}>About us</span>
              </strong>
            </h1>
            <p style={{ textIndent: '18.0000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                &quot;KidFi&quot; (&quot;we&quot;, &quot;us&quot; or
                &quot;our&quot;), operated by&nbsp;
              </span>
              <strong>
                <span style={{ fontSize: '16px' }}>“KIDFI” LLC</span>
              </strong>
              <span style={{ fontSize: '16px' }}>
                , a legal entity registered in the Republic of Azerbaijan, №
              </span>
              <span
                style={{
                  color: 'rgb(0,0,0)',
                  fontSize: '16px',
                }}
              >
                1405934651
              </span>
              <span style={{ fontSize: '16px' }}>
                , is a mobile software application (the &quot;Application&quot;,
                &quot;App&quot;) that can be downloaded and installed on your
                mobile device or tablet computer (collectively, the
                &quot;Device&quot;).&nbsp;
              </span>
            </p>
            <h1>
              <strong>
                <span style={{ fontSize: '19px' }}>About the app</span>
              </strong>
            </h1>
            <p style={{ textIndent: '18.0000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                &nbsp;&quot;KidFi&quot; is an online application for the
                Child(ren) and Parent(s) (collectively referred to as
                &quot;User&quot;), which provides Child(ren) with basic
                knowledge in the field of finance, earnings, and their use based
                on the performance of tasks specified in the Application.&nbsp;
              </span>
            </p>
            <p style={{ textIndent: '18.0000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                &quot;KidFi&quot; is not an educational service provider or
                educational institution.
              </span>
            </p>
            <p style={{ textIndent: '18.0000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                By using the App, you agree to comply with the rules set out in
                these Terms of Use, as well as our Privacy Policy and any
                information disclosed to you if you have purchased additional
                features, products, or services that we offer in the App (the
                &quot;Agreement&quot;). You will be asked to accept these terms
                when registering in the App and when using our services. You are
                not allowed to use any of our services if you do not agree to
                comply with these Terms. &nbsp;
              </span>
            </p>
            <p style={{ textIndent: '18.0000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                This Agreement and the App may be amended or supplemented from
                time to time to establish new functions or changes in business
                practices, and to ensure compliance with changes and
                requirements of national legislation and international
                standards. The version you will be dealing with is the latest
                and it is placed in the Application in the &quot;Settings&quot;
                section, as well as on the website https://www.kidfi.co/.&nbsp;
              </span>
            </p>
            <p style={{ textIndent: '18.0000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                Changes or additions are made without prior notice to the User,
                except if the changes are significant, which affect the rights
                and obligations of the User. In this case, we will notify you in
                advance of the changes in reasonable ways, which may include
                notification through the Application or via the mail address
                specified during registration in the Application. If you
                continue to use the Application after the amendments and
                additions come into force, it is considered that you have agreed
                to the new version of the Agreement. &nbsp;
              </span>
            </p>
            <p style={{ textIndent: '18.0000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                You agree that this Agreement supersedes any previous agreements
                (with the exception of cases specifically discussed in this
                document) and governs all your relations with &quot;KidFi&quot;,
                including any actions and agreements that preceded your
                acceptance of this Agreement.&nbsp;
              </span>
            </p>
            <h1>
              <strong>
                <span style={{ fontSize: '19px' }}>
                  What do we need from you?
                </span>
              </strong>
            </h1>
            <p style={{ textIndent: '18.0000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                To create a parent account in &quot;KidFi&quot; and use the
                Application, you must be at least 18 years old. All child-type
                accounts (the Child(ren) account) are created by the Parent(s),
                and all child(ren) activities are controlled and approved by the
                Parent(s). As a Parent, you declare and guarantee that:
              </span>
            </p>
            <ul style={{ listStyleType: 'undefined', marginLeft: '26px' }}>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  •&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  You can enter into a binding contract with &quot;KidFi&quot;;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  •&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  You are not a person who is prohibited from using the
                  Application in accordance with applicable legislation;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  •&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  You have the right to create an account for the Child(ren) in
                  accordance with applicable legislation;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  •&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  You agree to comply with this Agreement and all applicable
                  national and international laws, rules, and regulations;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  •&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  You have provided real data concerning you, the Child(ren),
                  and your right to create an account for yourself and the
                  Child(ren).
                </span>
              </li>
            </ul>
            <h1>
              <strong>
                <span style={{ fontSize: '19px' }}>
                  How we use your personal information
                </span>
              </strong>
            </h1>
            <p style={{ textIndent: '18.0000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                We only use the personal information that you have provided to
                us in accordance with our Privacy Policy. You are responsible
                for maintaining the confidentiality of the credentials that you
                use to register with &quot;KidFi&quot;, and you are solely
                responsible for all actions that occur using these credentials.
                If you believe that someone has gained access to your account,
                please contact us immediately through the section of this
                Agreement &quot;To contact us&quot;. For more information,
                please read our Privacy Policy.
              </span>
            </p>
            <h1>
              <strong>
                <span style={{ fontSize: '19px' }}>
                  Termination of the account
                </span>
              </strong>
            </h1>
            <p style={{ textIndent: '18.0000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                You may terminate your account and/or your Child(ren)`s account
                at any time and for any reason without prior notice to us.
                &quot;KidFi&quot; may close your account or the Child(ren)’s
                account at any time without prior notice if it considers that
                you or the Child(ren) has/have violated this Agreement. Upon
                termination of your account or the Child(ren)`s account, this
                Agreement will terminate.
              </span>
            </p>
            <h1>
              <strong>
                <span style={{ fontSize: '19px' }}>
                  Security and Limitation of Liability
                </span>
              </strong>
            </h1>
            <p style={{ textIndent: '18.0000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                The purpose of &quot;KidFi&quot; is to provide financial
                education to the Child(ren) and &quot;KidFi&quot; is not
                responsible for the conduct of any User on or off the
                Application. You agree to use caution in all interactions with a
                Child(ren), especially if you choose to request a card for use
                by your Child(ren). You should comply with all applicable
                national and international child protection regulations. You are
                solely responsible for your interactions with your Child(ren),
                their education on this Application, expenses, and purchases.
                &nbsp;&nbsp;
              </span>
            </p>
            <h1>
              <strong>
                <span style={{ fontSize: '19px' }}>
                  Responsibilities of Parent(s)
                </span>
              </strong>
            </h1>
            <p style={{ textIndent: '18.0000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                &quot;KidFi&quot; grants you a personal, non-transferable right
                to access and use the Application. This right is granted to you
                to use the benefits of the Application, as provided and
                permitted by this Agreement. Thus, you agree:
              </span>
            </p>
            <ul style={{ listStyleType: 'undefined', marginLeft: '26px' }}>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  •&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  Not to use the Application or any content contained in the
                  Application for any commercial purposes without our written
                  consent, also not to copy, modify, transmit, create any
                  derivative works, use or reproduce in any way any copyrighted
                  materials, images, trademarks, trade names, service marks or
                  other types of intellectual property, content or confidential
                  information available through the App without the prior
                  written consent of &quot;KidFi&quot;;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  •&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  Not to use any robot, bot, or application to search the App to
                  access, extract, index, or &quot;mining&quot; data;&nbsp;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  •&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  Not to use the Application in any way that may interfere with,
                  disrupt or negatively affect the operation of the Application;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  •&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  Not to download viruses or other malicious code or otherwise
                  compromise the security of the Application;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  •&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  Not to use any means containing any reference to
                  &quot;KidFi&quot; (or any trademark, trade name, logo, or
                  slogan of &quot;KidFi&quot;) to direct any person to other
                  websites for any purpose;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  •&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  Not to modify, adapt, translate, sell, reverse engineer,
                  decrypt, decompile or otherwise disassemble any part of the
                  Application or force others to do so;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  •&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  Not to use, access, or publish the program interface of the
                  Application without our written consent;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  •&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  Not to investigate, scan or test the vulnerability of the
                  Application;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  •&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  Not to encourage or promote any activity that violates this
                  Agreement.
                </span>
              </li>
            </ul>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>Besides:</span>
            </p>
            <ul style={{ listStyleType: 'undefined', marginLeft: '26px' }}>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  •&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  Not to use the Application for any purposes that are illegal
                  or prohibited by this Agreement;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  •&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  Not to use the Application for any malicious or nefarious
                  purposes, especially in terms of the section of national or
                  international laws on the protection of children`s rights;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  •&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  Not to use the App to damage &quot;KidFi&quot;;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  •&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  Not to violate our Privacy Policy, which is updated from time
                  to time;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  •&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  Not to send spam, extort money, or deceive Users;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  •&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  Not to publish any content that violates anyone`s rights,
                  including rights to publicity, privacy, copyrights,
                  trademarks, or other intellectual property or contractual
                  rights;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  •&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  Not to post any content that incites hatred, involves threats,
                  is sexually explicit or pornographic, incites violence, or
                  contains graphic or unjustified violence;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  •&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  Not to post any content promoting racism, bigotry, hatred, or
                  physical harm of any kind against any group or individual;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  •&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  Not to request a password for any purpose or personal
                  information for commercial or illegal purposes from other
                  Users or to distribute personal information of another person
                  without his or her permission;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  •&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  Not to use another User`s account, share an account with other
                  users, or maintain more than one account;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  •&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  Not to use false information regarding your identity, the
                  identity of the Child(ren), and your legal capacity to create
                  an account for the Child(ren);
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  •&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  Not to violate any applicable tax legislation or anti-money
                  laundering legislation.
                </span>
              </li>
            </ul>
            <p style={{ textIndent: '18.0000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                &quot;KidFi&quot; may investigate and take any available legal
                action in response to illegal and/or unauthorized use of the
                Application, including termination of the violator`s account.
                &quot;KidFi&quot; also reserves the right to notify the
                authorities and claim damages in full if your violation has
                caused harm to &quot;KidFi&quot; or its Users.
              </span>
            </p>
            <h1>
              <strong>
                <span style={{ fontSize: '19px' }}>
                  Rights and obligations of &quot;KidFi&quot;
                </span>
              </strong>
            </h1>
            <p style={{ textIndent: '13.5000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                By creating an account, you grant &quot;KidFi&quot; a
                transferable, royalty-free right to post, store, use, copy,
                display, reproduce, adapt, edit, publish, modify and distribute
                the information provided, as well as any information that you
                publish, upload, display or otherwise make available
                (collectively referred to as &quot;Publication&quot;) in the
                Application or transmit to other users (collectively referred to
                as &quot;Content&quot;).
              </span>
            </p>
            <p style={{ textIndent: '13.5000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                In order for &quot;KidFi&quot; to prevent the use of Content
                outside of the Application, you authorize &quot;KidFi&quot; to
                act on your behalf with respect to the use of your Content taken
                from the Application by third parties in violation of copyright.
                Our right to your Content is regulated reciprocally by your
                rights under applicable legislation (for example, personal data
                protection laws to the extent that any Content contains personal
                information as defined by these laws) and is intended for the
                limited purpose of operation, development, provision, and
                improvement.&nbsp;
              </span>
            </p>
            <p style={{ textIndent: '13.5000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                You agree that all information that you provide when creating
                your account is accurate and reliable, and you have the right to
                post Content in the Application.
              </span>
            </p>
            <p style={{ textIndent: '13.5000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                You understand and agree that we may monitor or verify any
                Content that you post in the App. We may completely or partially
                remove any Content that, in our opinion, violates this Agreement
                or may damage the reputation of &quot;KidFi&quot;.
              </span>
            </p>
            <p style={{ textIndent: '13.5000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                You agree that we, our affiliates, and our third-party partners
                may advertise in the App. By submitting suggestions or reviews
                about our App to &quot;KidFi&quot;, you agree that
                &quot;KidFi&quot; may use and share such reviews for any purpose
                without paying you compensation.
              </span>
            </p>
            <p style={{ textIndent: '13.5000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                You agree that &quot;KidFi&quot; may access, retain and disclose
                your account information and Content if required by law or in
                good faith believing that such access, retention, or disclosure
                is reasonably necessary, for example, to (a) comply with legal
                process; (b) ensuring compliance with this Agreement; (c)
                responding to claims that any Content violates the rights of
                third parties; (d) responding to your customer service requests;
                (e) protecting the rights or personal safety of
                &quot;KidFi&quot; or any other person.
              </span>
            </p>
            <h1>
              <strong>
                <span style={{ fontSize: '19px' }}>
                  KidFi payments, subscription, and automatic updates
                </span>
              </strong>
            </h1>
            <p style={{ textIndent: '18.0000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                You will comply with your payment obligations for services
                purchased through the Application. For recurring payments
                applicable to your subscription, you agree that third parties
                working on our behalf (&quot;Provider&quot;) may store your
                payment information to process such payments. The Provider has
                the right, at its discretion, to change the prices for its
                services at any time. You also allow Provider, through such
                third-party vendors, to use your Payment Method on file to
                process your subscription recurring charges made by you through
                the Application. The Provider does not guarantee a refund. You
                also agree to pay the applicable fees for the services you order
                (including, without limitation, periodic fees for monthly or
                annual subscriptions, or any additional administrative charge
                that may be applied) as they become due plus all related taxes,
                and to reimburse us for all collection costs and interest for
                any overdue amounts.
              </span>
            </p>
            <p style={{ textIndent: '18.0000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                If the Payment Method you use with us, such as a credit card,
                reaches its expiration date and you do not edit your payment
                method information or cancel your Account or the applicable
                service, you authorize us to continue billing that payment
                method and you remain responsible for any uncollected amounts.
                Your obligation to pay fees continues through the subscription
                period until you cancel your subscription. All applicable taxes
                are calculated based on the billing information you provide us
                at the time of purchase.
              </span>
            </p>
            <p style={{ textIndent: '18.0000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                If you purchase a recurring subscription with automatic renewal,
                the subscription bill will continue to be charged to your
                Payment Method until you cancel the subscription. After the
                initial subscription period and after any subsequent
                subscription period, your subscription will be automatically
                renewed for an equivalent additional period at the price you
                agreed upon when subscribing.&nbsp;
              </span>
            </p>
            <p style={{ textIndent: '18.0000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                If you do not want your subscription to renew automatically, or
                if you want to change or terminate your subscription, you will
                need to log in to your third-party account and cancel your
                subscription, even if you have otherwise deleted your
                &quot;KidFi&quot; account or if you removed the
                &quot;KidFi&quot; app from your device. Deleting your
                &quot;KidFi&quot; account or deleting the &quot;KidFi&quot; app
                from your device does not cancel your subscription;
                &quot;KidFi&quot; will retain all funds debited from your
                Payment Method until you cancel your subscription to a
                third-party account, if applicable. If you cancel your
                subscription, you will be able to use it until the end of the
                current subscription period, and your subscription will not be
                renewed after the current period expires.
              </span>
            </p>
            <h1>
              <strong>
                <span style={{ fontSize: '19px' }}>
                  Purchase of goods and services in KidFi
                </span>
              </strong>
            </h1>
            <p style={{ textIndent: '13.5000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                &quot;KidFi&quot; may offer products and services for purchase
                (&quot;In-App Purchase&quot;) via Google Pay, ApplePay and/or
                other payment platforms authorized by &quot;KidFi&quot;. In the
                case of an in-app purchase, you will be asked to confirm the
                purchase with the relevant payment service provider and select a
                payment method (using your card or a third-party account, for
                example, Google Pay, ApplePay) (&quot;Payment Method&quot;). You
                may be charged an additional fee in the form of taxes.
              </span>
            </p>
            <h1>
              <strong>
                <span style={{ fontSize: '19px' }}>Third-party services</span>
              </strong>
            </h1>
            <p style={{ textIndent: '18.0000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                The Application may contain advertisements and promotions
                offered by third parties, as well as links to other websites or
                resources. &quot;KidFi&quot; is not responsible for the
                availability or lack of availability of such external websites
                or resources. If you decide to interact with third parties
                available through our Application, the terms of such a
                relationship will be governed by a different agreement between
                you and them. &quot;KidFi&quot; is not responsible for the
                conditions or actions of such third parties.
              </span>
            </p>
            <h1>
              <strong>
                <span style={{ fontSize: '19px' }}>
                  Disclaimer of warranties
                </span>
              </strong>
            </h1>
            <p style={{ textIndent: '18.0000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                You expressly agree that you accept responsibility for the use
                of the Application, and use the Application solely at your own
                risk. We expressly disclaim all warranties of any kind, express
                or implied, including, but not limited to, any warranties of
                merchantability, fitness for a particular use or purpose,
                non-infringement, operability, condition, value, data accuracy,
                and system integration. &quot;KidFi&quot; does not represent or
                guarantee that the Application will be uninterrupted, safe, or
                error-free, does not guarantee that any defects or errors in the
                Application will be corrected, or that any information received
                through the Application will be accurate.
              </span>
            </p>
            <h1>
              <strong>
                <span style={{ fontSize: '19px' }}>
                  Limitation of liability
                </span>
              </strong>
            </h1>
            <p style={{ textIndent: '18.0000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                You expressly agree that to the extent permitted by the
                applicable legislation of the Republic of Azerbaijan, under no
                circumstances will &quot;KidFi&quot; or its officers, employees,
                directors, subsidiaries, affiliates, agents, or licensors be
                liable for any indirect, incidental, or other damages,
                including, but not limited to, losses resulting from loss of
                profit, data use, damage to business reputation or other
                intangible losses arising from or related to your use of the
                Application or Services, regardless of, whether such damages are
                based on contract, tort, warranty, law or otherwise. To the
                extent permitted under applicable legislation, to waive any
                implied warranties or to limit the obligations, the scope, and
                duration of such warranty, as well as the extent of
                &quot;KidFi&quot;`s liability, will be the minimum permitted by
                such applicable legislation.
              </span>
            </p>
            <h1>
              <strong>
                <span style={{ fontSize: '19px' }}>Compensation</span>
              </strong>
            </h1>
            <p style={{ textIndent: '18.0000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                You expressly agree to indemnify, defend and ensure the safety
                of &quot;KidFi&quot;, its officers, directors, employees,
                agents, subsidiaries, affiliates, licensors, and suppliers from
                any claims, actions, demands, obligations, and settlements,
                including, but not limited to, reasonable legal and accounting
                fees (&quot;Claims&quot;), arising as a result of or allegedly
                arising as a result of your violation of these terms and
                conditions.
              </span>
            </p>
            <h1>
              <strong>
                <span style={{ fontSize: '19px' }}>Applicable legislation</span>
              </strong>
            </h1>
            <p style={{ textIndent: '18.0000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                For all disputes, misunderstandings, and/or disagreements
                related to the existence or interpretation of this Agreement
                (the &quot;Dispute&quot;) the parties first try to resolve the
                issue through peaceful negotiations among themselves. If the
                parties do not reach an agreement in connection with the
                dispute, the issue is resolved by the relevant courts of the
                Republic of Azerbaijan and the court`s decision will be final
                and binding on the parties. The parties fully accept the powers
                of the courts of the Republic of Azerbaijan.&nbsp;
              </span>
            </p>
            <h1>
              <strong>
                <span style={{ fontSize: '19px' }}>Other</span>
              </strong>
            </h1>
            <p style={{ textIndent: '18.0000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                If any provision of this Agreement is found to be invalid, the
                remainder of the Agreement will remain in full force and effect.
                The failure of &quot;KidFi&quot; to exercise or enforce any
                right or provision of this Agreement does not constitute a
                waiver of such right or provision. You agree that your
                &quot;KidFi&quot; account is non-transferable and all your
                rights to your account and its Content terminate upon your
                death. No agency, partnership, joint venture, fiduciary, or
                other special relationship or employment relationship is created
                as a result of this Agreement, and you may not make any
                statements on behalf of or in any way associated with
                &quot;KidFi&quot;.
              </span>
            </p>
            <h1>
              <strong>
                <span style={{ fontSize: '19px' }}>To contact us</span>
              </strong>
            </h1>
            <p style={{ textIndent: '18.0000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                In case you have any recommendations or questions about this
                Agreement or any other matters, you can direct them to us
                via&nbsp;
              </span>
              <a href="mailto:info@kidfi.co">
                <strong>
                  <u>
                    <span
                      style={{
                        color: 'rgb(5,99,193)',
                        textDecoration: 'underline',
                        fontSize: '16px',
                      }}
                    >
                      info@kidfi.co
                    </span>
                  </u>
                </strong>
              </a>
              <strong>
                <span
                  style={{
                    color: 'rgb(0,0,0)',
                    fontSize: '16px',
                  }}
                >
                  .
                </span>
              </strong>
            </p>
            <p
              style={{
                marginTop: '5.0000pt',
                marginBottom: '5.0000pt',
                lineHeight: '114%',
              }}
            >
              <strong>
                <span
                  style={{
                    lineHeight: '114%',
                    color: 'rgb(0,0,0)',
                    fontSize: '16px',
                  }}
                >
                  “KIDFI” LLC
                </span>
              </strong>
            </p>
            <p
              style={{
                marginTop: '5.0000pt',
                marginBottom: '5.0000pt',
                textAlign: 'justify',
                lineHeight: '114%',
              }}
            >
              <strong>
                <span
                  style={{
                    lineHeight: '114%',
                    color: 'rgb(0,0,0)',
                    fontSize: '16px',
                  }}
                >
                  1405934651, Baku city, Nasimi district, A.Huseynov street,
                </span>
              </strong>
              <strong>
                <span
                  style={{
                    lineHeight: '114%',
                    color: 'rgb(0,0,0)',
                    fontWeight: 'bold',
                    fontSize: '16px',
                  }}
                >
                  &nbsp;5/7, 454
                </span>
              </strong>
            </p>
            <p
              style={{
                marginTop: '5.0000pt',
                marginBottom: '5.0000pt',
                textAlign: 'justify',
                lineHeight: '114%',
              }}
            >
              <strong>
                <span
                  style={{
                    lineHeight: '114%',
                    color: 'rgb(0,0,0)',
                    fontSize: '16px',
                  }}
                >
                  Last updated: August 2022
                </span>
              </strong>
            </p>
          </>
        ) : lang === 'ru' ? (
          <>
            <p style={{ textAlign: 'center' }}>
              <strong>
                <span style={{ fontSize: '19px' }}>ПРАВИЛА ПОЛЬЗОВАНИЯ</span>
              </strong>
            </p>
            <p style={{ textAlign: 'justify' }}>
              <strong>
                <span style={{ fontSize: '19px' }}>Управляется ООО «</span>
              </strong>
              <strong>
                <span
                  style={{
                    color: 'rgb(0,0,0)',
                    fontSize: '19px',
                  }}
                >
                  KIDFI
                </span>
              </strong>
              <strong>
                <span style={{ fontSize: '19px' }}>»</span>
              </strong>
            </p>
            <h1>
              <strong>
                <span style={{ fontSize: '19px' }}>О нас</span>
              </strong>
            </h1>
            <p style={{ textIndent: '18.0000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                «KidFi» («мы», «нас» или «наш»), оператором которого
                является&nbsp;
              </span>
              <strong>
                <span style={{ fontSize: '16px' }}>ООО «KIDFI»</span>
              </strong>
              <span style={{ fontSize: '16px' }}>
                , юридическое лицо, зарегистрированное в Азербайджанской
                Республике, №1405934651, представляет собой мобильное
                программное приложение («Приложение»), которые можно загрузить и
                установить на ваше мобильное устройство или планшетный компьютер
                (совместно именуемые «Устройство»).
              </span>
            </p>
            <h1>
              <strong>
                <span style={{ fontSize: '19px' }}>О приложении</span>
              </strong>
            </h1>
            <p style={{ textIndent: '18.0000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                &nbsp;«KidFi» - это онлайн-приложение для Ребенка (Детей) и
                Родителя(-ей) (совместно именуемые «Пользователь»), которое
                предоставляет Ребенку (Детям) базовые знания в области финансов,
                заработка и их использования на основе выполнение задач,
                указанных в Приложении.
              </span>
            </p>
            <p style={{ textIndent: '18.0000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                «KidFi» не является поставщиком образовательных услуг или
                учебным заведением.
              </span>
            </p>
            <p style={{ textIndent: '18.0000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                Используя Приложение, вы соглашаетесь соблюдать правила,
                изложенные в настоящих Правилах пользования, а также нашу
                Политику конфиденциальности и любую информацию, раскрытую вам,
                если вы приобрели дополнительные функции, продукты или услуги,
                которые мы предлагаем в Приложении («Соглашение»). Вам будет
                предложено принять эти условия при регистрации в Приложении и
                при использовании наших услуг. Вам не разрешается использовать
                какие-либо из наших услуг, если вы не согласны соблюдать
                настоящие Условия.
              </span>
            </p>
            <p style={{ textIndent: '18.0000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                Настоящее Соглашение и Приложение могут время от времени
                изменяться или дополняться для установления новых функций или в
                связи с изменениями в деловой практике, а также для обеспечения
                соответствия изменениям и требованиям национального
                законодательства и международных стандартов. Версия, с которой
                вы будете иметь дело, является последней и размещена в
                Приложении в разделе «Настройки», а также на сайте
                https://www.kidfi.co/.
              </span>
            </p>
            <p style={{ textIndent: '18.0000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                Изменения или дополнения вносятся без предварительного
                уведомления Пользователя, за исключением случаев, когда
                изменения являются существенными, затрагивающими права и
                обязанности Пользователя. В этом случае мы заранее уведомим вас
                об изменениях разумными способами, которые могут включать
                уведомление через Приложение или по адресу электронной почты,
                указанному при регистрации в Приложении. Если вы продолжаете
                использовать Приложение после вступления в силу изменений и
                дополнений, считается, что вы согласились с новой редакцией
                Соглашения.
              </span>
            </p>
            <p style={{ textIndent: '18.0000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                Вы соглашаетесь с тем, что настоящее Соглашение заменяет все
                предыдущие соглашения (за исключением случаев, специально
                оговоренных в этом документе) и регулирует все ваши отношения с
                «KidFi», включая любые действия и соглашения, предшествовавшие
                принятию вами настоящего Соглашения.
              </span>
            </p>
            <h1>
              <strong>
                <span style={{ fontSize: '19px' }}>Что нам нужно от вас?</span>
              </strong>
            </h1>
            <p style={{ textIndent: '18.0000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                Для создания родительской учетной записи в «KidFi» и
                использования Приложения вам должно быть не менее 18 лет. Все
                учетные записи дочернего типа (учетная запись Ребенка (детей))
                создаются Родителем (Родителями), и все действия Ребенка (Детей)
                контролируются и утверждаются Родителем (Родителями). Как
                Родитель, вы заявляете и гарантируете, что:
              </span>
            </p>
            <ul style={{ listStyleType: 'undefined', marginLeft: '26px' }}>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  •&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  Вы можете заключить обязательный договор с «KidFi»;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  •&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  Вы не являетесь лицом, которому запрещено использовать
                  Приложение в соответствии с действующим законодательством;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  •&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  Вы имеете право создать учетную запись для Ребенка (Детей) в
                  соответствии с действующим законодательством;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  •&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  Вы соглашаетесь соблюдать настоящее Соглашение и все
                  применимые национальные и международные законы, правила и
                  положения;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  •&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  Вы предоставили реальные данные о себе, Ребенке (Детях) и
                  своем праве на создание учетной записи для себя и Ребенка
                  (Детей).
                </span>
              </li>
            </ul>
            <h1>
              <strong>
                <span style={{ fontSize: '19px' }}>
                  Как мы используем вашу личную информацию
                </span>
              </strong>
            </h1>
            <p style={{ textIndent: '18.0000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                Мы используем только личную информацию, которую вы нам
                предоставили, в соответствии с нашей Политикой
                конфиденциальности. Вы несете ответственность за сохранение
                конфиденциальности учетных данных, которые вы используете для
                регистрации в «KidFi», и несете единоличную ответственность за
                все действия, которые происходят с использованием этих учетных
                данных. Если вы считаете, что кто-то получил доступ к вашей
                учетной записи, немедленно свяжитесь с нами через раздел
                настоящего Соглашения «Связаться с нами». Для получения
                дополнительной информации ознакомьтесь с нашей Политикой
                конфиденциальности.
              </span>
            </p>
            <h1>
              <strong>
                <span style={{ fontSize: '19px' }}>
                  Прекращение действия учетной записи
                </span>
              </strong>
            </h1>
            <p style={{ textIndent: '18.0000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                Вы можете прекратить действие своей учетной записи и/или учетной
                записи вашего ребенка (детей) в любое время и по любой причине
                без предварительного уведомления нас. «KidFi» может закрыть вашу
                учетную запись или учетную запись ребенка (детей) в любое время
                без предварительного уведомления, если сочтет, что вы или
                ребенок (дети) нарушили настоящее Соглашение. После прекращения
                действия вашей учетной записи или учетной записи ребенка (детей)
                действие настоящего Соглашения прекращается.
              </span>
            </p>
            <h1>
              <strong>
                <span style={{ fontSize: '19px' }}>
                  Безопасность и ограничение ответственности
                </span>
              </strong>
            </h1>
            <p style={{ textIndent: '18.0000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                Целью «KidFi» является предоставление финансового образования
                Ребенку (Детям), и «KidFi» не несет ответственности за поведение
                любого Пользователя в Приложении или вне его. Вы соглашаетесь
                соблюдать осторожность во всех взаимодействиях с Ребенком
                (Детьми), особенно если вы решите запросить карту для
                использования вашим Ребенком (Детьми). Вы должны соблюдать все
                применимые национальные и международные правила защиты детей. Вы
                несете единоличную ответственность за свое взаимодействие с
                вашим Ребенком (Детьми), их обучение в этом приложении, расходы
                и покупки.
              </span>
            </p>
            <h1>
              <strong>
                <span style={{ fontSize: '19px' }}>
                  Обязанности Родителя (Родителей)
                </span>
              </strong>
            </h1>
            <p style={{ textIndent: '18.0000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                &quot;KidFi&quot; предоставляет вам личное, не подлежащее
                передаче право на доступ и использование Приложения. Это право
                предоставляется вам для использования преимуществ Приложения,
                как это предусмотрено и разрешено настоящим Соглашением. Таким
                образом, вы соглашаетесь:
              </span>
            </p>
            <ul style={{ listStyleType: 'undefined', marginLeft: '26px' }}>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  •&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  Не использовать Приложение или любой контент, содержащийся в
                  Приложении, в каких-либо коммерческих целях без нашего
                  письменного согласия, а также не копировать, изменять,
                  передавать, создавать какие-либо производные работы,
                  использовать или воспроизводить каким-либо образом любые
                  защищенные авторским правом материалы, изображения, товарные
                  знаки, торговые наименования, знаки обслуживания или другие
                  виды интеллектуальной собственности, контент или
                  конфиденциальную информацию, доступную через Приложение без
                  предварительного письменного согласия &quot;KidFi&quot;;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  •&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  Не использовать никаких роботов, ботов или приложения для
                  поиска в Приложении для доступа, извлечения, индексации или
                  &quot;интеллектуального анализа&quot; данных;&nbsp;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  •&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  Не использовать Приложение каким-либо образом, который может
                  помешать, нарушить или негативно повлиять на работу
                  Приложения;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  •&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  Не загружать вирусы или другой вредоносный код или иным
                  образом ставить под угрозу безопасность Приложения;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  •&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  Не использовать любые средства, содержащие какие-либо ссылки
                  на &quot;KidFi&quot; (или любой товарный знак, торговое
                  название, логотип или слоган &quot;KidFi&quot;), чтобы
                  перенаправить кого-либо на другие веб-сайты в любых целях;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  •&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  Не изменять, адаптировать, переводить, продавать,
                  перепроектировать, расшифровывать, декомпилировать или иным
                  образом разбирать любую часть Приложения или заставлять других
                  делать это;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  •&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  Не использовать, не получать доступ и не публиковать
                  программный интерфейс Приложения без нашего письменного
                  согласия;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  •&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  Не исследовать, не сканировать и не тестировать уязвимость
                  Приложения;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  •&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  Не поощрять и не продвигать какую-либо деятельность,
                  нарушающую настоящее Соглашение.
                </span>
              </li>
            </ul>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>Кроме того:</span>
            </p>
            <ul style={{ listStyleType: 'undefined', marginLeft: '26px' }}>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  •&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  Не использовать Приложение для любых целей, которые являются
                  незаконными или запрещены настоящим Соглашением;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  •&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  Не использовать Приложение в каких-либо злонамеренных или
                  неблаговидных целях, особенно с точки зрения раздела
                  национальных или международных законов о защите прав детей;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  •&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  Не использовать Приложение для нанесения ущерба
                  &quot;KidFi&quot;;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  •&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  Не нарушать нашу Политику конфиденциальности, которая время от
                  времени обновляется;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  •&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  Не рассылать спам, не вымогать деньги и не обманывать
                  Пользователей;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  •&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  Не публиковать любой контент, который нарушает чьи-либо права,
                  включая права на публичность, неприкосновенность частной
                  жизни, авторские права, товарные знаки или другие права
                  интеллектуальной собственности или договорные права;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  •&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  Не размещать любой контент, который разжигает ненависть,
                  содержит угрозы, является откровенно сексуальным или
                  порнографическим, подстрекает к насилию или содержит
                  графическое или неоправданное насилие;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  •&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  Не размещать какой-либо контент, пропагандирующий расизм,
                  фанатизм, ненависть или физический вред любого рода в
                  отношении какой-либо группы или отдельного лица;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  •&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  Не запрашивать пароль для каких-либо целей или личную
                  информацию в коммерческих или незаконных целях у других
                  Пользователей или распространять личную информацию другого
                  лица без его или ее разрешения;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  •&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  Не использовать учетную запись другого пользователя, совместно
                  использовать учетную запись с другими Пользователями или
                  поддерживать более одной учетной записи;
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  •&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  Не использовать ложную информацию о вашей личности, личности
                  Ребенка (Детей) и вашей правоспособности для создания учетной
                  записи для Ребенка (Детей);
                </span>
              </li>
              <li>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  •&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12.0000pt',
                  }}
                >
                  Не нарушать какое-либо применимое налоговое законодательство
                  или законодательство о борьбе с отмыванием денег.
                </span>
              </li>
            </ul>
            <p style={{ textIndent: '18.0000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                «KidFi» может расследовать и предпринять любые доступные
                правовые меры в ответ на незаконное и/или несанкционированное
                использование Приложения, включая закрытие учетной записи
                нарушителя. «KidFi» также оставляет за собой право уведомить
                власти и потребовать возмещения ущерба в полном объеме, если
                ваше нарушение нанесло ущерб «KidFi» или его Пользователям.
              </span>
            </p>
            <h1>
              <strong>
                <span style={{ fontSize: '19px' }}>
                  Права и обязанности «KidFi»
                </span>
              </strong>
            </h1>
            <p style={{ textIndent: '18.0000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                Создавая учетную запись, вы предоставляете «KidFi» передаваемое
                безвозмездное право размещать, хранить, использовать,
                копировать, отображать, воспроизводить, адаптировать,
                редактировать, публиковать, изменять и распространять
                предоставленную информацию, а также любую информацию, которую вы
                публикуете, загружаете, показываете или иным образом
                предоставляете (совместно именуемый «Публикация») в Приложении
                или передавать другим Пользователям (совместно именуемый
                «Контент»).
              </span>
            </p>
            <p style={{ textIndent: '18.0000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                Для того, чтобы «KidFi» мог предотвратить использование Контента
                за пределами Приложения, вы разрешаете «KidFi» действовать от
                вашего имени в отношении использования вашего Контента, взятого
                из Приложения третьими лицами в нарушение авторских прав. Наше
                право на ваш Контент взаимно регулируется вашими правами в
                соответствии с применимым законодательством (например, законами
                о защите персональных данных в той мере, в какой любой Контент
                содержит личную информацию, определенную этими законами) и
                предназначен для ограниченных целей эксплуатации, разработки,
                предоставления и улучшения.&nbsp;
              </span>
            </p>
            <p style={{ textIndent: '18.0000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                Вы соглашаетесь с тем, что вся информация, которую вы
                предоставляете при создании своей учетной записи, является
                точной и надежной, и вы имеете право размещать Контент в
                Приложении.
              </span>
            </p>
            <p style={{ textIndent: '18.0000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                Вы понимаете и соглашаетесь с тем, что мы можем отслеживать или
                проверять любой контент, который вы размещаете в Приложении. Мы
                можем полностью или частично удалить любой Контент, который, по
                нашему мнению, нарушает настоящее Соглашение или может нанести
                ущерб репутации «KidFi».
              </span>
            </p>
            <p style={{ textIndent: '18.0000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                Вы соглашаетесь с тем, что мы, наши аффилированные лица и наши
                сторонние партнеры можем размещать рекламу в Приложении.
                Отправляя предложения или отзывы о нашем Приложении, вы
                соглашаетесь с тем, что «KidFi» может использовать и делиться
                такими отзывами в любых целях без выплаты вам компенсации.
              </span>
            </p>
            <p style={{ textIndent: '18.0000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                Вы соглашаетесь с тем, что «KidFi» может получать доступ,
                сохранять и раскрывать информацию и Контент вашей учетной
                записи, если этого требует закон или добросовестно полагая, что
                такой доступ, хранение или раскрытие разумно необходимы,
                например, для (а) соблюдения судебного процесса; (б) обеспечения
                соблюдения настоящего Соглашения; (c) реагирование на заявления
                о том, что какой-либо Контент нарушает права третьих лиц; (d)
                реагирование на ваши запросы в службу поддержки клиентов; (e)
                защиты прав или личной безопасности «KidFi» или любого другого
                лица.
              </span>
            </p>
            <h1>
              <strong>
                <span style={{ fontSize: '19px' }}>
                  Платежи, подписка и автоматическое обновление KidFi
                </span>
              </strong>
            </h1>
            <p style={{ textIndent: '18.0000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                Вы будете выполнять свои обязательства по оплате услуг,
                приобретенных через Приложение. Для периодических платежей,
                применимых к вашей подписке, вы соглашаетесь с тем, что третьи
                стороны, работающие от нашего имени («Провайдер»), могут хранить
                вашу платежную информацию для обработки таких платежей.
                Провайдер имеет право по своему усмотрению изменять цены на свои
                услуги в любое время. Вы также разрешаете Провайдеру через таких
                сторонних поставщиков использовать ваш Способ оплаты в файле для
                обработки ваших периодических платежей за подписку,
                произведенных вами через Приложение. Провайдер не гарантирует
                возврат средств. Вы также соглашаетесь оплачивать
                соответствующие сборы за услуги, которые вы заказываете
                (включая, помимо прочего, периодические сборы за ежемесячную или
                годовую подписку или любые дополнительные административные
                сборы, которые могут быть применены) по мере их уплаты, плюс все
                соответствующие налоги, а также возмещать нам все расходы по
                сбору платежей и проценты за любые просроченные суммы.
              </span>
            </p>
            <p style={{ textIndent: '18.0000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                Если срок действия Способа оплаты, который вы используете,
                например кредитной карты, истекает, и вы не редактируете
                информацию о способе оплаты или не отменяете свою Учетную запись
                или соответствующую услугу, вы разрешаете нам продолжать
                выставлять счета этим способом оплаты, и вы остаетесь
                ответственным за любые неполученные суммы. Ваше обязательство по
                уплате сборов сохраняется в течение всего периода подписки до
                тех пор, пока вы не отмените свою подписку. Все применимые
                налоги рассчитываются на основе платежной информации, которую вы
                предоставляете нам во время покупки.
              </span>
            </p>
            <p style={{ textIndent: '18.0000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                Если вы приобретаете повторяющуюся подписку с автоматическим
                продлением, счет за подписку будет продолжать списываться с
                вашего способа оплаты до тех пор, пока вы не отмените подписку.
                После первоначального периода подписки и после любого
                последующего периода подписки ваша подписка будет автоматически
                продлена на эквивалентный дополнительный период по цене, о
                которой вы договорились при подписке.&nbsp;
              </span>
            </p>
            <p style={{ textIndent: '18.0000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                Если вы не хотите, чтобы ваша подписка продлевалась
                автоматически, или если вы хотите изменить или прекратить
                подписку, вам необходимо будет войти в свою стороннюю учетную
                запись и отменить подписку, даже если вы иным образом удалили
                свою учетную запись «KidFi» или если вы удалили приложение
                «KidFi» со своего устройства. Удаление вашей учетной записи
                «KidFi» или удаление приложения «KidFi» с вашего устройства не
                отменяет вашу подписку; «KidFi» сохранит все средства, списанные
                с вашего платежного метода, до тех пор, пока вы не отмените
                подписку на стороннюю учетную запись, если это применимо. Если
                вы отмените свою подписку, вы сможете использовать ее до
                окончания текущего периода подписки, и ваша подписка не будет
                продлена после истечения текущего периода.
              </span>
            </p>
            <h1>
              <strong>
                <span style={{ fontSize: '19px' }}>
                  Покупка товаров и услуг в KidFi
                </span>
              </strong>
            </h1>
            <p style={{ textIndent: '18.0000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                «KidFi» может предлагать товары и услуги для покупки («Покупка в
                приложении») через Google Pay,&nbsp;
              </span>
              <span style={{ fontSize: '16px' }}>ApplePay</span>
              <span style={{ fontSize: '16px' }}>
                &nbsp;и/или другие платежные платформы, авторизованные «KidFi».
                В случае осуществления Покупки в приложении, вам будет
                предложено подтвердить покупку у соответствующего поставщика
                платежных услуг и выбрать способ оплаты (с помощью вашей карты
                или стороннего аккаунта, например, Google Pay, ApplePay)
                («Способ оплаты»). С вас может взиматься дополнительная плата в
                виде налогов.
              </span>
            </p>
            <h1>
              <strong>
                <span style={{ fontSize: '19px' }}>Сторонние сервисы</span>
              </strong>
            </h1>
            <p style={{ textIndent: '18.0000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                Приложение может содержать рекламу и рекламные акции,
                предлагаемые третьими лицами, а также ссылки на другие веб-сайты
                или ресурсы. «KidFi» не несет ответственности за доступность или
                отсутствие доступности таких внешних веб-сайтов или ресурсов.
                Если вы решите взаимодействовать с третьими лицами, доступными
                через наше Приложение, условия таких отношений будут
                регулироваться другим соглашением между вами. «KidFi» не несет
                ответственности за условия или действия таких третьих лиц.
              </span>
            </p>
            <h1>
              <strong>
                <span style={{ fontSize: '19px' }}>Отказ от гарантий</span>
              </strong>
            </h1>
            <p style={{ textIndent: '18.0000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                Вы прямо соглашаетесь с тем, что принимаете на себя
                ответственность за использование Приложения и используете
                Приложение исключительно на свой страх и риск. Мы категорически
                отказываемся от всех гарантий любого рода, явных или
                подразумеваемых, включая, но не ограничиваясь, любыми гарантиями
                товарной пригодности, пригодности для конкретного использования
                или цели, ненарушения прав, работоспособности, состояния,
                ценности, точности данных и системной интеграции. «KidFi» не
                представляет и не гарантирует, что Приложение будет
                бесперебойным, безопасным или безошибочным, не гарантирует, что
                любые дефекты или ошибки в Приложении будут исправлены или что
                любая информация, полученная через Приложение, будет точной.
              </span>
            </p>
            <h1>
              <strong>
                <span style={{ fontSize: '19px' }}>
                  Ограничение ответственности
                </span>
              </strong>
            </h1>
            <p style={{ textIndent: '18.0000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                Вы прямо соглашаетесь с тем, что в пределах, разрешенных
                применимым законодательством Азербайджанской Республики, ни при
                каких обстоятельствах «KidFi» или ее должностные лица,
                сотрудники, директора, дочерние компании, филиалы, агенты или
                лицензиары не несут ответственности за любой косвенный,
                случайный или иной ущерб, включая, но не ограничиваясь,
                убытками, возникающими в результате упущенной выгоды,
                использования данных, ущерба деловой репутации или других
                нематериальных убытков, возникающих в результате или связанных с
                использованием вами Приложения или Услуг, независимо от того,
                основаны ли такие убытки на контракте, деликте, гарантии, законе
                или иным образом. В пределах, разрешенных применимым
                законодательством, отказ от любых подразумеваемых гарантий или
                ограничение обязательств, объема и продолжительности такой
                гарантии, а также степени ответственности «KidFi» будет
                минимальным, разрешенным таким применимым законодательством.
              </span>
            </p>
            <h1>
              <strong>
                <span style={{ fontSize: '19px' }}>Компенсация</span>
              </strong>
            </h1>
            <p style={{ textIndent: '18.0000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                Вы прямо соглашаетесь возмещать убытки, защищать и обеспечивать
                безопасность «KidFi», ее должностных лиц, директоров,
                сотрудников, агентов, дочерних компаний, филиалов, лицензиаров и
                поставщиков от любых претензий, действий, требований,
                обязательств и расчетов, включая, но не ограничиваясь, разумные
                юридические и бухгалтерские сборы («Претензии»), возникающие в
                результате или предположительно возникающие в результате
                нарушения вами настоящих Правил.
              </span>
            </p>
            <h1>
              <strong>
                <span style={{ fontSize: '19px' }}>
                  Применимое законодательство
                </span>
              </strong>
            </h1>
            <p style={{ textIndent: '18.0000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                По всем спорам, недоразумениям и/или разногласиям, связанным с
                существованием или толкованием настоящего Соглашения («Спор»),
                стороны сначала пытаются разрешить проблему путем мирных
                переговоров между собой. Если стороны не достигнут соглашения в
                связи со спором, вопрос решается соответствующими судами
                Азербайджанской Республики, и решение суда будет окончательным и
                обязательным для сторон. Стороны полностью признают полномочия
                судов Азербайджанской Республики.
              </span>
            </p>
            <h1>
              <strong>
                <span style={{ fontSize: '19px' }}>Другое</span>
              </strong>
            </h1>
            <p style={{ textIndent: '18.0000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                Если какое-либо положение настоящего Соглашения будет признано
                недействительным, остальная часть Соглашения сохраняет свою
                действительность. Неспособность «KidFi» реализовать или привести
                в исполнение какое-либо право или положение настоящего
                Соглашения не означает отказа от такого права или положения. Вы
                соглашаетесь с тем, что ваша учетная запись «KidFi» не подлежит
                передаче, и все ваши права на вашу учетную запись и ее
                содержимое прекращаются после вашей смерти. Никакое агентство,
                партнерство, совместное предприятие, доверенное лицо или другие
                особые отношения или трудовые отношения не создаются в
                результате настоящего Соглашения, и вы не имеете права делать
                какие-либо заявления от имени или каким-либо образом связанные с
                «KidFi».
              </span>
            </p>
            <h1>
              <strong>
                <span style={{ fontSize: '19px' }}>Для связи с нами</span>
              </strong>
            </h1>
            <p style={{ textIndent: '18.0000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>
                В случае, если у вас есть какие-либо рекомендации или вопросы по
                данному Соглашению или любым другим вопросам, вы можете
                направить их нам по адресу&nbsp;
              </span>
              <a href="mailto:info@kidfi.co">
                <strong>
                  <u>
                    <span
                      style={{
                        color: 'rgb(5,99,193)',
                        textDecoration: 'underline',
                        fontSize: '16px',
                      }}
                    >
                      info@kidfi.co
                    </span>
                  </u>
                </strong>
              </a>
              <strong>
                <u>
                  <span
                    style={{
                      color: 'rgb(5,99,193)',
                      textDecoration: 'underline',
                      fontSize: '16px',
                    }}
                  >
                    ,
                  </span>
                </u>
              </strong>
            </p>
            <p style={{ textIndent: '18.0000pt', textAlign: 'justify' }}>
              <span style={{ fontSize: '16px' }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: 'justify' }}>
              <strong>
                <span style={{ fontSize: '16px' }}>ООО «</span>
              </strong>
              <strong>
                <span
                  style={{
                    color: 'rgb(0,0,0)',
                    fontSize: '19px',
                  }}
                >
                  KIDFI
                </span>
              </strong>
              <strong>
                <span
                  style={{
                    color: 'rgb(0,0,0)',
                    fontSize: '19px',
                  }}
                >
                  »
                </span>
              </strong>
            </p>
            <p style={{ textAlign: 'justify' }}>
              <strong>
                <span style={{ fontSize: '16px' }}>
                  1405934651, город Баку, Насиминский район, улица А.Гусейнова,
                  5/7, 454
                </span>
              </strong>
            </p>
            <p style={{ textAlign: 'justify' }}>
              <strong>
                <span style={{ fontSize: '16px' }}>
                  Последнее обновление: август 2022
                </span>
              </strong>
            </p>
          </>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};
