export const az = {
  copyright: 'Copyright © 2021 %KIDFI%. Bütün hüquqlar qorunur.',
  privacyPolicy: 'Məxfilik siyasəti',
  termsOfUse: 'İstifadəçi qaydaları',
};
export const en = {
  copyright: 'Copyright © 2021 %KIDFI%. All rights reserved.',
  privacyPolicy: 'Privacy Policy',
  termsOfUse: 'Terms of Use',
};
export const ru = {
  copyright: 'Copyright © 2021 %KIDFI%. Все права защищены.',
  privacyPolicy: 'Политика конфиденциальности',
  termsOfUse: 'Правила пользования',
};
