import React from 'react';
import AboutImage from 'assets/images/about_api.png';
import { getData } from './data';
import './About.css';

interface IProps {
  id: string;
  lang: string;
}

export const About: React.FC<IProps> = ({ id, lang }) => {
  const data = getData(lang);

  return (
    <>
      <div className="about" id={id}>
        <div className="container">
          <div className="d-flex justify-content-between about-row about-api">
            <div className="about-left">
              <img src={AboutImage} alt="" />
            </div>
            <div className="about-right about-text">
              <h2 className="title-primary">
                {data.title.split('%')[0]}
                <strong>{data.title.split('%')[1]}</strong>
                {data.title.split('%')[2]}
              </h2>
              <p className="text-primary">{data.description}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
