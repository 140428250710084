export const az = {
  testYourself: 'Necə işləyir?',
  about: 'Məhsul',
  pricing: 'Qiymət',
  contact: 'Əlaqə',
  partners: 'Tərəfdaşlar',
  api: 'API',
};
export const en = {
  testYourself: 'How it works?',
  about: 'Product',
  pricing: 'Pricing',
  contact: 'Contact',
  partners: 'Partners',
  api: 'API',
};
export const ru = {
  testYourself: 'Как это работает?',
  about: 'Продукт',
  pricing: 'Цена',
  contact: 'Контакт',
  partners: 'Партнеры',
  api: 'API',
};
