import React from 'react';
import './Privacy.css';
import { Welcome } from './components/welcome/Welcome';
import { ContactForm } from './components/contactForm/ContactForm';
import { About } from './components/about/About';

interface IProps {
  lang: string;
}

export const API: React.FC<IProps> = ({ lang }) => {
  return (
    <>
      <Welcome lang={lang} />
      <About lang={lang} id="about" />
      <ContactForm lang={lang} id="contact" />
    </>
  );
};
