import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { ButtonType, Button } from 'components/button/Button';
import APIImage from 'assets/images/api.png';
import './Welcome.css';
import { getData } from './data';

interface IProps {
  lang: string;
}

export const Welcome: React.FC<IProps> = ({ lang }) => {
  const scrollToElement = (element: any) => {
    element.scrollIntoView({
      behavior: 'smooth',
    });
  };

  const data = getData(lang);

  return (
    <>
      <div className="welcome" style={{ background: '#F6E4FD' }}>
        <div className="container">
          <div className="d-flex justify-content-between">
            <div
              className="welcome-left"
              style={{
                maxWidth:
                  lang == 'az' ? '593px' : lang == 'ru' ? '620px' : '529px',
              }}
            >
              <h1
                className={` ${
                  lang == 'az'
                    ? 'title-primary-az'
                    : lang == 'ru'
                    ? 'title-primary-ru'
                    : ''
                } title-primary`}
              >
                {data.title.split('%')[0]}
                <strong>{data.title.split('%')[1]}</strong>
                {data.title.split('%')[2]}
              </h1>
              <p className="text-primary">{data.description}</p>
              <div className="d-flex">
                <Button
                  title={data.buttonTitle}
                  type={ButtonType.PRIMARY}
                  className="button-welcome"
                  href="https://open-api-pn4rq.ondigitalocean.app/open-api/swagger-ui/index.html#/"
                  isLink
                />
                <div
                  className="scrollToBottom"
                  onClick={() =>
                    scrollToElement(document.getElementById('about'))
                  }
                >
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    color="#B122EA"
                    fontSize={24}
                  />
                </div>
              </div>
            </div>
            <div className="welcome-right">
              <img src={APIImage} alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
