import React, { Component, FC } from 'react';
import Select, { StylesConfig } from 'react-select';
import './Select.css';

interface Option {
  value: string;
  label: string;
}
interface IProps {
  options?: Option[];
  defaultValue?: Option;
  primaryColor?: string;
  secondaryColor?: string;
  className?: string;
  handleChange?: (value: unknown) => void;
  placeholder?: string;
  fontSize?: string;
  lineHeight?: string;
}

export const ReactSelect: FC<IProps> = ({
  options,
  defaultValue,
  primaryColor,
  secondaryColor,
  className,
  handleChange,
  placeholder,
  fontSize,
  lineHeight,
}) => {
  const colourStyles: StylesConfig = {
    control: (styles, { isFocused }) => ({
      ...styles,
      backgroundColor: '#fff',
      boxShadow: isFocused ? 'unset' : '',
      fontSize: fontSize,
      lineHeight: lineHeight,
      color: secondaryColor,
    }),
    singleValue: (styles) => ({
      ...styles,
      color: primaryColor,
    }),
    indicatorSeparator: () => ({}),
    dropdownIndicator: (styles) => ({
      ...styles,
      color: primaryColor,
      paddingLeft: 'unset',
      paddingTop: 'unset',
      paddingBottom: 'unset',
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? primaryColor
          : isFocused
          ? secondaryColor
          : undefined,
        color: isDisabled
          ? '#ccc'
          : isSelected
          ? '#fff'
          : isFocused
          ? '#fff'
          : primaryColor,
        cursor: isDisabled ? 'not-allowed' : 'pointer',

        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled
            ? isSelected
              ? secondaryColor
              : primaryColor
            : undefined,
        },
      };
    },
  };
  return (
    <Select
      options={options}
      defaultValue={defaultValue}
      styles={colourStyles}
      className={`select-react ${className}`}
      isSearchable={false}
      onChange={handleChange}
      placeholder={placeholder}
    />
  );
};
