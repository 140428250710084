import React from 'react';
import { Card } from 'components/card/Card';

import './Pricing.css';
import { getData } from './data';
import { getLocaleData } from 'helpers/getLocaleData';
import { az, en, ru } from './lang';

interface IProps {
  id: string;
  lang: string;
}
export const Pricing: React.FC<IProps> = ({ id, lang }) => {
  const data = getData(lang);
  const baseLang = getLocaleData(lang, az, en, ru);
  return (
    <>
      <div className="pricing" id={id}>
        <div className="container">
          <h5 className="title-primary text-center">{baseLang.title}</h5>
          <div className="card-container">
            {data.map((packet, index) => (
              <Card
                key={index}
                title={packet.title}
                info={packet.info}
                price={packet.price}
                isActive={packet.isActive}
                items={packet.items}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
