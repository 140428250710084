import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { TestYourself } from './components/testYourself/TestYourself';
import { About } from './components/about/About';
import { AppInfo } from './components/appInfo/AppInfo';
import { Pricing } from './components/pricing/Pricing';
import { ContactForm } from './components/contactForm/ContactForm';
import { Partners } from './components/partners/Partners';

interface IProps {
  lang: string;
}

export const HomeView: React.FC<IProps> = ({ lang }) => {
  const { state } = useLocation();
  const { targetId } = (state || {}) as { targetId: string };

  useEffect(() => {
    const element = document.getElementById(targetId);
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [targetId]);

  return (
    <>
      <TestYourself id="testYourself" lang={lang} />
      <About id="about" lang={lang} />
      <AppInfo id="appInfo" lang={lang} />
      <Pricing id="pricing" lang={lang} />
      {/* <ContactForm id="contact" lang={lang} /> */}
      <Partners id="partners" lang={lang} />
    </>
  );
};
