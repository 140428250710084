import React from 'react';
import axios from 'axios';
import { Formik, Field, FormikHelpers, Form } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleCheck,
  faCircleExclamation,
} from '@fortawesome/free-solid-svg-icons';
import { ButtonType, Button } from 'components/button/Button';
import 'components/textField/TextField.css';
import { ModalPanel } from 'components/modal/Modal';
import FooterLogo from 'assets/images/footer_logo.svg';
import { getData } from './data';
import './ContactForm.css';

interface IProps {
  id: string;
  lang: string;
}

interface Values {
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  phone: string;
  company: string;
  message: string;
}

export const ContactForm: React.FC<IProps> = ({ id, lang }) => {
  const data = getData(lang);
  const [modalIsOpen, setModalOpen] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [isError, setIsError] = React.useState(false);

  function closeModal() {
    setModalOpen(false);
    setIsError(false);
    setMessage('');
  }
  const onPostContactForm = (values: Values) => {
    axios
      .post('https://shark-app-4wif8.ondigitalocean.app/kid-fi/guest/', {
        email: values.email,
        firstName: values.fullName.split(' ')[0],
        lastName: values.fullName.split(' ')[1],
        phone: values.phone,
        company: values.company,
        message: values.message,
      })
      .then(() => {
        setMessage('Successfully sent');
        setModalOpen(true);
      })
      .catch((error) => {
        setIsError(true);
        setMessage(error.response.data.errorDescription);
        setModalOpen(true);
      });
  };

  return (
    <Formik
      initialValues={{
        email: '',
        firstName: '',
        lastName: '',
        fullName: '',
        phone: '',
        company: '',
        message: '',
      }}
      onSubmit={(values: Values, { resetForm }: FormikHelpers<Values>) => {
        onPostContactForm(values);
        resetForm();
      }}
    >
      <Form>
        <div className="contactForm" id={id}>
          <div className="container">
            <div className="d-flex justify-content-between">
              <div className="contactForm-left">
                <img src={FooterLogo} alt="" />
                <h6
                  className={` ${
                    lang == 'az'
                      ? 'title-primary-az'
                      : lang == 'ru'
                      ? 'title-primary-ru'
                      : ''
                  } title-primary`}
                >
                  {data.title.split('%')[0]}
                  <strong>{data.title.split('%')[1]}</strong>
                  {data.title.split('%')[2]}
                </h6>
              </div>
              <div className="contactForm-right">
                <div className="d-flex">
                  <div>
                    <Field
                      placeholder={data.fullName}
                      name="fullName"
                      className="input-primary"
                    />
                    <Field
                      placeholder={data.email}
                      name="email"
                      className="input-primary"
                    />
                    <Field
                      placeholder={data.phoneNumber}
                      name="phone"
                      className="input-primary"
                    />
                  </div>
                  <div>
                    <Field
                      placeholder={data.company}
                      name="company"
                      className="input-primary"
                    />
                    <Field
                      as="textarea"
                      placeholder={data.message}
                      name="message"
                    />
                  </div>
                </div>
                <Button title={data.buttonTitle} type={ButtonType.PRIMARY} />
              </div>
            </div>
          </div>
        </div>
        <ModalPanel isOpen={modalIsOpen} closeModal={closeModal}>
          <div className="result d-flex align-items-center">
            {isError ? (
              <FontAwesomeIcon
                icon={faCircleExclamation}
                color="#DC3545"
                fontSize={32}
              />
            ) : (
              <FontAwesomeIcon
                icon={faCircleCheck}
                color="#198754"
                fontSize={32}
              />
            )}
            <h6>{message}</h6>
          </div>
        </ModalPanel>
      </Form>
    </Formik>
  );
};
