export const az = {
  title: 'API əldə etmək üçün %indi% əlaqə yaradın.',
  fullName: 'Adınız və soyadınız',
  email: 'E-poçt*',
  phoneNumber: 'Telefon nömrəniz*',
  company: 'Şirkətin adı*',
  message: 'Mesajınız',
  buttonTitle: 'Göndər',
};
export const en = {
  title: 'Contact to get APIs, %now.%',
  fullName: 'Name and surname',
  email: 'Email*',
  phoneNumber: 'Mobile number*',
  company: 'Company name*',
  message: 'Message',
  buttonTitle: 'Send',
};
export const ru = {
  title: 'Свяжитесь, чтобы получить API, прямо %сейчас.%',
  fullName: 'Имя и фамилия',
  email: 'Email*',
  phoneNumber: 'Номер мобильного телефона*',
  company: 'Название компании*',
  message: 'Сообщение',
  buttonTitle: 'Oтправить',
};
