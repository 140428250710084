import { getLocaleData } from 'helpers/getLocaleData';
import { az, en, ru } from './lang';

export const getData = (lang: string) => {
  const baseLang = getLocaleData(lang, az, en, ru);
  return {
    firstTitle: baseLang.firstTitle,
    firstDescription: baseLang.firstDescription,
    secondTitle: baseLang.secondTitle,
    secondDescription: baseLang.secondDescription,
  };
};
