import { CardInfo } from '@components/card/Card';
import { getLocaleData } from 'helpers/getLocaleData';
import { az, en, ru } from './lang';

export const getData = (lang: string) => {
  const baseLang = getLocaleData(lang, az, en, ru);
  const additionalData = [
    {
      isActive: false,
    },
    {
      isActive: false,
    },
    {
      isActive: false,
    },
  ];
  const data: CardInfo[] = baseLang.cardInfo.map((item: any, index: number) => {
    return {
      title: item.title,
      price: item.price,
      info: item.info,
      items: item.items,
      ...additionalData[index],
    };
  });
  return data;
};
