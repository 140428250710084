export const az = {
  title: '%Maliyyə% dünyasına xoş gəlmisən!',
  description:
    'Şəxsi maliyyənin uğurlu idarə edilməsi üçün maliyyə savadlılığı çox vacibdir. Pul haqqında hər şeyi öyrənmək vaxtıdır. KidFi mobil tətbiqi ilə maliyyə dünyasına səyahətə hazır ol!',
  buttonTitle: 'Özünüzü sınayın',
  modalTitle: 'Hey, testə xoş gəlmisiniz. Siz %Cəsarətlisiniz!%',
  modalDescription:
    'Ancaq ilk növbədə sizin haqqınızda bir az məlumat lazımdır. Zəhmət olmasa özünüz haqqında məlumat verin.',
  rewardMoney: 'Reward money',
  goToTest: 'Testə keçid edin',
  birthday: 'Doğum tarixi',
  email: 'E-poçt',
  female: 'Qadın',
  male: 'Kişi',
  next: 'İrəli',
  unknown: 'Bilmirəm',
  quizTitle: 'Maliyyə Terminologiyasına Giriş',
  thankYou: 'Təşəkkürlər!',
  submissionSent: 'Nəticələriniz göndərildi!',
};
export const en = {
  title: 'Welcome to the world of %finance!%',
  description:
    'Financial literacy is essential to manage personal finances. It is time to be smart about money. Get ready to start this journey with KidFi mobile app!',
  buttonTitle: 'Test yourself',
  modalTitle: 'Hey, Welcome to test. You are %Brave!%',
  modalDescription:
    'But, firstly we need a bit data about you. Please, tell us about yourself.',
  rewardMoney: 'Reward money',
  goToTest: 'Go to test',
  birthday: 'Birthday',
  email: 'Your e-mail',
  female: 'Female',
  male: 'Male',
  next: 'Next',
  unknown: "I don't know",
  quizTitle: 'Introduction to the Finance Terminology',
  thankYou: 'Thank you!',
  submissionSent: 'Your submission has been sent!',
};
export const ru = {
  title: 'Добро пожаловать в мир %финансов!%',
  description:
    'Финансовая грамотность необходима для успешного  управления личными финансами. Настало время разумно относиться к деньгам. Будьте готовы начать путешествие в мир финансов с мобильным приложением KidFi!',
  buttonTitle: 'Проверь себя',
  modalTitle: 'Эй, добро пожаловать на тест. Ты %храбрый!%',
  modalDescription:
    'Но, во-первых, нам нужно немного данных о вас. Пожалуйста, расскажите о себе.',
  rewardMoney: 'Reward money',
  goToTest: 'Перейти к тесту',
  birthday: 'Дата рождения',
  email: 'Email',
  female: 'Женский',
  male: 'Мужской',
  next: 'Следующий',
  unknown: 'Я не знаю',
  quizTitle: 'Введение в финансовую терминологию',
  thankYou: 'Спасибо!',
  submissionSent: 'Ваша заявка отправлена!',
};
